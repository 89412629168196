<script setup>
import { computed } from "vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import { convertSecondToTime } from "@/Components/Statistic/helpers.js";

const props = defineProps({
  modelValue: Boolean,
  modalMessages: Array,
});

const emit = defineEmits(["update:modelValue"]);

const isVisible = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<template>
  <b-modal v-model="isVisible" hide-footer hide-header centered>
    <div class="modal-body">
      <div>
        <div class="d-flex justify-content-end">
          <h3 class="fs-2 cursor-pointer d-flex justify-content-end">
            <i class="bi bi-x-lg" @click="isVisible = !isVisible"></i>
          </h3>
        </div>
        <div class="messages">
          <PerfectScrollbar class="messages">
            <div
              v-for="(comment, index) of modalMessages"
              class="comment-card"
              :key="index"
            >
              <p>{{ comment.message }}</p>
              <p class="comment-time">
                {{ convertSecondToTime(comment.webinar_message_time).value }}
              </p>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>
.modal-body {
  padding: 0;
}
.ps {
  height: 50vh;
}

.comment-card {
  background: #f6f6f6;
  padding: 12px;
  margin: 0 20px 12px 0;
  border-radius: 12px;
  text-align: left;
}
.comment-time {
  text-align: end;
  margin: 0;
  color: #888888;
}
</style>
<style lang="scss">
.ps__thumb-y {
  background-color: #1db954 !important;
}

.ps__rail {
  &-y {
    border-radius: 12px;
  }
}
</style>
