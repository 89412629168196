import { defineStore } from "pinia";

export const useFileUploadStore = defineStore("fileUpload", {
    state: () => {
        return {
            isUpload: false,
            uploadProgress: 0
        };
    },
    actions: {
        setIsUpload(value) {
            this.isUpload = value;
        },
        setUploadProgress(value) {
            this.uploadProgress = value;
        },
    },
});
