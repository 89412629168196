<script setup>
import NavBar from "@/Components/NavBar.vue";
import FooterComponents from "@/Components/Footer.vue";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import horMenu from "./hor-menu";
import horStudentMenu from "./hor-student-menu";

import AppMenu from "@/Components/AppMenu.vue";
import RequestProgressHandler from "@/Components/RequestProgressHandler.vue";
import TenantSurveyModal from "@/Components/modals/TenantSurveyModal.vue";
import { usePage } from "@inertiajs/vue3";
import { useMessagesNotificationsStore } from "@/stores/messagesNotificationsStore.js";
import { toast } from "vue3-toastify";

// import alertSound from '../../sounds/mixkit-tile-game-reveal-960.wav';
import alertSound from "../../sounds/mixkit-light-button-2580.wav";
import useClearWorkshopStatisticsHandler from "@/composables/useClearWorkshopStatisticsHandler.js";
import { useWebinarStatisticsStore } from "@/stores/webinarStatisticsStore.js";

const menuItems = ref(horMenu);
const studendMmenuItems = ref(horStudentMenu);

const page = usePage();
const user = page.props.auth.user;
const roles = page.props.auth.roles;
const isTariffActive = page.props.auth.isTariffActive;

const webinarStatisticsStore = useWebinarStatisticsStore();

useClearWorkshopStatisticsHandler(() => {
  webinarStatisticsStore.dropWebinarStatisticsListStore();
});

// OWNER - Власник організації(тенант), має повний доступ всюди.
// DIRECTOR - Має доступ всюди і може робити все, окрім білінга.
// EXPERT - Має повний доступ до курсів, вебінарів, майстеркласів, менторів, стореджу і тд. Все шо повʼязано з контентом. (Все окрім білінга, менеджмента команди,
// менеджмента студентів(додавання, редагування, видалення і тд.))
// CURATOR - Read only права на всі курси і повне керування курсом де поставили куратором.(Додавання або видалення студентів на курс, відкриття модулів, чат зі студентами і тд)
// PRESENTER - Read only права на всі вебінари і воркшопи, та повне керування вебінаром де поставили спікером.(Стрім, аплоад презентації і тд)
// MODERATOR - Read only права на всі вебінари і воркшопи, та модерування де поставили модератором
// MENTOR - Read only права на всі менторські програми та повне керування де поставили ментором

const filterMenuByRoles = (menu, userRoles) => {
  return menu
    .filter((item) => {
      return !item.roles || item.roles.some((role) => userRoles.includes(role));
    })
    .map((item) => {
      if (item.actions) {
        item.actions = item.actions.filter((action) => {
          return (
            !action.roles ||
            action.roles.some((role) => userRoles.includes(role))
          );
        });
        if (item.actions.length === 0) {
          delete item.actions;
        }
      }

      if (item.subItems) {
        item.subItems = filterMenuByRoles(item.subItems, userRoles);
      }

      return item;
    });
};

const filteredMenu = computed(() => {
  return menuItems.value && roles
    ? filterMenuByRoles(menuItems.value, roles)
    : [];
});

const notificationsChannel = ref();
const messagesStore = useMessagesNotificationsStore();

const notificationsListener = (e) => {
  console.log("NotificationSent", e);
};

const messagesListener = (e) => {
  if (e.message.sender_id !== user.id) {
    messagesStore.addMessage(e.message);
    if (messagesStore.soundsEnabled) {
      playSound();
    }
    if (messagesStore.toastsEnabled) {
      toast.success(
        "<strong>" +
          e.message?.sender_name +
          "</strong>\n" +
          e.message?.content,
        {
          autoClose: 2000,
          position: toast.POSITION.TOP_RIGHT,
          limit: 3,
          dangerouslyHTMLString: true,
          icon: "✉️",
        }
      );
    }
  }
};

const playSound = () => {
  const audio = new Audio(alertSound);
  audio.play().catch((error) => {
    console.error("Error playing sound:", error);
  });
};

onMounted(() => {
  if (window.Echo && page?.props?.auth?.user.id) {
    notificationsChannel.value = window.Echo.private(
      `App.Models.User.${page?.props?.auth?.user.id}`
    );
    notificationsChannel.value.listen(
      "NotificationSent",
      notificationsListener
    );
    notificationsChannel.value.listen("MessageSent", messagesListener);
  }

  // set initial messages counter
  if (page.props.messages && page.props.messages.totalCount) {
    messagesStore.setMessagesCount(page.props.messages.totalCount || 0);
  }
});

onBeforeUnmount(() => {
  if (notificationsChannel.value) {
    notificationsChannel.value.stopListening(
      "NotificationSent",
      notificationsListener
    );
    notificationsChannel.value.stopListening("MessageSent", messagesListener);
  }
});
</script>

<template>
  <div>
    <RequestProgressHandler />
    <div id="layout-wrapper">
      <NavBar />
      <AppMenu
        v-if="
          user &&
          user.current_account_type &&
          user.current_account_type === 'ACCOUNT_TYPE_PRODUCT_OWNER'
        "
        :menu-items="menuItems"
        :class="{ 'disabled-menu': !isTariffActive }"
      />
      <AppMenu
        v-if="
          user &&
          user.current_account_type &&
          user.current_account_type === 'ACCOUNT_TYPE_TEAM_MEMBER'
        "
        :menu-items="filteredMenu"
        :class="{ 'disabled-menu': !isTariffActive }"
      />
      <AppMenu
        v-if="
          user &&
          user.current_account_type &&
          user.current_account_type === 'ACCOUNT_TYPE_STUDENT'
        "
        :menu-items="studendMmenuItems"
      />

      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div class="page-content">
          <!--          <pre>{{roles}}</pre>-->
          <!-- Start Content-->
          <b-container fluid>
            <slot />
          </b-container>
          <TenantSurveyModal
            v-if="
              user &&
              user.current_account_type &&
              user.current_account_type === 'ACCOUNT_TYPE_PRODUCT_OWNER' &&
              !!page.props.auth.questions
            "
          />
        </div>
        <FooterComponents v-if="false" />
      </div>
      <!--            <RightBar/>-->
    </div>
  </div>
</template>

<style>
#keepincrmContainer {
  padding-bottom: 0 !important;
}

.uploaderToast {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 50%;
  background-color: red;
  margin: 20px;
}
</style>
