<script setup>
import { Head, router, useForm } from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";

import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import TabFormGeneralInfo from "@/Pages/Autowebinars/Partials/TabFormGeneralInfo.vue";
import MeetingButton from "@/Components/buttons/MeetingButton.vue";
import PreviewButton from "@/Components/buttons/PreviewButton.vue";
import DownloadXlsxButton from "@/Components/buttons/DownloadXlsxButton.vue";
import TabFormContent from "@/Pages/Autowebinars/Partials/TabFormContent.vue";
import TabFormRegisterInfo from "@/Pages/Autowebinars/Partials/TabFormRegisterInfo.vue";
import TabFormUsers from "@/Pages/Workshops/Partials/TabFormUsers.vue";
import ShareModal from "@/Components/modals/ShareModal.vue";
import useRequestProgress from "@/composables/useRequestProgress.js";
import TabFormEventEditor from "@/Pages/Autowebinars/Partials/TabFormEventEditor.vue";
import TabFormSettings from "@/Pages/Webinars/Partials/TabFormSettings.vue";
import UnsavedChangesModal from "@/Pages/Autowebinars/Partials/UnsavedChangesModal.vue";
import axios from "axios";
import { nextTick } from "vue";
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";
import { useWorkshopStatus } from "@/composables/useDateFormatter.js";

const props = defineProps({
  user: Object,
  autowebinar: Object,
  workshopTypes: Object,
  registerLink: "",
  team: Object,
  teamPresenter: Object,
  teamModerator: Object,
  roles: "",
  link: "",
  workShops: Object,
  webinarButtons: Object,
  workShopEventActionStatuses: Object,
  workShopEventEnum: Object,
  timeUntilAdjustedStart: Number,
});

console.log(props.roles);

const getButtonsFields = (webinarButtons) => {
  const buttons = [...webinarButtons];

  if (buttons.length === 0) {
    return [
      { title: "", url: "" },
      { title: "", url: "" },
    ];
  }

  if (buttons.length === 1) {
    if (buttons[0].order)
      buttons.push({ title: "", url: "", order: buttons[0].order + 1 });
    else buttons.push({ title: "", url: "" });
  }
  return buttons;
};

const form = useForm({
  workShop: props.autowebinar,
  filePreview: null,
  publicLink: "",
  webinarButtons: getButtonsFields(props.webinarButtons),
});

const newUsersCount = computed(
  () => form.workShop.users.filter((user) => !user.access).length
);

const { setIsLoading } = useRequestProgress();

const getParentWebinarButtons = async (id) => {
  try {
    const response = await axios.post("/workshops/get-buttons", { id: id });
    if (response.data) {
      form.webinarButtons = getButtonsFields(response.data.buttons);
    }
    return response.data.exists;
  } catch (error) {
    return false;
  }
};

const getParentWebinarInfo = async (id) => {
  try {
    const response = await axios.post("/workshops/configuration", {
      id: id,
    });
    if (response.data) {
      parentWebinar.value = response.data.webinar;

      form.workShop.completionRedirectUrl =
        response.data.webinar.completion_redirect_url;

      form.workShop.config.access = response.data.webinar.config.access;
    }
    return response.data.exists;
  } catch (error) {
    return false;
  }
};

const getParentInfo = async (id) => {
  setIsLoading(true);
  await getParentWebinarButtons(id);
  await getParentWebinarInfo(id);
  setIsLoading(false);
};

const { t } = useI18n();

// const parentConfig = ref(null);
const parentWebinar = ref(null);

const unsavedChangesModal = ref(false);

const page = ref({
  title: t("AUTO_WEBINAR") + ": " + props.autowebinar.title,
  breadcrumbs: [
    {
      text: "Дашборд",
      href: route("dashboard"),
    },
    {
      text: "Воркшопи",
      href: route("workshops.workshops"),
    },
    {
      text: t("AUTO_WEBINAR") + ": " + props.autowebinar.title,
      active: true,
    },
  ],
});

const tabIndex = ref(0);

const eventLogs = ref([]);

function save() {
  console.log("Save");
  router.visit(
    route("workshops.update", {
      type: "auto_webinar",
      id: form.workShop.id,
    }),
    {
      method: "post",
      data: form,
      preserveState: true,
      preserveScroll: true,
      forceFormData: true,
    }
  );
}

const shareLinkModal = ref(false);
const shareLink = ref("");
const selectedUsers = ref([]);
const tabEditRef = ref(null);

const watchPreview = () => {
  if (
    tabEditRef.value &&
    tabEditRef.value.saveAndWatchPreviewEventsHandler &&
    tabIndex.value === 1
  ) {
    tabEditRef.value.saveAndWatchPreviewEventsHandler();
  } else if (tabEditRef.value && tabEditRef.value.watchPreviewEventsHandler) {
    tabEditRef.value.watchPreviewEventsHandler();
  }
};

function handleShare() {
  shareLinkModal.value = !shareLinkModal.value;
  shareLink.value = form.workShop?.image; //TODO: get share link from autowebinar
}

const initialBaseWorkShopId = computed(() => {
  return props.autowebinar.baseWorkShopId;
});

onMounted(async () => {
  if (props.autowebinar.baseWorkShopId) {
    setIsLoading(true);
    const responseEventLog = await axios.get(
      route("workshops.get-event-log", {
        id: props.autowebinar.baseWorkShopId,
      })
    );
    eventLogs.value = responseEventLog.data.event_logs;

    if (!form.workShop?.eventLogVersionId) {
      form.workShop.eventLogVersionId =
        responseEventLog.data.event_logs.at(-1).id;
    }

    const responseConfig = await axios.post("/workshops/configuration", {
      id: props.autowebinar.baseWorkShopId,
    });
    parentWebinar.value = responseConfig.data.webinar;

    if (!form.workShop.completionRedirectUrl) {
      form.workShop.completionRedirectUrl =
        responseConfig.data.webinar.completion_redirect_url;
    }

    setIsLoading(false);
  }
});

watch(
  () => form.workShop.title,
  (value) => {
    console.log("change edit", page.value.title);
    page.value.title = t("AUTO_WEBINAR") + ": " + value;
    page.value.breadcrumbs[page.value.breadcrumbs.length - 1].text =
      t("AUTO_WEBINAR") + ": " + value;
  },
  { deep: true }
);

watch(
  () => tabIndex.value,
  (newValue, oldValue) => {
    if (
      newValue === 1 &&
      props.autowebinar.baseWorkShopId !== form.workShop.baseWorkShopId
    ) {
      unsavedChangesModal.value = true;
    }
  }
);
const { getWorkshopStatus } = useWorkshopStatus();

const isStartTimeGrant = computed(() => {
  return getWorkshopStatus({
    type: props.autowebinar.type,
    status: props.autowebinar.status,
    date: props.autowebinar.config.date,
    workShopDuration: props.autowebinar.config.duration,
    workShopStatus: props.autowebinar.config.workShopStatus,
  }).showLiveButton;
});
</script>

<template>
  <div>
    <Head title="Автовебінар" />
    <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />
    <PageContentCard :title="page.title">
      <template #header-end>
        <div class="header-end">
          <DiskUsageProgress />
          <MeetingButton
            v-if="isStartTimeGrant"
            :link="link"
            tooltip-text="Кімната зустрічі"
          />
          <b-button
            v-if="form.workShop.status === 'PUBLISH'"
            class="outline-dark"
            pill
            size="lg"
            @click="handleShare"
          >
            Поділитися подією
            <i class="ph-share"></i>
          </b-button>
          <ShareModal v-model="shareLinkModal" :shared-link="registerLink" />
        </div>
      </template>

      <template #default>
        <b-tabs v-model="tabIndex" nav-class="page-tabs">
          <b-tab title="Основні налаштування" active>
            <TabFormGeneralInfo
              @save="save"
              :form="form"
              :workshop-types="workshopTypes"
              :team="team"
              :team-presenter="teamPresenter"
              :team-moderator="teamModerator"
              :roles="roles"
              :register-link="registerLink"
              :workshops="workShops.data"
              :initialBaseWorkShopId="initialBaseWorkShopId"
              :parentWebinar="parentWebinar"
              @get-parent-info="getParentInfo"
            />
          </b-tab>
          <!--                    <b-tab title="Контент автовебінару">-->
          <!--                        <TabFormContent @save="save" :form="form" />-->
          <!--                    </b-tab>-->
          <b-tab
            title="Редактор івентів"
            :disabled="!props.autowebinar?.baseWorkShopId"
          >
            <TabFormEventEditor
              ref="tabEditRef"
              :autowebinar="autowebinar"
              :workShopEventActionStatuses="workShopEventActionStatuses"
              :buttons="webinarButtons"
              :workShopEventEnum="workShopEventEnum"
              :roles="roles"
              :authLink="link"
              :isOldValue="unsavedChangesModal"
            />
          </b-tab>
          <b-tab
            v-if="!form.workShop.config.access"
            title="Реєстрація"
            :disabled="form.workShop.config.access"
          >
            <TabFormRegisterInfo @save="save" :form="form" />
          </b-tab>
          <b-tab title="Користувачі">
            <template #title>
              Користувачі
              <div v-if="newUsersCount > 0" class="nav-link__counter">
                {{ newUsersCount }}
              </div>
            </template>
            <TabFormUsers v-model="selectedUsers" :form="form" />
          </b-tab>

          <template #tabs-end>
            <div class="tabs-end">
              <PreviewButton
                v-if="form.workShop.status === 'DRAFT'"
                :disabled="!props.autowebinar?.baseWorkShopId"
                @watchPreview="watchPreview"
              />
            </div>
          </template>
        </b-tabs>
      </template>
    </PageContentCard>
    <UnsavedChangesModal
      v-if="unsavedChangesModal"
      v-model="unsavedChangesModal"
      title="Збережіть зміни"
      accept-label="Добре"
      @accept-btn="() => (tabIndex = 0)"
    >
      <template v-slot:description>
        Для переходу на редактор івентів потрібно зберігти зміни.
      </template></UnsavedChangesModal
    >
  </div>
</template>

<style lang="scss" scoped>
.header-end {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
</style>
