import { addDays, addMonths, addYears, format, parseISO } from "date-fns";

export const toISOString = (dateString, endDay = false) => {
    const date = new Date(dateString);

    if (isNaN(date)) {
        return null;
    }

    return format(date, `yyyy-MM-dd'T'${endDay ? "23:59:59" : "00:00:00"}'Z'`);
};

// export const fromISOString = (isoString, separator = "-") => {
//     const result =
//         !!isoString && parseISO(isoString)
//             ? format(parseISO(isoString), `yyyy${separator}MM${separator}dd`)
//             : null;

//     return result;
// };
// export const fromISOStringReverse = (isoString, separator = "-") => {
//     const result =
//         !!isoString && parseISO(isoString)
//             ? format(parseISO(isoString), `dd${separator}MM${separator}yyyy`)
//             : null;

//     return result;
// };

export const fromISOString = (isoString, separator = "-") => {
    if (!isoString || typeof isoString !== "string") return null;

    try {
        const date = new Date(isoString);
        if (isNaN(date)) return null;

        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const day = String(date.getUTCDate()).padStart(2, "0");

        return `${year}${separator}${month}${separator}${day}`;
    } catch {
        return null;
    }
};

export const fromISOStringReverse = (isoString, separator = "-") => {
    if (!isoString || typeof isoString !== "string") return null;

    try {
        const date = new Date(isoString);
        if (isNaN(date)) return null;

        const year = date.getUTCFullYear(); // Беремо рік
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const day = String(date.getUTCDate()).padStart(2, "0");

        return `${day}${separator}${month}${separator}${year}`;
    } catch {
        return null;
    }
};

export const getPeriods = () => ({
    today: {
        name: "Cьогодні",
        value: () =>
            format(new Date(), "yyyy-MM-dd") +
            " to " +
            format(new Date(), "yyyy-MM-dd"),
        additionalValue: () => format(new Date(), "yyyy-MM-dd"),
    },
    yesterday: {
        name: "Вчора",
        value: () =>
            format(addDays(new Date(), -1), "yyyy-MM-dd") +
            " to " +
            format(addDays(new Date(), -1), "yyyy-MM-dd"),
        additionalValue: () => format(addDays(new Date(), -1), "yyyy-MM-dd"),
    },
    week: {
        name: "Тиждень",
        value: () =>
            format(addDays(new Date(), -7), "yyyy-MM-dd") +
            " to " +
            format(new Date(), "yyyy-MM-dd"),
    },
    month: {
        name: "Місяць",
        value: () =>
            format(addMonths(new Date(), -1), "yyyy-MM-dd") +
            " to " +
            format(new Date(), "yyyy-MM-dd"),
    },
    quarter: {
        name: "Квартал",
        value: () =>
            format(addMonths(new Date(), -3), "yyyy-MM-dd") +
            " to " +
            format(new Date(), "yyyy-MM-dd"),
    },
    year: {
        name: "Рік",
        value: () =>
            format(addYears(new Date(), -1), "yyyy-MM-dd") +
            " to " +
            format(new Date(), "yyyy-MM-dd"),
    },
});

export const dateRegex =
    /^(?:\d{4})[-. ](?:0?[1-9]|1[0-2])[-. ](?:0?[1-9]|[12]\d|3[01])$/;

export const convertSecondToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsRemaining = seconds % 60;

    const resultHours = `${hours.toString().padStart(2, "0")}`;
    const resultMinutes = `${minutes.toString().padStart(2, "0")}`;
    const resultSeconds = `${secondsRemaining.toString().padStart(2, "0")}`;

    return {
        value: `${resultHours}:${resultMinutes}:${resultSeconds}`,
        hours: resultHours,
        minutes: resultMinutes,
        seconds: resultSeconds,
    };
};

export const getIsoTimeDifferenceFormatted = (startIso, endIso) => {
    const start = new Date(startIso);
    const end = new Date(endIso);

    const differenceInMilliseconds = end.getTime() - start.getTime();

    if (differenceInMilliseconds < 0) {
        return "00:00:00";
    }

    const totalSeconds = Math.floor(differenceInMilliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600)
        .toString()
        .padStart(2, "0");
    const minutes = Math.floor((totalSeconds % 3600) / 60)
        .toString()
        .padStart(2, "0");
    const seconds = (totalSeconds % 60).toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
};

export const translateStatisticPrefix = "statistics-";
