<script setup>
import users from "@/../images/groups/users.svg"

const props = defineProps({
    group: Object,
});

const emit = defineEmits([
    'handle-edit',
    'handle-remove'
])

const handleEditGroup = () => {
    emit('handle-edit');
}

const handleRemoveGroup = () => {
    emit('handle-remove');
}
</script>

<template>
    <div class="group-item" @click="handleEditGroup">
        <div class="group-icon"><img :src="users" alt="group"></div>
        <div class="group-name">{{group.name}}</div>
        <div class="group-size">Студентів: <span>{{group.studentCount}}</span></div>
        <div class="group-spacer"></div>
        <div class="group-actions">
            <button
                @click.stop="handleEditGroup"
            >
                <i class="bi bi-pencil-square"></i>
            </button>
            <button
                @click.stop="handleRemoveGroup"
            >
                <i class="bi bi-trash3"></i>
            </button>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .group-item {
        font-family: e-Ukraine;
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid #EBEBEB;
        transition: all 0.3s ease-in-out;
        .group-icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 44px;
            height: 44px;
            border-radius: 25px;
            background: var(--Dark-green, #0F271C);
            transition: background 0.3s ease-in-out;
        }
        .group-name {
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            color: #1E1E1E;
            width: 20%;
        }
        .group-size {
            font-size: 13px;
            font-weight: 300;
            line-height: 13px;
            text-align: left;
            color: #1E1E1E;
            span {
                font-size: 16px;
                font-weight: 700;
                line-height: 16px;
                text-align: left;
            }
        }
        .group-spacer {
            flex: 1;
        }
        .group-actions {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
        &:hover {
            background: var(--background-primary-50, #F0FDF4);
            border-bottom: 1px solid #1DB954;
            padding: 20px 4px;
            .group-icon {
                background: #1DB954;
            }
            .group-actions {
                opacity: 1;
            }
        }
    }
</style>
