<script setup>
import { Head, Link } from "@inertiajs/vue3";
import { CountTo } from "vue3-count-to";

import PageHeader from "@/Components/PageHeader.vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import course1 from "../../../images/tmp/course/course1.png";
import StatusBage from "@/Components/StatusBage.vue";
import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import { ref } from "vue";
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";
import DropdownButtons from "@/Components/buttons/DropdownButtons.vue";

defineProps({
  user: Object,
  webinars: Object,
});

const { t } = useI18n();

const page = {
  title: t("t-workshops"),
  breadcrumbs: [
    {
      text: "Дашборд",
      href: route("dashboard"),
    },
    {
      text: "Воркшопи",
      active: true,
    },
  ],
};

const statuses = [
  { value: "all", text: t("course-status-all") },
  { value: "draft", text: t("course-status-draft") },
  { value: "published", text: t("course-status-published") },
  { value: "archived", text: t("course-status-archived") },
];

const createLinks = [
  { label: "Створити вебінар", link: "#" },
  { label: "Створити автовебінар", link: "#" },
  { label: "Створити майстерклас", link: "#" },
];

const status = ref("all");
</script>

<template>
  <div>
    <Head title="Воркшопи" />

    <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />

    <PageContentCard :title="$t('t-workshops')">
      <template #header-start>
        <b-form-select
          v-model="status"
          :placeholder="''"
          :options="statuses"
          size="sm"
          ref="input"
          class="ms-3"
          v-uid
        />
      </template>
      <template #header-end>
        <div class="header-end">
          <DiskUsageProgress :percent="15" :used="'245MB'" :capacity="'50GB'" />
          <DropdownButtons :buttons="createLinks">
            <template v-slot:toggle-button>
              <b-button
                class="outline-dark d-flex align-items-center"
                pill
                size="lg"
              >
                {{ $t("btn-create") }}
                <i class="ph-plus"></i>
              </b-button>
            </template>
          </DropdownButtons>
        </div>
      </template>
      <template #default>
        <div class="module-table">
          <div class="module-table__header border-bottom pb-2">
            <div
              class="module-table__col module-table__col--title text-uppercase opacity-50"
            >
              {{ $t("t-broadcast") }}
            </div>
            <div
              class="module-table__col module-table__col--date text-uppercase opacity-50"
            >
              {{ $t("t-date") }}
            </div>
            <div
              class="module-table__col module-table__col--participants text-uppercase opacity-50"
            >
              {{ $t("t-participants") }}
            </div>
            <div
              class="module-table__col module-table__col--status text-uppercase opacity-50"
            >
              {{ $t("t-status") }}
            </div>
            <div
              class="module-table__col module-table__col--type text-uppercase opacity-50"
            >
              {{ $t("t-type") }}
            </div>
            <div
              class="module-table__col module-table__col--actions text-uppercase opacity-50"
            >
              {{ $t("t-actions") }}
            </div>
          </div>
          <div
            v-for="webinar of webinars"
            :key="webinar.id"
            class="module-table__row border-bottom py-3"
          >
            <div class="module-table__col module-table__col--title">
              <div class="module-table__title-image">
                <b-img
                  :src="course1"
                  :title="webinar.title"
                  :alt="webinar.title"
                  rounded
                  fluid
                ></b-img>
              </div>
              <div class="module-table__title-text">
                <span class="table-col__title">{{ webinar.title }}</span>
                <span class="table-col__subtitle">{{
                  webinar.description
                }}</span>
              </div>
            </div>
            <div class="module-table__col module-table__col--date">
              <span class="table-col__time">19.00</span>
              <span class="table-col__day">22 травня 2023</span>
            </div>
            <div class="module-table__col module-table__col--participants">
              <i class="ri-user-fill"></i>{{ webinar.participants.online }}/{{
                webinar.participants.all
              }}
            </div>
            <div class="module-table__col module-table__col--status">
              <StatusBage :type="webinar.status"></StatusBage>
            </div>
            <div class="module-table__col module-table__col--type">
              {{ webinar.type }}
            </div>
            <div class="module-table__col module-table__col--actions">
              <div class="dropdown flex-shrink-0">
                <b-button
                  variant="text-variant"
                  size="sm"
                  class="btn-icon"
                  role="button"
                  data-bs-toggle="dropdown"
                  pill
                  aria-expanded="false"
                >
                  <i class="bi bi-three-dots-vertical"></i>
                </b-button>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <span class="dropdown-item" v-b-modal.shareLinkModal>
                      <i
                        class="ri-share-forward-2-line align-baseline me-1"
                      ></i>
                      Поділитись
                    </span>
                  </li>
                  <li>
                    <Link
                      class="dropdown-item"
                      :href="`/workshops/${webinar.id}/delete`"
                    >
                      Видалити
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <b-modal id="shareLinkModal" hide-footer hide-header centered>
          <div class="modal-body">
            <div class="mt-4">
              <div class="d-flex justify-content-between">
                <h3 class="mb-3">Поділитись подією</h3>
                <h3 class="mb-3 text-right cursor-pointer">
                  <i class="bi bi-x-lg" v-b-modal.shareLinkModal></i>
                </h3>
              </div>
              <!--                            <h3 class="mb-3">Поділитись подією</h3>-->
              <label for="shareLink" class="form-label"
                >Публічне посилання</label
              >
              <b-form-input
                id="shareLink"
                placeholder=""
                value=""
              ></b-form-input>
              <div class="hstack gap-2 justify-content-center my-2">
                <b-button
                  type="button"
                  pill
                  variant="success"
                  size="lg"
                  v-b-modal.shareLinkModal
                >
                  Скопіювати
                </b-button>
              </div>
            </div>
          </div>
        </b-modal>
      </template>
    </PageContentCard>
  </div>
</template>

<style lang="scss" scoped>
#shareLink {
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--green, #1db954);
  background: linear-gradient(
      0deg,
      rgba(29, 185, 84, 0.04) 0%,
      rgba(29, 185, 84, 0.04) 100%
    ),
    #fff;
}
.module-table__col--title {
  display: flex;
  width: 55%;

  @media (max-width: 992px) {
    width: 100%;
  }
  .module-table__title-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 10px;
  }
}
.module-table__col--date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 10%;

  @media (max-width: 992px) {
    width: 50%;
  }
}
.module-table__col--status {
  width: 10%;

  @media (max-width: 992px) {
    width: 50%;
  }
}
.module-table__col--participants {
  width: 10%;
  display: flex;
  align-items: center;
  i {
    font-size: large;
    opacity: 0.5;
  }
}
.module-table__col--actions {
  width: 5%;
  i {
    font-size: large;
  }
}
.module-table__col--type {
  width: 10%;
}
.module-table__title-image {
  width: 120px;
}
</style>
