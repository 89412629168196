import { parseISO, format, isToday, add, isBefore } from "date-fns";
import { uk } from "date-fns/locale";

export function useDateFormatter() {
    const formatDate = (date, formatString = "d MMMM yyyy") => {
        if (!date) {
            return "";
        }

        const parsedDate = parseISO(date);
        return format(parsedDate, formatString, { locale: uk });
    };

    return {
        formatDate,
    };
}

export function useIsToday() {
    const isDateToday = (date) => {
        if (!date) {
            return "";
        }

        const parsedDate = parseISO(date); //"2024-08-30 00:00:00"
        return isToday(parsedDate);
    };

    return {
        isDateToday,
    };
}

export function useDisableBroadcast() {
    const disableBroadcast = (lesson) => {
        const now = new Date();
        const lessonDate = parseISO(lesson.date);

        if (lesson.live_status === "running") {
            return false;
        }

        if (lesson.live_status === "finished") {
            return true;
        }

        if (lesson.live_status === "upcoming") {
            const timeDifference = lessonDate - now; // різниця у мілісекундах
            const fiveMinutesInMs = 5 * 60 * 1000;

            return timeDifference >= fiveMinutesInMs;
        }

        return false;
    };

    return {
        disableBroadcast,
    };
}

export function useWorkshopStatus() {
    const getWorkshopStatus = (workShop) => {
        let workshopStatus = {
            status: "DRAFT",
            showLiveButton: false,
        };

        if (workShop.status === "DRAFT") {
            return workshopStatus;
        } else {
            if (workShop.type === "MASTERCLASS") {
                workshopStatus.status = "PUBLISH";
                return workshopStatus;
            } else {
                workshopStatus.status = workShop.workShopStatus;
                if (workShop.workShopStatus === "ACTIVE") {
                    workshopStatus.showLiveButton = true;
                    return workshopStatus;
                }
                if (workShop.workShopStatus === "FINISHED") {
                    workshopStatus.showLiveButton = false;
                    return workshopStatus;
                }

                if (workShop.workShopStatus === "EXPIRED") {
                    workshopStatus.showLiveButton = false;
                    return workshopStatus;
                }

                if (workShop.workShopStatus === "PENDING") {
                    const now = new Date()

                    const webinarDate = parseISO(workShop.date);

                    const timeDifference = webinarDate - now; // різниця у мілісекундах
                    const fiveMinutesInMs = 30 * 60 * 1000;

                    if (timeDifference <= fiveMinutesInMs) {
                        workshopStatus.status = "UPCOMING";
                        workshopStatus.showLiveButton = true;
                    } else {
                        workshopStatus.showLiveButton = false;
                    }
                    return workshopStatus;
                }
            }
        }
        return workshopStatus;
    };

    return {
        getWorkshopStatus,
    };
}
