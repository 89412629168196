<script setup>
import { translateStatisticPrefix } from "@/Components/Statistic/helpers.js";
import { useI18n } from "vue-i18n";

const props = defineProps({
  currentValue: {
    type: Number,
    default: null,
  },
  filterList: {
    type: Array,
    default: [],
  },
});
const emit = defineEmits(["choose-filter"]);

const { t } = useI18n();

const updateSingleFilter = (filters, name = null) => {
  for (const [key, value] of Object.entries(filters)) {
    if (key !== name) {
      filters[key] = false;
    }
  }
};

const setFilterForSingleFilter = (filters, name) => {
  filters[name] = !filters[name];
};

const translateStatistic = (key) =>
  t(translateStatisticPrefix + key) === translateStatisticPrefix + key
    ? key
    : t(translateStatisticPrefix + key);
</script>

<template>
  <div class="dropdown-filter__wrapper">
    <b-button
      size="md"
      class="dropdown-filter default__filter"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span>{{
        Object.entries(currentValue).find(([key, value]) => value)?.[0]
          ? translateStatistic(
              Object.entries(currentValue).find(([key, value]) => value)?.[0]
            )
          : "Всі"
      }}</span>
      <i class="ph-caret-down-fill"></i>
    </b-button>
    <ul class="dropdown-menu dropdown-menu-right">
      <li @click="emit('choose-filter', null)">
        <span
          class="dropdown-item"
          :class="{ selected: currentValue === null }"
          @click="
            () => {
              updateSingleFilter(currentValue, null);
              emit('choose-filter', filter?.id || null);
            }
          "
        >
          Всі
        </span>
      </li>
      <li
        v-for="filter of filterList"
        :key="filter.id"
        @click="
          () => {
            setFilterForSingleFilter(currentValue, filter.name);
            updateSingleFilter(currentValue, filter.name);
            emit('choose-filter', filter.id);
          }
        "
      >
        <span
          class="dropdown-item"
          :class="{ selected: filter.id === currentValue }"
        >
          {{ translateStatistic(filter.name) }}
        </span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.list-filters__item {
  min-width: 120px;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .btn:focus-visible,
  .btn:active {
    box-shadow: none;
    border: 1px solid #ebebeb;
    background-color: white !important;
    color: #1e1e1e;
  }
  .dropdown-menu {
    border-radius: 12px;
    padding: 0;
    overflow: hidden;
    font-family: e-Ukraine;
    max-width: 100px !important;
    .dropdown-item {
      &.selected {
        background: color-mix(in srgb, #1db954, transparent 90%);
      }
    }
  }
  .dropdown-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border: 1px solid #ebebeb;
    background-color: white;
    border-radius: 8px;
    padding: 8px 16px;
    height: auto;
    font-family: e-Ukraine;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: #1e1e1e;
    .ph-caret-down-fill {
      transition: all 0.2s linear;
    }
    &.default__filter {
      min-width: 180px;
      max-width: 280px;
      span {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &.show {
      .ph-caret-down-fill {
        transform: rotate(180deg);
      }
    }
    .selected {
      background-color: red;
    }
  }
}
</style>
