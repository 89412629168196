<script setup>
import dog from "../../../../images/tmp/autowebinars/dog.svg";
// import video from "../../../../images/tmp/masterClasss/video.png";
import { computed, onMounted, ref, watch, watchEffect } from "vue";
import VideoUploadModal from "@/Components/modals/VideoUploadModal.vue";
import { format } from "date-fns";
import VideoPlayer from "@/Components/VideoPlayer.vue";
import { router, usePage } from "@inertiajs/vue3";
import { useRoleChecker } from "@/composables/useRoleChecker.js";
import EmptyData from "@/Components/EmptyData.vue";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";

const props = defineProps({
  form: Object,
});

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const onSubmit = () => {
  emit("save");
};

const uploadVideoCount = computed(() => {
  let tmp = 0;
  for (const video of localFiles.value) {
    if (video.type === "TYPE_UPLOAD") {
      tmp = tmp + 1;
    }
  }
  return tmp;
});

const recordVideoCount = computed(() => {
  let tmp = 0;
  for (const video of localFiles.value) {
    if (video.type === "TYPE_WEBINAR_RECORD") {
      tmp = tmp + 1;
    }
  }
  return tmp;
});

// watch(
//     () => props.form.selectedVideo,
//     (value) => {
//         props.form.workShop.config.video_id = value.id;
//     },
//     {deep: true}
// );

onMounted(() => {
  for (const video of localFiles.value) {
    if (video.id === props.form.workShop.config.selectedVideo) {
      props.form.selectedVideo = video;
    }
  }
});

const emit = defineEmits(["save"]);

const file = ref(null);
const fileInput = ref(null);
const videoUploadModal = ref(false);
const openFilePicker = () => {
  videoUploadModal.value = !videoUploadModal.value;
};

const handleFileChange = (event) => {
  const selectedFile = event.target.files[0];
  if (selectedFile) {
    file.value = selectedFile;
    props.form.filePreview = selectedFile;
    console.log(selectedFile);
  }
};

const uploadComplete = (event) => {
  localFiles.value = [
    ...localFiles.value,
    {
      id: event.id,
      file: event.file,
      preview: event.preview,
      name: event.name,
      date: event.updated_at_date,
      length: event.length,
      size: formatBytes(event.size),
      type: event.type,
      updated_at_date: event.updated_at_date,
      updated_at_time: event.updated_at_time,
    },
  ];
};

const uploadedVideoCollapse = ref(true);
const recordedVideoCollapse = ref(false);

const hasVideoByType = (type) => {
  return localFiles.value.filter((el) => el.type === type).length > 0;
};

const localFiles = ref([]);

watchEffect(() => {
  localFiles.value = [...props.form.workShop.videos];
});

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [" Bytes", " Kb", " Mb", " Gb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const removeModal = ref(false);
const tempId = ref(null);

const deleteVideo = (id) => {
  console.log("deleteVideo", id);
  router.visit(route("workshops.remove-video-from-masterclass"), {
    method: "post",
    data: {
      type: "masterclass",
      id: id,
    },
    preserveState: true,
    preserveScroll: true,
    forceFormData: true,
    onSuccess: () => {
      localFiles.value = [
        ...localFiles.value.filter((video) => video.id !== id),
      ];
      if (props.form.selectedVideo.id === id) props.form.selectedVideo = null;
    },
  });
};
</script>

<template>
  <b-container fluid class="tab-form">
    <b-form>
      <b-row>
        <b-col cols="3" class="sidebar">
          <!--          <p class="cloud-info m-0">Cloud (тільки відео)</p>-->
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="accordion-header">
                <div class="w-100 text-start">
                  Завантажені відео ({{ uploadVideoCount }})
                </div>
                <b-button
                  variant="danger"
                  class="red-plus-button add-button"
                  :disabled="isReadOnly"
                  @click="openFilePicker"
                >
                  <i class="bx bx-plus"></i>
                </b-button>
                <VideoUploadModal
                  v-if="videoUploadModal"
                  @uploadComplete="uploadComplete"
                  v-model="videoUploadModal"
                  :parent-id="form.workShop.id"
                  :route-args="['workshops.upload-video']"
                />
                <!--                <FileUploadModal v-model="videoUploadModal" :route-args="['test_add_video', { itemId: 1 }]" />-->
                <input
                  ref="fileInput"
                  type="file"
                  style="display: none"
                  @change="handleFileChange"
                />
                <div
                  v-if="hasVideoByType('TYPE_UPLOAD')"
                  @click="uploadedVideoCollapse = !uploadedVideoCollapse"
                >
                  <i
                    v-if="!uploadedVideoCollapse"
                    class="bx bxs-down-arrow ms-3"
                  ></i>
                  <i v-else class="bx bxs-up-arrow ms-3"></i>
                </div>
              </b-card-header>
              <b-collapse
                v-model="uploadedVideoCollapse"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <template v-for="video in localFiles" :key="video.id">
                    <div class="video-item" v-if="video.type === 'TYPE_UPLOAD'">
                      <b-form-radio
                        v-model="props.form.selectedVideo"
                        :value="video"
                        :disabled="isReadOnly"
                        name="selected-video"
                      ></b-form-radio>
                      <div class="video-wrapper">
                        <b-img
                          :src="video.preview"
                          title="title"
                          alt="title"
                        ></b-img>
                      </div>
                      <div class="video-item-description">
                        <div class="video-item-title">
                          {{ video.name }}
                        </div>
                        <div class="video-item-date">
                          Завантажено:
                          <span
                            >{{ video.updated_at_date }}
                            {{ video.updated_at_time }}</span
                          >
                        </div>
                        <div class="video-item-duration">
                          {{ video.length }}
                          <button
                            class="video-item__delete"
                            :disabled="isReadOnly"
                            @click="
                              removeModal = true;
                              tempId = video.id;
                            "
                          >
                            <i class="bi bi-trash3"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                  <ConfirmModal
                    v-model="removeModal"
                    :object-id="tempId"
                    title="Видалити відео"
                    decline-label="Скасувати"
                    accept-label="Так, видалити"
                    @accept-btn="(id) => deleteVideo(id)"
                    @decline-btn="(id) => console.log('decline', id)"
                  >
                    <template v-slot:description>
                      Після видалення файлу його не можна буде повернути. Ви
                      впевнені, що хочете продовжити?
                    </template>
                  </ConfirmModal>
                </b-card-body>
              </b-collapse>
            </b-card>

            <!--            <b-card no-body class="mb-1">-->
            <!--              <b-card-header header-tag="header" class="accordion-header" role="tab">-->
            <!--                <div class="w-100 text-start">Записи вебінарів ({{ recordVideoCount }})</div>-->
            <!--                <div-->
            <!--                    v-if="hasVideoByType('TYPE_WEBINAR_RECORD')"-->
            <!--                    @click="recordedVideoCollapse = !recordedVideoCollapse"-->
            <!--                >-->
            <!--                  <i v-if="!recordedVideoCollapse" class="bx bxs-down-arrow ms-3"></i>-->
            <!--                  <i v-else class="bx bxs-up-arrow ms-3"></i>-->
            <!--                </div>-->
            <!--              </b-card-header>-->
            <!--              <b-collapse v-model="recordedVideoCollapse" accordion="my-accordion" role="tabpanel">-->
            <!--                <b-card-body>-->
            <!--                  <template v-for="video in localFiles" :key="video.id">-->
            <!--                    <div class="video-item" v-if="video.type === 'TYPE_WEBINAR_RECORD'">-->
            <!--                      <b-form-radio v-model="props.form.selectedVideo" :value="video"-->
            <!--                                    name="selected-video"></b-form-radio>-->
            <!--                      <b-img :src="video.preview" title="title" class="ms-2" alt="title"></b-img>-->
            <!--                      <div class="video-item-description">-->
            <!--                        <div class="video-item-title">{{ video.name }}-->
            <!--                        </div>-->
            <!--                        <div class="video-item-date">Завантажено:-->
            <!--                          <span>{{ video.updated_at_date }} {{ video.updated_at_time }}</span></div>-->
            <!--                        <div class="video-item-duration">{{ video.length }}</div>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </template>-->
            <!--                </b-card-body>-->
            <!--              </b-collapse>-->
            <!--            </b-card>-->
          </div>
        </b-col>
        <b-col cols="9">
          <div
            v-if="props.form.selectedVideo"
            class="d-flex flex-column align-items-center"
          >
            <!--            <b-img :src="video" class="video-img"></b-img>-->
            <!--            <video src="{{video.file}}" class="video-img"></video>-->
            <!--            <video class="video-img" controls :src="props.form.selectedVideo.file"></video>-->
            <div class="video-container">
              <div
                v-if="
                  !props.form.selectedVideo.status ||
                  props.form.selectedVideo.status !== 'Finished'
                "
                class="video-status"
              >
                <img
                  :src="props.form.selectedVideo.preview"
                  title=""
                  alt=""
                  class="video-status__preview"
                />
                <div class="video-status__loader">
                  <div>
                    <b-spinner variant="primary"></b-spinner>
                  </div>
                  <div>
                    {{
                      props.form.selectedVideo.status
                        ? $t(props.form.selectedVideo.status)
                        : "Обробка"
                    }}
                  </div>
                </div>
              </div>
              <VideoPlayer
                v-else
                :video-src="props.form.selectedVideo.file"
                class="video-player"
              />
            </div>
            <div class="video-info">
              <div class="d-flex my-3 gap-1 align-items-center">
                <b-form-group label="Назва майстеркласу" class="video-title">
                  <b-form-input
                    v-model="props.form.selectedVideo.name"
                    :disabled="isReadOnly"
                  ></b-form-input>
                </b-form-group>
                <div class="video-date d-flex">
                  <i class="ph-calendar-check"></i>
                  {{ props.form.selectedVideo.updated_at_date }}
                </div>
                <div class="video-time">
                  {{ props.form.selectedVideo.updated_at_time }}
                </div>
                <div class="video-duration d-flex">
                  <i class="ph-clock"></i>
                  {{ props.form.selectedVideo.length }}
                </div>
                <div class="video-size d-flex">
                  <i class="bx bx-tachometer"></i>
                  {{ props.form.selectedVideo.size }}
                </div>
                <b-button
                  variant="primary"
                  size="md"
                  pill
                  :disabled="isReadOnly"
                  @click="onSubmit"
                >
                  Зберегти
                </b-button>
              </div>
              <div class="video-description">
                <p>
                  {{ props.form.selectedVideo.description }}
                </p>
              </div>
            </div>
          </div>
          <div v-else class="d-flex flex-column align-items-center">
            <!--&lt;!&ndash;            <b-img :src="dog" class="no-video-img  my-3"></b-img>&ndash;&gt;-->
            <!--            <p class="no-video-title">Тут, поки що, нічого не має</p>-->
            <!--            <p class="no-video-description">Оберіть, або завантажте відео з панелі матеріалів, зліва</p>-->
            <EmptyData type="masterclass">
              <template v-slot:title>
                {{
                  localFiles.length === 0
                    ? "Завантажте відео для майстер-класу з панелі ліворуч"
                    : "Завантажте або оберіть необхідне відео з панелі ліворуч"
                }}
              </template>
            </EmptyData>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<style lang="scss">
.grand-modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.grand-modal-title {
  color: #da7f14;
  display: flex;
  flex-direction: row;

  i {
    font-size: xxx-large;
  }

  h4 {
    color: #da7f14;
  }
}

.grand-modal-desc {
  margin-left: 50px;
  color: dimgray;
}

.grand-modal-accept {
  background-color: var(--green, #1db954);
  border: none;
}

.grand-modal-accept:hover {
  background-color: var(--green, #1db954);
  opacity: 0.8;
}

.grand-modal-accept:hover {
  background-color: var(--green, #1db954);
  opacity: 0.8;
}

.module-table__col--email {
  display: flex;
  width: 30%;
}

.module-table__col--name {
  width: 15%;
}

.module-table__col--phone {
  width: 10%;
}

.module-table__col--payment {
  width: 10%;

  i {
    color: green;
  }
}

.module-table__col--actions {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  //i {
  //  margin: 0 10px;
  //  font-size: large;
  //  color: #C2C2C2;
  //}
}

.sidebar {
  border-right: 1px solid var(--stroke, #ebebeb);
  padding: 0;
  margin-left: -19px;
  margin-top: -19px;
}

.cloud-info {
  color: var(--dark, #1e1e1e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 12px */
  text-transform: uppercase;
  display: flex;
  padding: 32px 0 32px 20px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--light_bg_grey, #f8faf8);
}

.accordion-header {
  display: flex;
  padding: 16px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--dark, #1e1e1e);
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 184.615% */
  letter-spacing: -0.26px;

  .add-button {
    width: 30px;
    height: 30px;
  }
}

.video-item {
  display: flex;
  margin-bottom: 20px;

  .form-check-input {
    border-color: color-mix(in srgb, var(--dark, #1e1e1e), transparent 50%);
  }

  .video-item-description {
    padding-left: 14px;
    flex: 1;

    .video-item-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      align-self: stretch;
      overflow: hidden;
      color: var(--dark, #1e1e1e);
      text-overflow: ellipsis;
      font-style: normal;
      margin-bottom: 8px;
      font-family: e-Ukraine;
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      word-break: break-word;
    }

    .video-item-date {
      font-family: e-Ukraine;
      color: color-mix(in srgb, var(--dark, #1e1e1e), transparent 50%);
      font-size: 10px;
      font-style: normal;
      font-weight: 200;
      line-height: 100%; /* 10px */
      margin-bottom: 8px;

      span {
        font-family: e-Ukraine;
        font-weight: 400;
        color: var(--dark, #1e1e1e);
      }
    }

    .video-item-duration {
      font-family: e-Ukraine;
      color: color-mix(in srgb, var(--dark, #1e1e1e), transparent 50%);
      font-size: 10px;
      font-style: normal;
      font-weight: 200;
      line-height: 100%; /* 10px */
      display: flex;
      align-items: center;
      justify-content: space-between;

      .video-item__delete {
        i {
          font-size: 12px;
        }

        &:hover {
          color: color-mix(in srgb, var(--dark, #1e1e1e), transparent 25%);
        }
      }
    }
  }

  img {
    height: 65px;
    border-radius: 4px;
  }
}

.video-item:last-child {
  margin-bottom: 0;
}

.video-description {
  font-weight: 300;
  font-size: 14px;
  color: #8f8f8f;
  p {
    margin: 0;
  }
}

.no-video-img {
  width: 300px;
}

.no-video-title {
  color: var(--dark, #1e1e1e);
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 26px */
}

.no-video-description {
  color: var(--dark, #1e1e1e);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 22.4px */
  opacity: 0.4;
}

.video-date,
.video-time,
.video-duration,
.video-size {
  color: color-mix(in srgb, var(--dark, #1e1e1e), transparent 00%);
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 100%;
  white-space: nowrap;
  display: flex;
  gap: 6px;
  align-items: center;

  i {
    color: black;
    font-size: 20px;
  }
}

.video-title {
  width: -webkit-fill-available;
}

.video-status {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background: #f7f7f8;
  //min-height: 150px;
  //width: 100%;
  height: calc(100vh - 360px);
  width: calc((100vh - 360px) * (16 / 9)) !important;

  .video-status__preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 25%;
  }

  .video-status__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
  }
}

.video-wrapper {
  background-color: #f7f7f8;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px !important;
  width: calc(65px * (16 / 9)) !important;
}

.video-container {
  height: calc(100vh - 360px);
  width: calc((100vh - 360px) * (16 / 9)) !important;

  .video-player {
    .video-js {
      height: calc(100vh - 360px);
      width: calc((100vh - 360px) * (16 / 9)) !important;
    }
  }
}
</style>
