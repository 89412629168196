import { defineStore } from "pinia";
import axios from "axios";
import useRequestProgress from "@/composables/useRequestProgress.js";
import {toast} from "vue3-toastify";

const { setIsLoading } = useRequestProgress()

export const useStudentsGroupStore = defineStore('studentsGroupStore', {
    state: () => {
        return {
            groups: [],
            groupPagination: {
                currentPage: 1,
                totalItems: null,
                perPage: 20
            },
            group: {
                students: [],
                pagination: {
                    currentPage: 1,
                    totalItems: null,
                    perPage: 20
                }
            },
            availableStudents: {
                students: [],
                pagination: {
                    currentPage: 1,
                    totalItems: null,
                    perPage: 20
                }
            }
        };
    },
    getters: {
        studentsGroupList(state) {
            return state.groups;
        },
        groupPaginationData(state) {
            return state.groupPagination;
        },
        isGroupPagination(state) {
            return state.groupPagination.totalItems && state.groupPagination.totalItems > state.groupPagination.perPage
        },
        availableStudentsList(state) {
            return state.availableStudents.students;
        },
        availableStudentsPaginationData(state) {
            return state.availableStudents.pagination;
        },
        isAvailableStudentsPagination(state) {
            return state.availableStudents.pagination.totalItems && state.availableStudents.pagination.totalItems > state.availableStudents.pagination.perPage
        },
        groupStudentsList(state) {
            return state.group.students;
        },
        groupStudentsPaginationData(state) {
            return state.group.pagination;
        },
        isGroupStudentsPagination(state) {
            return state.group.pagination.totalItems && state.group.pagination.totalItems > state.group.pagination.perPage
        }
    },
    actions: {
        getGroupsList(props) {
            setIsLoading(true)
            axios.get(route('api.get_action', { 'moduleName': 'group','action': 'getGroupList' }), props)
                .then(response => {
                    if(response.data){
                        if(response.data.data){
                            this.groups = response.data.data;
                        }
                        if(response.data.meta) {
                            this.groupPagination.currentPage = response.data.meta.current_page
                            this.groupPagination.totalItems = response.data.meta.total
                            this.groupPagination.perPage = response.data.meta.per_page
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        createGroup(props) {
            setIsLoading(true)
            axios.post(route('api.get_action', { 'moduleName': 'group','action': 'createGroup' }), props)
                .then(response => {
                    this.getGroupsList({
                        params:{
                            tenant_id: props.tenant_id,
                        }
                    })
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                    return [];
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        deleteGroup(props) {
            setIsLoading(true)
            axios.post(route('api.get_action', { 'moduleName': 'group','action': 'removeGroup' }), props)
                .then(response => {
                    this.getGroupsList({
                        params:{
                            tenant_id: props.tenant_id,
                        }
                    })
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                    return [];
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        getStudentsWithoutGroup(props) {
            setIsLoading(true)
            axios.get(route('api.get_action', { 'moduleName': 'group','action': 'getStudentsWithoutGroup' }), props)
                .then(response => {
                    if(response.data){
                        if(response.data.data){
                            this.availableStudents.students = response.data.data;
                        }
                        if(response.data.meta) {
                            this.availableStudents.pagination.currentPage = response.data.meta.current_page
                            this.availableStudents.pagination.totalItems = response.data.meta.total
                            this.availableStudents.pagination.perPage = response.data.meta.per_page
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        resetStudentsWithoutGroup(){
            this.availableStudents = {
                students: [],
                    pagination: {
                    currentPage: 1,
                        totalItems: null,
                        perPage: 20
                }
            }
        },
        getStudentsFromGroup(props) {
            setIsLoading(true)
            axios.get(route('api.get_action', { 'moduleName': 'group','action': 'getStudentsFromGroup' }), props)
                .then(response => {
                    if(response.data){
                        if(response.data.data){
                            this.group.students = response.data.data;
                        }
                        if(response.data.meta) {
                            this.group.pagination.currentPage = response.data.meta.current_page
                            this.group.pagination.totalItems = response.data.meta.total
                            this.group.pagination.perPage = response.data.meta.per_page
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        resetStudentsFromGroup(){
            this.group = {
                students: [],
                pagination: {
                    currentPage: 1,
                    totalItems: null,
                    perPage: 20
                }
            }
        },
        addStudentToGroup(props) {
            setIsLoading(true)
            axios.post(route('api.get_action', { 'moduleName': 'group','action': 'addStudentToGroup' }), props)
                .then(response => {
                    this.getStudentsFromGroup({
                        params:{
                            group_id: props.group_id,
                            tenant_id: props.tenant_id,
                            page: this.group.pagination.currentPage,
                            perPage: this.group.pagination.perPage
                        }
                    })
                    this.getStudentsWithoutGroup({
                        params:{
                            group_id: props.group_id,
                            tenant_id: props.tenant_id,
                            page: this.availableStudents.pagination.currentPage,
                            perPage: this.availableStudents.pagination.perPage
                        }
                    })
                    this.getGroupsList({
                        params:{
                            tenant_id: props.tenant_id,
                        }
                    })
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                    return [];
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        removeStudentFromGroup(props) {
            setIsLoading(true)
            axios.post(route('api.get_action', { 'moduleName': 'group','action': 'removeStudentFromGroup' }), props)
                .then(response => {
                    this.getStudentsFromGroup({
                        params:{
                            group_id: props.group_id,
                            tenant_id: props.tenant_id,
                            page: this.group.pagination.currentPage,
                            perPage: this.group.pagination.perPage
                        }
                    })
                    this.getStudentsWithoutGroup({
                        params:{
                            group_id: props.group_id,
                            tenant_id: props.tenant_id,
                            page: this.availableStudents.pagination.currentPage,
                            perPage: this.availableStudents.pagination.perPage
                        }
                    })
                    this.getGroupsList({
                        params:{
                            tenant_id: props.tenant_id,
                        }
                    })
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                    return [];
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        updateGroupName(props) {
            setIsLoading(true)
            axios.post(route('api.get_action', { 'moduleName': 'group','action': 'updateGroup' }), props)
                .then(response => {
                    this.getGroupsList({
                        params:{
                            tenant_id: props.tenant_id,
                        }
                    })
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                    return [];
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        errorToast(){
            toast.error("Сталася помилка, спробуйте ще раз!", {
                autoClose: 2000,
                position: toast.POSITION.BOTTOM_CENTER,
                multiple: false
            });
        }
    }
})
