<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  webinarId: String,
  withFilters: Boolean,
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "handle-download-statistics"]);

const isVisible = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const url = ref("");
const isUrlValid = ref(true);

const webinarReportAccount = import.meta.env.VITE_WEBINAR_REPORT_ACCOUNT;

function isValidGoogleSheetUrl(url) {
  const regex =
    /^https:\/\/docs\.google\.com\/spreadsheets\/d\/[a-zA-Z0-9-_]+\/edit(\?.*)?$/;
  return regex.test(url);
}

function validateUrl() {
  isUrlValid.value = isValidGoogleSheetUrl(url.value);
}

function handleDownload() {
  if (!url.value.trim()) {
    isUrlValid.value = false;
    return;
  }

  if (isValidGoogleSheetUrl(url.value)) {
    emit(
      "handle-download-statistics",
      props.webinarId,
      url.value,
      props.withFilters
    );
  } else {
    isUrlValid.value = false;
  }
}
</script>

<template>
  <b-modal v-model="isVisible" hide-footer hide-header centered>
    <div class="modal-body">
      <h2 class="mb-3">Експорт в google таблиці</h2>
      <div>
        <p>Для того, щоб завантажити звіт в таблицю google, вам необхідно:</p>
        <ul class="mb-3 download-statistics__info">
          <li>
            Створити таблицю під своїм профілем в
            <a
              href="https://docs.google.com/spreadsheets/"
              target="_blank"
              rel="noopener noreferrer"
            >
              google таблицях </a
            >.
          </li>
          <li>
            Дати доступ для редагування данної таблиці користувачу
            <span class="fw-bold download-statistics__link">{{
              webinarReportAccount
            }}</span>
          </li>
          <li>В полі знизу вказати посилання на данну таблицю</li>
        </ul>

        <div class="mb-3">
          <p class="fw-bold mb-0">Посилання на google таблицю :</p>
          <input
            v-model="url"
            type="text"
            @input="validateUrl"
            class="form-control"
            placeholder="https://docs.google.com/spreadsheets/d/1ISvH1XLXjYMj3evvoL_gL4yEcM6_sYCvSZIAcgSj0o8/edit"
          />
          <p v-if="!isUrlValid && !!url.trim()" class="text-danger mt-2">
            Введіть коректне посилання на Google таблицю.
          </p>
        </div>

        <button
          @click="handleDownload"
          :disabled="!isUrlValid || !url.trim()"
          class="btn btn-primary"
        >
          <span>Завантажити</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
.modal-body {
  font-family: e-Ukraine;
}

.download-statistics__info {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: decimal;
  color: #1e1e1e80;
  a {
    color: #1db954;
  }
}

.download-statistics__link {
  display: inline-block;
  padding-top: 6px;
  color: #1e1e1e;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}
</style>
