import { defineStore } from "pinia";
import axios from "axios";
import useRequestProgress from "@/composables/useRequestProgress.js";
import {toast} from "vue3-toastify";

const { setIsLoading } = useRequestProgress()

export const useCourseGroupStore = defineStore('courseGroupStore', {
    state: () => {
        return {
            groups: [],
            groupPagination: {
                currentPage: 1,
                totalItems: null,
                perPage: 20
            },
            groupsWithCourseStatus: {
                groups: [],
                pagination: {
                    currentPage: 1,
                    totalItems: null,
                    perPage: 20
                }
            },
            students: [],
            studentsPagination: {
                currentPage: 1,
                totalItems: null,
                perPage: 20
            },
            studentsFilterByGroup: null,
            studentsSorting: []
        };
    },
    getters: {
        courseGroupList(state) {
            return state.groups;
        },
        isCourseGroupListEmpty(state) {
            return state.groups.length === 0;
        },
        groupsWithCourseStatusList(state) {
            return state.groupsWithCourseStatus.groups;
        },
        isGroupsWithCourseStatusListEmpty(state) {
            return state.groupsWithCourseStatus.groups.length === 0;
        },
        courseStudentsList(state) {
            return state.students;
        },
        isStudentsListEmpty(state) {
            return state.students.length === 0;
        },
        studentsPerPage(state) {
            return state.studentsPagination.perPage;
        },
        studentsFilterByGroupValue(state) {
            return state.studentsFilterByGroup;
        },
        getStudentsSortingByName: (state) => (name) => {
            return state.studentsSorting.find( e => e.name === name)?.direction
        },
        studentsPaginationData: (state) => {
            return state.studentsPagination
        },
        isStudentsPagination(state) {
            return state.studentsPagination.totalItems && state.studentsPagination.totalItems > state.studentsPagination.perPage
        }
    },
    actions: {
        resetStudentsPagination() {
            this.studentsPagination.currentPage = 1
        },
        toggleSort(fieldName) {
            const existingIndex = this.studentsSorting.findIndex(item => item.name === fieldName);

            if (existingIndex === -1) {
                this.studentsSorting.push({ name: fieldName, direction: 'asc' });
            } else {
                const currentDirection = this.studentsSorting[existingIndex].direction;

                if (currentDirection === 'asc') {
                    this.studentsSorting[existingIndex].direction = 'desc';
                } else {
                    this.studentsSorting.splice(existingIndex, 1);
                }
            }
        },
        setStudentsPerPage(value) {
            this.studentsPagination.perPage = value
        },
        setStudentsFilterByGroup(value) {
            this.studentsFilterByGroup = value
        },
        getGroupsList(props) {
            setIsLoading(true)
            axios.get(route('api.get_action', { 'moduleName': 'group','action': `listGroupsInCourse/${props.params.course_id}` }))
                .then(response => {
                    if(response.data){
                        if(response.data.data){
                            this.groups = response.data.data;
                        }
                        if(response.data.meta) {
                            this.groupPagination.currentPage = response.data.meta.current_page
                            this.groupPagination.totalItems = response.data.meta.total
                            this.groupPagination.perPage = response.data.meta.per_page
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        getGroupsWithCourseStatusList(props) {
            setIsLoading(true)
            axios.get(route('api.get_action', { 'moduleName': 'group','action': `listGroupsWithCourseStatus/${props.params.course_id}` }))
                .then(response => {
                    if(response.data){
                        if(response.data.data){
                            this.groupsWithCourseStatus.groups = response.data.data;
                        }
                        if(response.data.meta) {
                            this.groupsWithCourseStatus.pagination.currentPage = response.data.meta.current_page
                            this.groupsWithCourseStatus.pagination.totalItems = response.data.meta.total
                            this.groupsWithCourseStatus.pagination.perPage = response.data.meta.per_page
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        assignGroupToCourse(props) {
            setIsLoading(true)
                axios.post(route('api.get_action', { 'moduleName': 'group','action': 'assignGroupToCourse' }), props)
                .then(response => {
                    this.getGroupsWithCourseStatusList({
                        params:{
                            course_id: props.course_id
                        }
                    })
                    this.getGroupsList({
                        params:{
                            course_id: props.course_id
                        }
                    })
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                    return [];
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        removeGroupFromCourse(props) {
            setIsLoading(true)
            axios.post(route('api.get_action', { 'moduleName': 'group','action': 'removeGroupFromCourse' }), props)
                .then(response => {
                    this.getGroupsWithCourseStatusList({
                        params:{
                            course_id: props.course_id
                        }
                    })
                    this.getGroupsList({
                        params:{
                            course_id: props.course_id
                        }
                    })
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                    return [];
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        getStudentsFromCourse(props) {
            setIsLoading(true)
            const storedProps = {
                page: this.studentsPagination.currentPage,
                perPage: this.studentsPagination.perPage
            }
            if (this.studentsFilterByGroup) storedProps.group_id = this.studentsFilterByGroup;
            if (this.studentsSorting.length > 0) storedProps.sorts = this.studentsSorting;
            console.log('getStudentsFromCourse', props, storedProps)
            axios.post(route('api.get_action', { 'moduleName': 'course','action': `getStudentsFromCourse` }), { ...props, ...storedProps })
                .then(response => {
                    if(response.data){
                        if(response.data.data){
                            this.students = response.data.data;
                        }
                        if(response.data.meta) {
                            this.studentsPagination.currentPage = response.data.meta.current_page
                            this.studentsPagination.totalItems = response.data.meta.total
                            this.studentsPagination.perPage = response.data.meta.per_page
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.errorToast()
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        errorToast(){
            toast.error("Сталася помилка, спробуйте ще раз!", {
                autoClose: 2000,
                position: toast.POSITION.BOTTOM_CENTER,
                multiple: false
            });
        }
    }
})
