import {ref} from 'vue';

export default (function () {

  const isLoading = ref(false)

  const isError = ref(false)

  const errorMessage = ref(null)

  let loadingCount = 0;

  const setIsLoading = (state) => {
      if (state === true) {
          loadingCount++;
          isLoading.value = true;
      } else {
          loadingCount = Math.max(loadingCount - 1, 0);
          if (loadingCount === 0) {
              isLoading.value = false;
          }
      }
  }

  const setErrorMessage = (errorsString) => {
    isError.value = !!errorsString
    errorMessage.value = errorsString
  }

  return () => {
    return {
      isLoading,
      isError,
      errors: errorMessage,
      setIsLoading,
      setErrorMessage
    }
  }
})()
