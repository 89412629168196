<script setup>

import {computed} from "vue";
import {BButton} from "bootstrap-vue-next";

const props = defineProps({
    groups: {
        type: Array,
        default: []
    }
})

const hiddenGroups = computed(() => {
    return Array.isArray(props.groups) && props.groups.length > 1
        ? props.groups.slice(1)
        : [];
});

</script>

<template>
    <div v-if="groups && groups.length > 0">
        <div v-if="groups.length > 1">
            <div class="dropdown d-flex flex-column align-items-start">
                {{groups[0].name}}
                <b-button
                    variant="link"
                    size="md"
                    role="button"
                    class="hidden-groups__btn"
                    data-bs-toggle="dropdown"
                    pill
                    data-bs-auto-close="outside"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                   Показати +{{hiddenGroups.length}}
                </b-button>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li
                        v-for="group in hiddenGroups"
                        :key="group.id"
                        class="m-2"
                    >
                        {{ group.name }}
                    </li>
                </ul>
            </div>
        </div>
        <div v-else>
            {{groups[0].name}}
        </div>
    </div>
    <div v-else class="not-assigned">
        {{$t('t-not-assigned')}}
    </div>
</template>

<style scoped lang="scss">
.hidden-groups__btn {
    padding: 4px 4px 4px 0;
    box-shadow: none!important;
    &:focus-visible {
        box-shadow: none!important;
    }
}
</style>
