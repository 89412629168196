<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref} from "vue";
import {useStudentsGroupStore} from "@/stores/studentsGroupStore.js";
import useRequestProgress from "@/composables/useRequestProgress.js";
import GroupMainInfo from "@/Pages/Courses/Partials/group/GroupMainInfo.vue";
import GroupContent from "@/Pages/Courses/Partials/group/GroupContent.vue";
import GroupSettings from "@/Pages/Courses/Partials/group/GroupSettings.vue";
import axios from "axios";
import {toast} from "vue3-toastify";
import {useForm} from "@inertiajs/vue3";

const props = defineProps({
    modelValue: Boolean,
    group: Object,
    course: Object,
});

const studentsGroupStore = useStudentsGroupStore();

const emit = defineEmits(["update:modelValue", 'close-modal']);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const { setIsLoading } = useRequestProgress()

const config = ref({})
const team = ref({})

const form = useForm({
    group_id: props.group.id,
    course_id: props.course.id,
    is_manual_module_open: 1,
    is_automatic_module_open: 1,
    is_after_watch_lesson_open: 1,
    is_all_lesson_open: 1,
    max_user_in_course: null,
    speakers: [],
});

onMounted(() => {
    nextTick(() => {
        if (props.group && props.course) {
            getGroupData({
                group_id: props.group.id,
                course_id: props.course.id
            });
        }
    });
});

onBeforeUnmount(() => {
    emit('close-modal')
});

const getGroupData = (props) => {
    setIsLoading(true)
    axios.post (route('api.get_action', { 'moduleName': 'group','action': `getCourseGroupEdit` }), props)
        .then(response => {
            if(response.data){
                if(response.data.config) Object.assign(form, response.data.config);
                if (response.data.team) team.value = response.data.team
            }
        })
        .catch(error => {
            console.error(error);
            toast.error("Сталася помилка, спробуйте ще раз!", {
                autoClose: 2000,
                position: toast.POSITION.BOTTOM_CENTER,
                multiple: false
            });
        })
        .finally(() => {
            setIsLoading(false)
        })
}

</script>

<template>
    <b-modal
        v-model="isVisible"
        v-if="isVisible"
        hide-footer
        hide-header
        centered
        no-close-on-esc
        no-close-on-backdrop
        class="edit-course-group__modal"
    >
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <div class="modal-header__text">
                    <div class="modal-header__label">
                        {{ $t('t-group-edit') }}
                    </div>
                    <div class="modal-header__title">
                        {{group.name}}
                    </div>
                </div>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <b-form>
                <b-tabs class="modal-tabs-component">
                    <b-tab title="Основна інформація">
                        <GroupMainInfo
                            :form="form"
                            :key="form"
                        />
                    </b-tab>
                    <b-tab title="Контент курсу" active>
                        <GroupContent :course="course" />
                    </b-tab>
                    <b-tab title="Управління">
                        <GroupSettings
                            :team="team"
                            :form="form"
                            :key="form"
                        />
                    </b-tab>
                </b-tabs>
            </b-form>
        </div>
    </b-modal>
</template>
<style lang="scss">
.edit-course-group__modal {
    font-family: e-Ukraine;
    .modal-dialog {
        max-width: 1200px;
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .modal-header__text {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .modal-header__label {
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                color: color-mix(in srgb, var(--dark, #1E1E1E), transparent 50%);
            }
            .modal-header__title {
                font-size: 22px;
                font-weight: 500;
                line-height: 22px;
                text-align: center;
                color: #1E1E1E;
            }
        }
    }
    .ps__thumb-y {
        background-color: #1db954!important;
    }
}
</style>
