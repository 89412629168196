<script setup>
const props = defineProps({
  currentValue: {
    type: Number,
    default: null,
  },
});
const emit = defineEmits(["choose-amount"]);
const values = [10, 20, 50, 100];
</script>

<template>
  <div class="dropdown-filter__wrapper">
    <b-button
      size="md"
      class="dropdown-filter per-page__filter"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ currentValue }}
      <i class="ph-caret-down-fill"></i>
    </b-button>
    <ul class="dropdown-menu dropdown-menu-right">
      <li
        v-for="value of values"
        :key="value"
        @click="emit('choose-amount', value)"
      >
        <span
          class="dropdown-item"
          :class="{ selected: value === currentValue }"
        >
          {{ value }}
        </span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.list-filters__item {
  min-width: 120px;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .btn:focus-visible,
  .btn:active {
    box-shadow: none;
    border: 1px solid #ebebeb;
    background-color: white !important;
    color: #1e1e1e;
  }
  .dropdown-menu {
    border-radius: 12px;
    padding: 0;
    overflow: hidden;
    font-family: e-Ukraine;
    max-width: 100px !important;
    .dropdown-item {
      &.selected {
        background: color-mix(in srgb, #1db954, transparent 90%);
      }
    }
  }
  .dropdown-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ebebeb;
    background-color: white;
    border-radius: 8px;
    padding: 8px 16px;
    height: auto;
    font-family: e-Ukraine;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: #1e1e1e;
    .ph-caret-down-fill {
      transition: all 0.2s linear;
    }
    &.per-page__filter {
      width: 120px !important;
    }
    &.show {
      .ph-caret-down-fill {
        transform: rotate(180deg);
      }
    }
    .selected {
      background-color: red;
    }
  }
}
</style>
