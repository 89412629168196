<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, onUpdated, ref} from "vue";
import InputGroup from "@/Components/InputGroup.vue";
import { helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import noAvatar from "../../../images/tmp/ProductOwnerProfile/avatar.png";
import {usePage} from "@inertiajs/vue3";
import {useStudentsGroupStore} from "@/stores/studentsGroupStore.js";
import useRequestProgress from "@/composables/useRequestProgress.js";

const props = defineProps({
    modelValue: Boolean,
});

const emit = defineEmits(["update:modelValue"]);

const studentsGroupStore = useStudentsGroupStore();
const pageData = usePage();

const tenanId = pageData?.props?.auth?.tenant?.id || false

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

//const { isLoading } = useRequestProgress()

const form = ref({
    name: "",
    tenant_id: tenanId,
    students_ids: [],
    searchQuery: ""
});

const rules = {
    name: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
};

const v$ = useVuelidate(rules, form.value, { $lazy: true });

onMounted(() => {
    nextTick(() => {
        studentsGroupStore.getStudentsWithoutGroup({
            params:{
                tenant_id: tenanId,
            }
        })
    });
});

onBeforeUnmount(() => {
    studentsGroupStore.resetStudentsWithoutGroup()
});

const handlePageChange = () => {
    nextTick(() => {
        studentsGroupStore.getStudentsWithoutGroup({
            params:{
                tenant_id: tenanId,
                page: studentsGroupStore.availableStudentsPaginationData.currentPage,
                perPage: studentsGroupStore.availableStudentsPaginationData.perPage,
            }
        })
    });
}

const currentPage = computed({
    get: () => studentsGroupStore.availableStudents.pagination.currentPage,
    set: (value) => studentsGroupStore.availableStudents.pagination.currentPage = value,
});

const handleSend = () => {
    v$.value.$touch();
    if (!v$.value.$invalid && tenanId) {
        studentsGroupStore.createGroup(form.value)
        isVisible.value = !isVisible.value;
    }
};

const toggleStudent = (id) => {
    const index = form.value.students_ids.indexOf(id);

    if (index === -1) {
        form.value.students_ids.push(id);
    } else {
        form.value.students_ids.splice(index, 1);
    }
}

let debounceTimer;
const debounceDelay = 300;

const handleFilter = () => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
        if(form.value.searchQuery.length > 2 || form.value.searchQuery === "") {
            studentsGroupStore.getStudentsWithoutGroup({
                params:{
                    tenant_id: tenanId,
                    searchQuery: form.value.searchQuery
                }
            })
        }
    }, debounceDelay);
}

// TODO: Якщо потрібно по скролу підвантажувати <perfect-scrollbar @ps-y-reach-end="handleEndList">
// const handleEndList = (e) => {
//     const { scrollTop, scrollHeight, clientHeight } = e.target;
//     if (scrollTop + clientHeight >= scrollHeight - 80) {
//         console.log('handleEndList', scrollTop, scrollHeight, clientHeight)
//     }
// }

</script>

<template>
    <b-modal
        v-model="isVisible"
        v-if="isVisible"
        hide-footer
        hide-header
        centered
        class="add-student-group__modal"
    >
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>{{ $t('t-group-create') }}</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <InputGroup
                :label="$t('t-group-name')"
                v-model="form.name"
                size="lg"
                class="modal__input-group"
                :is-error="v$.name.$invalid"
                :error-message="
                    v$.name?.$errors.find(
                        (error) => error['$validator'] === 'required'
                    )?.$message
                "
                @blur="v$.name.$touch()"
            />
            <div class="group-counter">
                Додайде студентів в групу ({{form.students_ids.length}})
            </div>
            <InputGroup
                :label="$t('t-search')"
                v-model="form.searchQuery"
                size="lg"
                @input="handleFilter"
                class="modal__student-filter-input"
            />
            <perfect-scrollbar :style="{ minHeight: '300px', maxHeight: '300px' }">
                <div
                    v-for="student of studentsGroupStore.availableStudentsList"
                    :key="student.id"
                    class="student-item"
                >
                    <b-avatar
                        variant="light"
                        :src="student.imageUrl ? student.imageUrl : noAvatar"
                        class="student-item__avatar"
                    />
                    <div class="student-item__info">
                        <div>{{student.name}}</div>
                        <div class="student-item__info--email">{{student.email}}</div>
                    </div>
                    <div class="student-item__action">
                        <b-button
                            v-if="!form.students_ids.includes(student.id)"
                            pill
                            size="md"
                            class="outline-dark"
                            @click="toggleStudent(student.id)"
                        >
                            {{ $t('t-add') }} <i class="ph-plus"></i>
                        </b-button>
                        <b-button
                            v-else
                            variant="link"
                            size="md"
                            class="in-group"
                            @click="toggleStudent(student.id)"
                        >
                            {{ $t('t-remove') }}
                        </b-button>
                    </div>
                </div>
            </perfect-scrollbar>
            <b-pagination
                v-if="studentsGroupStore.isAvailableStudentsPagination"
                class="modal__pagination"
                v-model="currentPage"
                :total-rows="studentsGroupStore.availableStudentsPaginationData.totalItems"
                :per-page="studentsGroupStore.availableStudentsPaginationData.perPage"
                aria-controls="my-table"
                @page-click="handlePageChange"
            ></b-pagination>
            <div class="d-flex justify-content-end">
                <b-button
                    variant="primary"
                    size="md"
                    pill
                    :disabled="form.name === ''"
                    @click="handleSend"
                >
                    {{ $t('btn-create') }}
                </b-button>
            </div>
        </div>
    </b-modal>
</template>
<style lang="scss">
.add-student-group__modal {
    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .group-counter {
            font-family: e-Ukraine;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            color: #1E1E1E;
            padding: 16px 0;
        }
        .student-item {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            padding: 12px 12px 12px 0;
            .student-item__info {
                flex: 1;
                font-family: e-Ukraine;
                font-size: 13px;
                font-weight: 400;
                line-height: 13px;
                text-align: left;
                color: #1E1E1E;
                display: inline-flex;
                flex-direction: column;
                gap: 4px;
                .student-item__info--email {
                    font-size: 10px;
                    font-weight: 300;
                    line-height: 10px;
                    color: #C2C2C2;
                }
            }
            .student-item__action {
                .in-group {
                    color: #F24822;
                }
            }
        }
        .modal__student-filter-input {
            margin-top: 0!important;
        }
        .modal__pagination {
            margin: 0;
        }
    }
    .ps__thumb-y {
        background-color: #1db954!important;
    }
}
</style>
