
<script setup>
import noImage from "@/../images/no-image.png";
import { useWebinarStatisticsStore } from "@/stores/webinarStatisticsStore.js";
import { computed, ref } from "vue";
import { convertSecondToTime } from "@/Components/Statistic/helpers.js";

const props = defineProps({
  workshop: Object,
});

const emit = defineEmits([
  "handle-filter",
  "download-all-statistics",
  "download-statistics-with-filter",
]);

const downloadStatisticsShow = ref(false);

function formatDateTime(isoString) {
  const dateObj = new Date(isoString);

  // Форматирование даты
  const date = dateObj.toLocaleDateString("ru-RU", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Форматирование времени
  const time = dateObj.toLocaleTimeString("ru-RU", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return { date, time };
}

const statisticDate = computed(() =>
  formatDateTime(
    webinarStatisticsStore.webinarStatistics.info.start_webinar_time
  )
);

const webinarStatisticsStore = useWebinarStatisticsStore();

const webinarReportAccount = import.meta.env.VITE_WEBINAR_REPORT_ACCOUNT;

const cdnUrl = import.meta.env.VITE_AWS_CDN;
</script>
<template>
  <div
    class="item-statistics__header d-flex justify-content-between align-items-center"
  >
    <div class="d-flex align-items-center gap-3 item-statistics__left-info">
      <div class="module-table__title-image">
        <b-img
          :src="
            webinarStatisticsStore.webinarStatistics.info?.image
              ? `${cdnUrl}/${webinarStatisticsStore.webinarStatistics.info.image}`
              : noImage
          "
          :title="workshop?.title"
          :alt="workshop?.title"
          rounded
          fluid
        ></b-img>
      </div>
      <div class="d-flex flex-column gap-2 item-statistics__event">
        <h6>
          {{
            $t(webinarStatisticsStore.webinarStatistics.info.type)
              ? $t(webinarStatisticsStore.webinarStatistics.info.type)
              : "Подія"
          }}
        </h6>
        <div>
          {{ webinarStatisticsStore.webinarStatistics.info.webinar_name }}
        </div>
      </div>
    </div>
    <div class="fields__info">
      <div>
        <div class="field__description">Тривалість</div>
        <div class="field__description--total_duration">
          {{
            convertSecondToTime(
              webinarStatisticsStore.webinarStatistics.info.total_duration
            ).value
          }}
          год
        </div>
      </div>
      <div>
        <div class="field__description">Дата та час проведення</div>
        <div class="field__description--date_time">
          <span>{{ statisticDate.date }}</span>
          |
          <span>{{ statisticDate.time }}</span>
        </div>
      </div>
      <div class="d-flex gap-3">
        <div class="download-statistics__wrapper">
          <button
            class="download-statistics__show-button"
            :class="{
              'download-statistics__show-button--active':
                downloadStatisticsShow,
            }"
            @click="() => (downloadStatisticsShow = !downloadStatisticsShow)"
          >
            <span>Завантажити звіт</span> <i class="bi bi-download"></i>
          </button>
          <div
            class="download-statistics__buttons"
            v-if="downloadStatisticsShow"
          >
            <button
              @click="
                () => {
                  downloadStatisticsShow = false;
                  emit('download-all-statistics');
                }
              "
            >
              Завантажити повний звіт
            </button>
            <button
              @click="
                () => {
                  downloadStatisticsShow = false;
                  emit('download-statistics-with-filter');
                }
              "
            >
              Завантажити відповідно до фільтру
            </button>
          </div>
        </div>
        <button class="filter-button" @click="() => emit('handle-filter')">
          <span>Фільтр</span> <i class="bi bi-funnel"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.item-statistics__header {
  font-family: "e-Ukraine";
  font-size: 13px;
  font-weight: 300;
  padding-bottom: 35px;
}

.item-statistics__event {
  font-size: 14px;
  h6 {
    color: var(--green, #1db954);
    font-weight: 400;
    margin: 0;
    font-size: 14px;
  }
}

.item-statistics__left-info {
  width: 60%;
  @media (max-width: 1790px) {
    width: 50%;
  }
}

.fields__info {
  display: flex;
  align-items: center;
  gap: 3rem;
  @media (max-width: 1870px) {
    gap: 2rem;
  }
  @media (max-width: 1790px) {
    gap: 1rem;
  }
}

.field__description {
  font-weight: 400;
  text-transform: uppercase;
  color: color-mix(in srgb, var(--dark, #1e1e1e), transparent 50%);
  &--total_duration {
    font-weight: 400;
  }
  &--date_time {
    span {
      font-weight: 400;
    }
  }
}

.download-statistics__wrapper {
  position: relative;
}

.download-statistics__buttons {
  width: 260px;
  position: absolute;
  z-index: 11;
  right: 0;
  border-radius: 12px;
  background: white;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 40px 0px #8e8d8d42;
  -webkit-box-shadow: 0 0 40px 0px #8e8d8d42;
  padding: 0;
  overflow: hidden;
  button {
    width: 100%;
    transition: 0.3s;
    text-align: left;
    padding: 12px 12px;
    &:hover {
      background-color: #3e6da52a;
    }
  }
}

.download-statistics__show-button {
  padding: 16px 24px;
  border-radius: 45px;
  border: 1px solid var(--green, #000000);
  font-family: e-Ukraine;
  font-size: 11px;
  font-weight: 300;
  line-height: 15.4px;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  min-width: 150px;
}

.download-statistics__show-button--active {
  color: #1db954;
  border: 1px solid var(--green, #1db954);
}

.filter-button {
  padding: 16px 24px;
  border-radius: 45px;
  background: var(--green, #1db954);
  border: 1px solid var(--green, #1db954);
  font-family: e-Ukraine;
  font-size: 11px;
  font-weight: 300;
  line-height: 15.4px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.module-table__title-image {
  width: 128px;
  height: 72px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  background: #f7f7f8;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 128px;
    max-height: 72px;
  }
}
</style>
