<script setup>
import DownloadPdfButton from "@/Components/buttons/DownloadPdfButton.vue";
import EmptyHistory from "@/../images/empty-data/empty_history.png";
import { useDateFormatter } from "@/composables/useDateFormatter.js";
import PaymentStatus from "@/Pages/ProductOwnerProfile/Partials/Membership/PaymentStatus.vue";

const props = defineProps({
    paymentHistory: Object,
});

const { formatDate } = useDateFormatter();
</script>

<template>
    <div class="payment-history__wrapper">
        <div class="payment-history__title">Історія платежів</div>
        <div
            v-if="paymentHistory && paymentHistory.length > 0"
            class="module-table payment-history"
        >
            <div class="module-table__header border-bottom pb-2">
                <div
                    class="module-table__col module-table__col--date text-uppercase opacity-50"
                >
                    Дата
                </div>
                <div
                    class="module-table__col module-table__col--bill-number text-uppercase opacity-50"
                >
                    Номер рахунку
                </div>
                <div
                    class="module-table__col module-table__col--plan text-uppercase opacity-50"
                >
                    Тариф
                </div>
                <div
                    class="module-table__col module-table__col--price text-uppercase opacity-50"
                >
                    Сума
                </div>
                <div
                    class="module-table__col module-table__col--status text-uppercase opacity-50"
                >
                    Статус
                </div>
                <div
                    class="module-table__col module-table__col--actions text-uppercase opacity-50"
                >
                    Виписка
                </div>
            </div>
            <div
                v-for="payment of paymentHistory"
                :key="payment.id"
                class="module-table__row border-bottom py-3"
            >
                <div class="module-table__col module-table__col--date">
                    {{ formatDate(payment.date, "dd.MM.yyyy") }}
                </div>
                <div class="module-table__col module-table__col--bill-number">
                    {{ payment.billNumber }}
                </div>
                <div class="module-table__col module-table__col--plan">
                    {{ payment.plan }}
                </div>
                <div class="module-table__col module-table__col--price">
                    {{ payment.price }}
                </div>
                <div class="module-table__col module-table__col--status" :title="payment.error">
                    <PaymentStatus :status="payment.status" />
                </div>
                <div class="module-table__col module-table__col--actions">
                    <div v-if="payment.isGift" class="lms-gift">
                        <i class="ph-gift-bold" /> {{payment.giftMessage}}
                    </div>
                    <div v-else>
                        <DownloadPdfButton
                            v-if="payment.status === 'success'"
                            :url="route('get-check', { id: payment.id })"
                        />
                        <span v-else>-</span>
                    </div>

                </div>
            </div>
        </div>
        <div v-else class="empty-plan">
            <img :src="EmptyHistory" alt="Тут поки що нічого немає" />
            <div class="empty-plan__title">Тут поки що нічого немає</div>
        </div>
    </div>
</template>

<style lang="scss">
.payment-history {
    .module-table__col--date {
        width: 10%;
        @media only screen and (max-width: 1280px) {
            width: 15%;
        }
    }

    .module-table__col--bill-number {
        width: 20%;
        @media only screen and (max-width: 1280px) {
            width: 30%;
        }
    }

    .module-table__col--plan {
        width: 10%;
        @media only screen and (max-width: 1280px) {
            width: 10%;
        }
    }

    .module-table__col--price {
        width: 10%;
        @media only screen and (max-width: 1280px) {
            width: 10%;
        }
    }

    .module-table__col--status {
        width: 10%;
        @media only screen and (max-width: 1280px) {
            width: 10%;
        }
    }

    .module-table__col--actions {
        width: 15%;
        justify-content: center;
        .lms-gift {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            text-align: center;
            i {
                font-size: 1.5rem;
                color: var(--green, #1db954);
            }
        }
        @media only screen and (max-width: 1280px) {
            width: 20%;
        }
    }
}
</style>
