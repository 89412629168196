<script setup>

import {watch} from "vue";
import axios from "axios";
import useRequestProgress from "@/composables/useRequestProgress.js";
import {toast} from "vue3-toastify";

const props = defineProps({
    form: Object
});

const { setIsLoading, isLoading } = useRequestProgress();

watch(
    () => props.form.is_manual_module_open,
    (newValue) => {
        if (newValue) {
            props.form.is_automatic_module_open = false;
        }
    }
);

watch(
    () => props.form.is_automatic_module_open,
    (newValue) => {
        if (newValue) {
            props.form.is_manual_module_open = false;
        }
    }
);

watch(
    () => props.form.is_all_lesson_open,
    (newValue) => {
        if (newValue) {
            props.form.is_after_watch_lesson_open = false;
        }
    }
);

watch(
    () => props.form.is_after_watch_lesson_open,
    (newValue) => {
        if (newValue) {
            props.form.is_all_lesson_open = false;
        }
    }
);

const handleSaveConfig = () => {
    const payload = {
        group_id: props.form.group_id,
        course_id: props.form.course_id,
        is_manual_module_open: Number(props.form.is_manual_module_open),
        is_automatic_module_open: Number(props.form.is_automatic_module_open),
        is_all_lesson_open: Number(props.form.is_all_lesson_open),
        is_after_watch_lesson_open: Number(props.form.is_after_watch_lesson_open),
        available: props.form.available,
        max_user_in_course: props.form.max_user_in_course,
        speakers: props.form.speakers,
    }
    setIsLoading(true)
    axios.post (route('api.get_action', { 'moduleName': 'group','action': `courseGroupConfigUpdate` }), payload)
        .then(() => {

        })
        .catch(error => {
            console.error(error);
            toast.error("Сталася помилка, спробуйте ще раз!", {
                autoClose: 2000,
                position: toast.POSITION.BOTTOM_CENTER,
                multiple: false
            });
        })
        .finally(() => {
            setIsLoading(false)
        })
}

</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="12">
                <b-card no-body class="shadow-none mt-4">
                    <b-card-body
                        class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                    >
                        <div class="card__title">
                            Доступи до модулів та окремих уроків
                        </div>

                        <b-form-group class="mt-4 modules-open-checkboxes">
                            <b-form-checkbox
                                v-model="form.is_manual_module_open"
                                :disabled="form.is_manual_module_open"
                            >
                                Відкривати власноруч для кожного студента
                                <i
                                    id="tooltip-manualy"
                                    class="bi bi-info-circle"
                                ></i>
                            </b-form-checkbox>
                            <b-tooltip
                                target="tooltip-manualy"
                                custom-class="custom-tooltip"
                                offset="5"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                При встановлені цього параметру ви
                                самостійно відкриваєте модулі та уроки поза
                                модулями для кожного студента: послідовно
                                або всі відразу.
                            </b-tooltip>
                            <b-form-checkbox
                                v-model="form.is_automatic_module_open"
                                :disabled="form.is_automatic_module_open"
                            >
                                Відкриваються автоматично
                                <i
                                    id="tooltip-auto"
                                    class="bi bi-info-circle"
                                ></i>
                            </b-form-checkbox>
                            <b-tooltip
                                target="tooltip-auto"
                                custom-class="custom-tooltip"
                                offset="5"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Кожен наступний модуль або урок поза модулем
                                буде відкриватись студенту автоматично після
                                проходження всіх уроків до цього.
                            </b-tooltip>
                        </b-form-group>
                    </b-card-body>
                </b-card>

                <b-card no-body class="shadow-none mt-4">
                    <b-card-body
                        class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                    >
                        <div class="card__title">
                            Доступи до уроків в модулі
                        </div>

                        <b-form-group class="mt-4 lessons-open-checkboxes">
                            <b-form-checkbox
                                v-model="form.is_all_lesson_open"
                                :disabled="form.is_all_lesson_open"
                            >
                                Відкриваються відразу
                                <i
                                    id="tooltip-by-date"
                                    class="bi bi-info-circle"
                                ></i>
                            </b-form-checkbox>
                            <b-tooltip
                                target="tooltip-by-date"
                                custom-class="custom-tooltip"
                                offset="5"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Після початку роботи з модулем студенту
                                будуть доступні відразу всі уроки в цьому
                                модулі.
                            </b-tooltip>
                            <b-form-checkbox
                                v-model="form.is_after_watch_lesson_open"
                                :disabled="form.is_after_watch_lesson_open"
                            >
                                Відкриваються послідовно
                                <i
                                    id="tooltip-first"
                                    class="bi bi-info-circle"
                                ></i>
                            </b-form-checkbox>
                            <b-tooltip
                                target="tooltip-first"
                                custom-class="custom-tooltip"
                                offset="5"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Після початку роботи з модулем студенту
                                відкриється лише перший урок. Кожен
                                наступний урок буде відкриватись після
                                проходження попереднього.
                            </b-tooltip>
                        </b-form-group>
                    </b-card-body>
                </b-card>
                <b-button
                    variant="primary"
                    size="lg"
                    pill
                    class="mt-3 float-end"
                    :disabled="isLoading"
                    @click="handleSaveConfig()"
                >
                    Зберегти
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<style scoped lang="scss">

</style>
