<script setup>
import { Head, Link, usePage } from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";
import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import EmptyData from "@/Components/EmptyData.vue";
import { onMounted, computed } from "vue";

import ShareModal from "@/Components/modals/ShareModal.vue";
import noImage from "../../../images/no-image.png";
import DropdownButtons from "@/Components/buttons/DropdownButtons.vue";
import StatusBage from "@/Components/StatusBage.vue";
import PerPageSelector from "@/Components/form/PerPageSelector.vue";
import FilterSelectorStatistics from "@/Components/form/FilterSelectorStatistics.vue";
import PeriodFilterSelectorStatistics from "@/Components/form/PeriodFilterSelectorStatistics.vue";
import WebinarConnectButton from "@/Components/WebinarButtons/WebinarConnectButton.vue";
import StatisticListTotalDurationItem from "@/Components/Statistic/StatisticListTotalDurationItem.vue";

import { useWebinarStatisticsStore } from "@/stores/webinarStatisticsStore.js";
import useRequestProgress from "@/composables/useRequestProgress.js";

import {
  toISOString,
  fromISOStringReverse,
  translateStatisticPrefix,
  convertSecondToTime,
} from "@/Components/Statistic/helpers.js";

const props = defineProps({
  user: Object,
  workshops: Object,
  workshopTypes: Object,
  workshopStatistic: Object,
});

const { t } = useI18n();

const page = {
  title: t("t-workshops"),
  breadcrumbs: [
    {
      text: "Дашборд",
      href: route("dashboard"),
    },
    {
      text: "Воркшопи",
      href: route("workshops.workshops"),
    },
    {
      text: "Статистика",
      active: true,
    },
  ],
};

const createLinks = [
  { label: "Створити вебінар", link: "/workshops/create/webinar" },
  { label: "Створити автовебінар", link: "/workshops/create/auto-webinar" },
  { label: "Створити майстерклас", link: "/workshops/create/masterclass" },
];

const pageData = usePage();

const { isLoading } = useRequestProgress();

const tenantId = pageData?.props?.auth?.tenant?.id || false;

const userId = pageData?.props?.auth.user?.id || false;

const cdnUrl = import.meta.env.VITE_AWS_CDN;

function generateStatisticLink(type, id) {
  return route("workshops.item-workshop-statistic", {
    type: type.toLowerCase(),
    id: id,
  });
}

const webinarStatisticsStore = useWebinarStatisticsStore();

const setPage = async (e, page) => {
  await fetchAndSyncWebinarStatisticsList({ page });
};

const handlePerPageChange = async (amount) => {
  webinarStatisticsStore.setWebinarStatisticsListPerPage(amount, true);
  await fetchAndSyncWebinarStatisticsList();
};

const handleSortingChange = async (value) => {
  webinarStatisticsStore.changeSortingWebinarStatisticsList(value);
  await fetchAndSyncWebinarStatisticsList();
};

const handleGroupFilterChange = async (filter, value) => {
  await fetchAndSyncWebinarStatisticsList({ page: 1 });
};

async function fetchAndSyncWebinarStatisticsList(fetchData) {
  if (fetchData?.page) {
    webinarStatisticsStore.setWebinarStatisticsListCurrentPage(fetchData.page);
  }
  if (fetchData?.perPage) {
    webinarStatisticsStore.setWebinarStatisticsListPerPage(fetchData.perPage);
  }
  try {
    await webinarStatisticsStore.getWebinarStatisticsList();

    await webinarStatisticsStore.getWebinarsLinksCabinet();

    // setQueryParamsToUrl();
  } catch (error) {
    console.error("Failed to fetch webinar statistics List:", error);
  }
}

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

const periodChangeHandle = async (key, value) => {
  const filters =
    webinarStatisticsStore.webinarStatisticsList.installedFilters[key].filters;

  // Проверка на отсутствие периодов и значения
  if (!filters?.period_from && !filters?.period_to && !value) {
    return;
  }

  // Удаление периодов, если значение отсутствует, но есть установленные фильтры
  if ((filters?.period_from || filters?.period_to) && !value) {
    filters.period_from = null;
    filters.period_to = null;
    await fetchAndSyncWebinarStatisticsList({ page: 1 });
    return;
  }

  let periodFrom, periodTo;

  // Если значение содержит " to ", обрабатываем диапазон дат
  if (value?.includes(" to ")) {
    const [from, to] = value.split(" to ");
    periodFrom = toISOString(from);
    periodTo = toISOString(to, true);
  } else {
    // Обрабатываем одиночную дату
    periodFrom = toISOString(value);
    periodTo = toISOString(value, true);
  }

  // Обновляем фильтры
  webinarStatisticsStore.webinarStatisticsList.installedFilters[key].filters = {
    period_from: periodFrom,
    period_to: periodTo,
  };

  // Синхронизируем список
  await fetchAndSyncWebinarStatisticsList({ page: 1 });
};

const isStatisticsEmpty = computed(
  () =>
    !webinarStatisticsStore.webinarStatisticsList.list.length &&
    !Object.values(
      webinarStatisticsStore.createWebinarStatisticsListFilterObj()
    ).length
);

const translateStatistic = (key) =>
  t(translateStatisticPrefix + key) === translateStatisticPrefix + key
    ? key
    : t(translateStatisticPrefix + key);

onMounted(async () => {
  //   const url = new URL(window.location.href);

  //   const page = Number(url.searchParams.get("page")) || 1;

  //   const perPage = Number(url.searchParams.get("per_page")) || 1;

  //   webinarStatisticsStore.setWebinarStatisticsListInitialQueryParams({
  //     page,
  //     perPage,
  //   });

  webinarStatisticsStore.setTenantId(tenantId);

  webinarStatisticsStore.setUserId(userId);
  if (
    isEmptyObject(webinarStatisticsStore.webinarStatisticsList.installedFilters)
  ) {
    await webinarStatisticsStore.getWebinarStatisticsListFilters(tenantId);
  }
  await fetchAndSyncWebinarStatisticsList();
  //   const res = await axios.post("/api/module/workShop/sendTenantSurveyAnswers", {
  //     user_id: page.props.auth?.tenant?.id,
  //     answers: answers.value,
  //   });
});
</script>

<template>
  <div>
    <Head title="Статистика" />

    <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />

    <!-- <PageContentCard :title="$t('t-workshops')"> -->
    <PageContentCard title="Статистика">
      <template #before-title>
        <Link class="back-to-workshops" :href="route('workshops.workshops')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="24"
            viewBox="0 0 15 24"
            fill="none"
          >
            <path
              d="M10.8213 5.60467C11.0203 5.60066 11.2155 5.66397 11.3799 5.78584C11.5443 5.9077 11.6698 6.0821 11.739 6.2849C11.8082 6.4877 11.8178 6.70887 11.7663 6.9178C11.7148 7.12674 11.6048 7.31311 11.4516 7.45113L5.58747 12.9078L11.4552 18.3645C11.5624 18.4495 11.6522 18.5576 11.719 18.6822C11.7858 18.8068 11.8281 18.9451 11.8433 19.0883C11.8585 19.2315 11.8462 19.3767 11.8071 19.5145C11.7681 19.6523 11.7032 19.7798 11.6166 19.889C11.53 19.9982 11.4234 20.0867 11.3038 20.1489C11.1841 20.2111 11.0538 20.2456 10.9212 20.2503C10.7887 20.2551 10.6566 20.2299 10.5334 20.1763C10.4103 20.1228 10.2986 20.0421 10.2056 19.9393L3.49755 13.707C3.39205 13.6091 3.30735 13.4874 3.24927 13.3505C3.19118 13.2135 3.16111 13.0645 3.16111 12.9137C3.16111 12.7629 3.19118 12.6139 3.24927 12.477C3.30735 12.34 3.39205 12.2184 3.49755 12.1204L10.2056 5.86845C10.3756 5.70396 10.5938 5.61047 10.8213 5.60467Z"
              fill="white"
            />
          </svg> </Link
      ></template>
      <template #header-start> </template>
      <template #default>
        <template v-if="isStatisticsEmpty && !isLoading">
          <div class="empty-statistics">
            <EmptyData type="workshopStatistics">
              <template v-slot:title> Тут поки що нічого немає </template>
              <template v-slot:description>
                <div>
                  Проведіть свій перший вебінар або майстер-клас, і після цього
                  тут буде відображено детальну статистику про ваші заходи
                </div>
              </template>
              <template v-slot:action>
                <DropdownButtons :buttons="createLinks">
                  <template v-slot:toggle-button>
                    <b-button pill size="lg" class="outline-dark">
                      {{ $t("btn-create") }}
                      <i class="ph-plus"></i>
                    </b-button>
                  </template>
                </DropdownButtons>
              </template>
            </EmptyData>
          </div>
        </template>
        <template v-else-if="!isStatisticsEmpty">
          <div class="list-filters__wrapper">
            <div class="list-filters__container">
              <div class="list-filters__item">
                <div class="text-uppercase opacity-50">Показувати</div>
                <PerPageSelector
                  :current-value="
                    webinarStatisticsStore.webinarStatisticsList.pagination
                      .perPage
                  "
                  @choose-amount="handlePerPageChange"
                />
              </div>
              <template
                v-if="
                  webinarStatisticsStore.webinarStatisticsList.filters?.length
                "
              >
                <div
                  class="list-filters__item"
                  v-for="filter in webinarStatisticsStore.webinarStatisticsList
                    .filters"
                  :key="filter.key"
                >
                  <div class="text-uppercase opacity-50">
                    {{ translateStatistic(filter.key) }}:
                  </div>
                  <template v-if="filter.type === 'single'">
                    <FilterSelectorStatistics
                      :current-value="
                        webinarStatisticsStore.webinarStatisticsList
                          .installedFilters[filter.key].filters
                      "
                      :filter-list="filter.value"
                      @choose-filter="
                        (value) => {
                          handleGroupFilterChange(filter, value);
                        }
                      "
                    />
                  </template>
                  <template v-if="filter.type === 'period'">
                    <PeriodFilterSelectorStatistics
                      :current-value="
                        webinarStatisticsStore.webinarStatisticsList
                          .installedFilters[filter.key].filters
                      "
                      @choose-filter="
                        (value) => handleGroupFilterChange(filter, value)
                      "
                      @change-period="
                        (value) => periodChangeHandle(filter.key, value)
                      "
                  /></template>
                </div>
              </template>
            </div>
            <div class="list-filters__actions"></div>
          </div>
          <div class="module-table workshops-list">
            <div class="module-table__header border-bottom pb-2">
              <div
                class="module-table__col module-table__col--name text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsListCurrentSortingByName(
                      'webinar_name'
                    )
                  "
                  @click="handleSortingChange('webinar_name')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Назва
                </div>
              </div>
              <div
                class="module-table__col module-table__col--viewers text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsListCurrentSortingByName(
                      'count_of_viewers'
                    )
                  "
                  @click="handleSortingChange('count_of_viewers')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Глядачів
                </div>
              </div>
              <div
                class="module-table__col module-table__col--comments text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsListCurrentSortingByName(
                      'count_of_messages'
                    )
                  "
                  @click="handleSortingChange('count_of_messages')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Коментарів
                </div>
              </div>
              <div
                class="module-table__col module-table__col--type text-uppercase"
              >
                <div class="table-column__sorting">Тип</div>
              </div>
              <div
                class="module-table__col module-table__col--date text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsListCurrentSortingByName(
                      'start_webinar_time'
                    )
                  "
                  @click="handleSortingChange('start_webinar_time')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Дата проведення
                </div>
              </div>
              <div
                class="module-table__col module-table__col--durations text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsListCurrentSortingByName(
                      'total_duration'
                    )
                  "
                  @click="handleSortingChange('total_duration')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Тривалість
                </div>
              </div>
              <div
                class="module-table__col module-table__col--status text-uppercase"
              >
                <div class="table-column__sorting">Статус</div>
              </div>
              <div
                class="module-table__col module-table__col--actions text-uppercase"
              >
                <div class="table-column__sorting">{{ $t("t-actions") }}</div>
              </div>
            </div>
            <div
              v-for="(workshop, index) of webinarStatisticsStore
                .webinarStatisticsList.list"
              :key="index"
              class="module-table__row border-bottom py-3"
            >
              <div class="module-table__col module-table__col--name">
                <div class="module-table__title-image">
                  <b-img
                    :src="
                      workshop?.image ? `${cdnUrl}/${workshop.image}` : noImage
                    "
                    :title="workshop?.webinar_name"
                    :alt="workshop?.webinar_name"
                    rounded
                    fluid
                  ></b-img>
                </div>
                <div class="module-table__title-text">
                  <span class="table-col__title"
                    >{{ workshop.webinar_name }}
                  </span>
                  <span class="table-col__subtitle">{{
                    workshop.description && workshop.description.length > 0
                      ? workshop.description
                      : "без опису"
                  }}</span>
                </div>
              </div>
              <div class="module-table__col module-table__col--viewers">
                <span> {{ workshop.count_of_viewers }} </span>
              </div>
              <div class="module-table__col module-table__col--comments">
                <span> {{ workshop.count_of_messages }} </span>
              </div>
              <div class="module-table__col module-table__col--type">
                <span>{{ $t(workshop.type) }}</span>
              </div>
              <div class="module-table__col module-table__col--date">
                <span>
                  {{ fromISOStringReverse(workshop.start_webinar_time, ".") }}
                </span>
              </div>
              <div class="module-table__col module-table__col--durations">
                <StatisticListTotalDurationItem
                  :seconds="workshop.total_duration"
                />
              </div>
              <div class="module-table__col module-table__col--status">
                <WebinarConnectButton
                  v-if="workshop.status === 'PENDING'"
                  :link="
                    webinarStatisticsStore.findWebinarLinkCabinet(
                      workshop.webinar_id
                    )
                  "
                />
                <StatusBage
                  v-else
                  :type="translateStatisticPrefix + workshop.status"
                ></StatusBage>
              </div>
              <div class="module-table__col module-table__col--actions">
                <Link
                  :href="
                    generateStatisticLink(workshop.type, workshop.webinar_id)
                  "
                  class="workshop-statistics__details-button"
                  >Детальніше</Link
                >
              </div>
            </div>
          </div>
          <div class="module-table__footer">
            <div class="module-table__footer--info">
              Відображено:
              <span
                v-if="
                  webinarStatisticsStore.webinarStatisticsList.list.length > 1
                "
              >
                {{
                  webinarStatisticsStore.webinarStatisticsList.pagination
                    .currentPage *
                    webinarStatisticsStore.webinarStatisticsList.pagination
                      .perPage -
                  webinarStatisticsStore.webinarStatisticsList.pagination
                    .perPage +
                  1
                }}-{{
                  webinarStatisticsStore.webinarStatisticsList.pagination
                    .currentPage *
                    webinarStatisticsStore.webinarStatisticsList.pagination
                      .perPage -
                  webinarStatisticsStore.webinarStatisticsList.pagination
                    .perPage +
                  webinarStatisticsStore.webinarStatisticsList.list.length
                }}
              </span>
              <span v-else>
                {{
                  webinarStatisticsStore.webinarStatisticsList.pagination
                    .currentPage *
                    webinarStatisticsStore.webinarStatisticsList.pagination
                      .perPage -
                  webinarStatisticsStore.webinarStatisticsList.pagination
                    .perPage +
                  webinarStatisticsStore.webinarStatisticsList.list.length
                }}
              </span>
              <span> з </span>
              <span>
                {{
                  webinarStatisticsStore.webinarStatisticsList.pagination.total
                }}
              </span>
            </div>
            <b-pagination
              v-if="
                webinarStatisticsStore.webinarStatisticsList.pagination &&
                webinarStatisticsStore.webinarStatisticsList.pagination.total >
                  0
              "
              v-model="
                webinarStatisticsStore.webinarStatisticsList.pagination
                  .currentPage
              "
              class="module-table__footer--pagination"
              :total-rows="
                webinarStatisticsStore.webinarStatisticsList.pagination.total
              "
              :per-page="
                webinarStatisticsStore.webinarStatisticsList.pagination.perPage
              "
              aria-controls="my-table"
              first-number
              last-number
              @page-click="setPage"
            >
              <template #prev-text><i class="ph-caret-left-bold"></i></template>
              <template #next-text
                ><i class="ph-caret-right-bold"></i
              ></template>
            </b-pagination>
          </div>
          <ShareModal v-model="shareLinkModal" :shared-link="shareLink" />
        </template>
      </template>
    </PageContentCard>
  </div>
</template>

<style lang="scss" scoped>
#shareLink {
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--green, #1db954);
  background: linear-gradient(
      0deg,
      rgba(29, 185, 84, 0.04) 0%,
      rgba(29, 185, 84, 0.04) 100%
    ),
    #fff;
}

.empty-statistics {
  font-family: "e-Ukraine";
  height: calc(100vh - 234px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.workshops-list {
  .module-table__row {
    position: relative;
    .workshops-tags {
      position: absolute;
      top: 4px;
      left: 4px;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    &:hover {
      background-color: color-mix(
        in srgb,
        var(--tb-module-table-bage-bg),
        transparent 60%
      );
      border-color: #1db954 !important;

      .module-table__user-avatar {
        color: #1db954;
        border-color: #1db954 !important;
      }
    }
  }

  .module-table__col--name {
    display: flex;
    flex: 1;
    align-items: center;
    padding-right: 16px;

    @media (max-width: 992px) {
      width: 100%;
    }

    .module-table__title-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
      flex-wrap: nowrap;
      padding: 10px 16px;
      width: calc(100% - 125px);

      .table-col__title {
        font-family: e-Ukraine;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0;
        text-align: left;
        color: #1e1e1e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }

      .table-col__subtitle {
        font-family: e-Ukraine;
        font-size: 14px;
        font-weight: 200;
        line-height: 1.5;
        letter-spacing: 0;
        text-align: left;
        color: #1e1e1e;
        opacity: 0.5;
        display: -webkit-box;
        display: -moz-box;
        -webkit-line-clamp: 2;
        -moz-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .module-table__col--viewers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 10%;

    @media (max-width: 992px) {
      width: 50%;
    }
  }
  .module-table__col--comments {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 10%;

    @media (max-width: 992px) {
      width: 50%;
    }
  }

  .module-table__col--durations {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 11%;

    @media (max-width: 1570px) {
      width: 13%;
    }

    @media (max-width: 992px) {
      width: 50%;
    }
  }
  .workshop-statistics__details-button {
    padding: 12px 24px;
    border-radius: 45px;
    background: var(--green, #1db954);
    border: 1px solid var(--green, #1db954);
    font-family: e-Ukraine;
    font-size: 11px;
    font-weight: 300;
    line-height: 15.4px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .module-table__col--type {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 10%;
  }
  .module-table__col--status {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 7%;
    @media (max-width: 1570px) {
      width: 8%;
    }
  }

  .module-table__col--actions {
    width: 10%;
    justify-content: center !important;
    //i {
    //  font-size: large;
    //}
  }

  .module-table__col--date {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 11%;
    @media (max-width: 1570px) {
      width: 13%;
    }
  }

  .module-table__title-image {
    width: 128px;
    height: 72px;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    background: #f7f7f8;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 128px;
      max-height: 72px;
    }
  }
}

.list-filters__wrapper {
  font-family: e-Ukraine;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 8px 12px 8px;
  border-radius: 8px;
  border: 1px solid var(--stroke-default-100, #e7e7e7);
  background: var(--background-default-50, #f6f6f6);
  margin-bottom: var(--tb-card-spacer-x);
  .list-filters__container,
  .list-filters__actions {
    display: flex;
    gap: 12px;
  }
}

.back-to-workshops {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  padding-bottom: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--purple, #f24822);
  cursor: pointer;
}

.module-table__footer {
  padding: 24px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-family: e-Ukraine;

  &--info {
    font-size: 12px;
    color: #888888;
    flex: 0 0 auto;
    span {
      font-weight: 700;
    }
  }

  &--pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
