<script setup>
import { computed, ref } from "vue";
import InputGroup from "@/Components/InputGroup.vue";
import { useAPIForm } from "@/composables/useApiForm.js";
import { toast } from "vue3-toastify";
import {helpers, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {useFileUploadStore} from "@/stores/fileUploadStore.js";
import {useI18n} from "vue-i18n";

const props = defineProps({
    modelValue: Boolean,
    routeArgs: Array,
    fileType: "file",
    allExceptVideo: {
        type: Boolean,
        default: false,
    },
    customFileExtensions: {
        type: Array,
        default: null,
    },
});

const emit = defineEmits(["update:modelValue", "upload-complete"]);

const { t } = useI18n();

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const fileUploadStore = useFileUploadStore()

const tmpPresign = ref(null);

const form = useAPIForm({
    name: "",
    // file: null,
    description: "",
    storageData: {},
});

const rules = {
    name: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    }
}

const v$ = useVuelidate(rules, form, { $lazy: true });

const cancelTokenSource = axios.CancelToken.source();

const handleSend = () => {
    v$.value.$touch();
    if (!v$.value.$invalid) {
        submitForm();
    }
};

// const submitForm = async () => {
//     // console.log(route(...props.routeArgs));
//     isLoading.value = true;
//     // console.log(file, route('presigned', {'type': 'file', 'file': file.value.name}));
//     // const presign = await getPresign(route('presigned', {'type': 'video', 'file': form.video.name}));
//     // console.log(props);
//     const presign = await getPresign(
//         route("presigned", {
//             type: props.fileType,
//             file: file.value.name,
//             file_name: form.name,
//             file_size: file.value.size,
//             file_description: form.description,
//         })
//     );
//     tmpPresign.value = presign;
//     console.log("presign", presign);
//     console.log("start upload");
//
//     console.log(file.value.type);
//     uploadToS3(file, presign.url);
//
//     console.log("endUpload");
//     // form.preview = base64;
//     // form.duration = duration;
//     console.log("Start update storage data");
//     form.storageData = presign.storageData;
//     form.storageData.description = form.name;
//     if (props.routeArgs) {
//         form.post(route(...props.routeArgs), {
//             onSuccess: onFormSubmit,
//         });
//     } else {
//         onFormSubmitEmpty();
//     }
//     console.log("End update storage data");
//     // console.log(...props.routeArgs);
// };

const submitForm = async () => {
    isLoading.value = true;

    try {
        const response = await fetch(route("presigned", {
            type: props.fileType,
            file: file.value.name,
            file_name: form.name,
            file_size: file.value.size,
            file_description: form.description,
        }));

        if (!response.ok) {
            if (response.status === 403) {
                toast.error("Ліміт диска вичерпано", {
                    autoClose: 3000,
                    position: toast.POSITION.BOTTOM_CENTER,
                    multiple: false
                });
            }
            throw new Error(`Помилка HTTP: ${response.status}`);
        }

        const presign = await response.json();
        tmpPresign.value = presign;
        isLoading.value = false;
        await uploadToS3(form, presign);

    } catch (error) {
        console.error("Error in submitForm:", error);
        // TODO: обробити інші помилки
    } finally {
        console.log("Presign request completed");
        isLoading.value = false;
    }
};

const uploadToS3 = async (form, presign) => {
    const formData = new FormData();
    formData.append("file", file.value);
    fileUploadStore.setIsUpload(true)
    fileUploadStore.setUploadProgress(0)
    try {
        const response = await axios.put(presign.url, file.value, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": file.value.type,
                "Content-Disposition": "inline",
            },
            cancelToken: cancelTokenSource.token,
            onUploadProgress: (progressEvent) => {
                fileUploadStore.setUploadProgress((progressEvent.loaded * 100) / progressEvent.total)
            },
            withCredentials: false,
            mode: "no-cors",
        });

        if (response.status === 200) {
            form.storageData = presign.storageData;
            form.storageData.description = form.name;
            if (props.routeArgs) {
                form.post(route(...props.routeArgs), {
                    onSuccess: onFormSubmit,
                });
            } else {
                onFormSubmitEmpty();
            }
            return response.data;
        } else {
            console.error("Error uploading file to S3:", response.statusText);
            fileUploadStore.setIsUpload(false);
            isVisible.value = !isVisible.value;
            toast.error("Помилка, спробуйте ще раз!", {
                autoClose: 3000,
                position: toast.POSITION.BOTTOM_CENTER,
                multiple: false
            });
            return null;
        }
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log("Upload canceled by the user.");
        } else {
            console.error("Error uploading file to S3:", error.message);
            fileUploadStore.setIsUpload(false);
            isVisible.value = !isVisible.value;
            toast.error("Помилка, спробуйте ще раз!", {
                autoClose: 3000,
                position: toast.POSITION.BOTTOM_CENTER,
                multiple: false,
            });
        }
        return null;
    }
};

// const uploadToS3_ = async (file, presign) => {
//     const formData = new FormData();
//     formData.append("file", file.value);
//     console.log(file.value.type);
//     try {
//         const response = await axios.put(presign, file.value, {
//             headers: {
//                 "Access-Control-Allow-Origin": "*",
//                 "Content-Type": file.value.type,
//                 "Content-Disposition": "inline",
//             },
//             withCredentials: false,
//             mode: "no-cors",
//         });
//
//         if (response.status === 200) {
//             console.log("File successfully uploaded to S3");
//             return response.data;
//         } else {
//             console.error("Error uploading file to S3:", response.statusText);
//             return null;
//         }
//     } catch (error) {
//         console.error("Error uploading file to S3:", error.message);
//         return null;
//     }
// };

// const getPresign = async (url) => {
//     let response = await fetch(url);
//     if (response.ok) {
//         let json = await response.json();
//         return json;
//     } else {
//         if (response.status === 403) {
//             toast.error("Ліміт диска вичерпано", {
//                 autoClose: 3000,
//                 position: toast.POSITION.BOTTOM_CENTER,
//             });
//         }
//         console.log("Ошибка HTTP: " + response.status);
//         isVisible.value = !isVisible.value;
//     }
// };

const cancelUpload = () => {
    cancelTokenSource.cancel("Upload canceled by the user.");
    fileUploadStore.setIsUpload(false);
    isVisible.value = !isVisible.value;
};

const onFormSubmit = (response) => {
    form.reset();
    file.value = null;
    fileInput.value = null;
    emit("upload-complete", tmpPresign.value);
    isVisible.value = !isVisible.value;
    fileUploadStore.setIsUpload(false);
    toast.success(t('request-success'), {
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_CENTER,
        multiple: false
    });
};

const onFormSubmitEmpty = () => {
    form.reset();
    file.value = null;
    fileInput.value = null;
    emit("upload-complete", tmpPresign.value);
    isLoading.value = false;
    isVisible.value = !isVisible.value;
    fileUploadStore.setIsUpload(false)
    toast.success(t('request-success'), {
        autoClose: 3000,
        position: toast.POSITION.BOTTOM_CENTER,
        multiple: false
    });
};

//const allowedExtensions = ['.doc', '.docx', '.xml', '.pdf', '.png', '.jpg'];
const allowedExtensions = {
    file: props.customFileExtensions
        ? props.customFileExtensions
        : [".doc", ".docx", ".xml", ".pdf"],
    image: [".png", ".jpg"],
};

const isLoading = ref(false);
const file = ref(null);
const fileInput = ref(null);

const isFile = computed(() => !!file.value);

const openFilePicker = () => {
    if (fileInput) {
        fileInput.value.click();
    }
};

const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
};

const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile) {
        if (props.allExceptVideo) {
            if (droppedFile.type.startsWith("video/")) {
                console.log("handleFileChange");
                file.value = null;
                return;
            } else {
                file.value = droppedFile;
            }
        } else if (isAllowedType(droppedFile)) file.value = droppedFile;
    }
};

const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
        if (props.allExceptVideo) {
            if (selectedFile.type.startsWith("video/")) {
                console.log("handleFileChange");
                file.value = null;
                return;
            } else {
                file.value = selectedFile;
            }
        } else if (isAllowedType(selectedFile)) file.value = selectedFile;
    }
};

const isAllowedType = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions[props.fileType].includes(`.${fileExtension}`);
};

const formatFileType = (file) => {
    return file.name.slice(file.name.lastIndexOf(".")).toLowerCase();
};
</script>

<template>
    <b-modal
        v-model="isVisible"
        hide-footer
        hide-header
        centered
        no-close-on-backdrop
        no-close-on-esc
    >
        <div class="modal-body" v-if="fileUploadStore.isUpload">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>Завантаження</h3>
                <h3>

                </h3>
            </div>
            <div v-if="file && file.name" class="upload-progress__file-name">{{file.name}}</div>
            <b-progress :max="100" height="2rem" variant="primary" animated>
                <b-progress-bar :value="fileUploadStore.uploadProgress" :label="`${fileUploadStore.uploadProgress.toFixed(2)}%`" class="upload-progress-bar"></b-progress-bar>
            </b-progress>
            <div class="d-flex justify-content-end">
                <b-button
                    variant="primary"
                    size="md"
                    pill
                    class="mt-3"
                    @click="cancelUpload"
                >
                    Скасувати
                </b-button>
            </div>
        </div>
        <div v-else class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>Додати файл</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <b-spinner
                v-if="isLoading"
                variant="primary"
                label="Spinning"
                class="wait-spinner"
            ></b-spinner>
            <div
                class="file-uploader"
                @dragover.prevent="handleDragOver"
                @drop.prevent="handleDrop"
                @click="openFilePicker"
            >
                <i class="p ph-paperclip-bold fs-1"></i>
                <div class="file-uploader__title">
                    <template v-if="isFile">
                        {{ file.name }}
                    </template>
                    <template v-else>
                        <span class="text-primary">Завантажити</span> файл
                    </template>
                </div>
                <div v-if="allExceptVideo" class="file-uploader__subtitle">
                    {{ !isFile ? "" : `Тип файлу: ${formatFileType(file)}` }}
                </div>
                <div v-else class="file-uploader__subtitle">
                    {{
                        !isFile
                            ? allowedExtensions[fileType].join(", ")
                            : `Тип файлу: ${formatFileType(file)}`
                    }}
                </div>
                <input
                    ref="fileInput"
                    type="file"
                    style="display: none"
                    @change="handleFileChange"
                />
            </div>
            <InputGroup
                label="Назва файлу"
                placeholder="Додайте назву файлу для студентів"
                v-model="form.name"
                size="lg"
                class=""
                :is-error="v$.name?.$error"
                :error-message="v$.name?.$errors[0]?.$message"
                @blur="v$.name.$touch()"
            />
            <InputGroup
                label="Опис файлу"
                placeholder="Додайте опис файлу для студентів"
                v-model="form.description"
                size="lg"
                class=""
            />
            <div class="upload-warning-text">
                Будь ласка не закривайте і не перезавантажуйте сторінку поки йде завантаження файлу!
            </div>
            <div class="d-flex justify-content-end">
                <b-button
                    variant="primary"
                    size="md"
                    :disabled="!isFile && !isLoading"
                    pill
                    class="mt-3"
                    @click="handleSend"
                >
                    Завантажити
                </b-button>
            </div>
        </div>
        <div v-if="isLoading" class="loading-backdrop"></div>
    </b-modal>
</template>
<style lang="scss" scoped>
.upload-warning-text {
    font-family: e-Ukraine;
    background: #FFF3CD;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
    padding: 18px 24px;
    gap: 8px;
    color: #856404;
    margin-top: 16px;
}
.wait-spinner {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: calc(50% - 1.5rem);
    left: calc(50% - 1.5rem);
    z-index: 1000;
}

.file-uploader {
    max-width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    padding: 20px 50px;
    margin: 0 auto;

    //.file-uploader__title,
    //.file-uploader__subtitle,
    i {
        color: var(--green, #1db954);
        text-align: center;
        font-style: normal;
    }

    .file-uploader__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 100%;
    }

    .file-uploader__subtitle {
        font-size: 12px;
        font-weight: 300;
        line-height: 140%;
        opacity: 0.5;
    }
}

.loading-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.25;
    top: 0;
    left: 0;
    z-index: 999;
}
.upload-progress-bar {
    font-family: e-Ukraine;
    font-weight: bold;
    font-size: 0.9rem;
}
.upload-progress__file-name {
    font-family: e-Ukraine;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.5;
    word-break: break-all;
    color: var(--dark, #1e1e1e);
    margin-bottom: 16px;
}
</style>
