<script setup>
import english from "../../images/flags/gb.svg";
import ukraine from "../../images/flags/ua.svg";
import i18n from "@/i18n.js";
import { onMounted, ref } from "vue";
import { Link } from "@inertiajs/vue3";

const lan = ref("uk");

const languages = [
  {
    flag: ukraine,
    language: "ua",
    title: "Українська",
  },
  {
    flag: english,
    language: "en",
    title: "English",
  },
];
</script>

<template>
  <div class="login-layout">
    <!-- ============================================================== -->
    <!-- Login Navbar -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- Page Content -->
    <!-- ============================================================== -->
    <slot />
  </div>
</template>

<style lang="scss">
</style>
