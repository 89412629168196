import { watch } from "vue";
import { usePage } from "@inertiajs/vue3";

export default function useClearWorkshopStatisticsHandler(clearStoreCallback) {
    const page = usePage();

    const isStatisticsRoute = (url) => {
        const basePattern = /^\/workshops\/statistics$/;
        const detailedPattern = /^\/workshops\/statistics\/[^/]+\/[^/]+$/;

        return basePattern.test(url) || detailedPattern.test(url);
    };

    watch(
        () => page.url,
        (newUrl) => {
            if (!isStatisticsRoute(newUrl)) {
                clearStoreCallback();
            }
        }
    );
}
