<script setup>
import { computed, ref } from "vue";
import InputGroup from "@/Components/InputGroup.vue";
import { useAPIForm } from "@/composables/useApiForm.js";
import { toast } from "vue3-toastify";

const props = defineProps({
    modelValue: Boolean,
    routeArgs: Array,
    fileType: "file",
    allExceptVideo: {
        type: Boolean,
        default: false,
    },
    initialMessage: String,
    conversationId: String,
});

const emit = defineEmits(["update:modelValue", "upload-complete"]);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const tmpPresign = ref(null);

const cancelTokenSource = axios.CancelToken.source();

const form = useAPIForm({
    name: "",
    message: {},
    messageInput: props.initialMessage,
    attachments: [],
    storageData: {},
});

const handleSend = () => {
    submitForm();
};

const submitForm = async () => {
    isLoading.value = true;
    // console.log(file, route('presigned', {'type': 'file', 'file': file.value.name}));
    // const presign = await getPresign(route('presigned', {'type': 'video', 'file': form.video.name}));
    // console.log(props);
    try {
        const presign = await getPresign(
            route("chat.presigned.by-id", {
                conversationId: props.conversationId,
                type: props.fileType,
                file: file.value.name,
                file_size: file.value.size,
                // 'file_description': form.name
            })
        );
        tmpPresign.value = presign;
        console.log("presign", presign);
        console.log("start upload", "ext:", file.value.type);

        uploadToS3(file, presign.url);

        console.log("endUpload");
        // form.preview = base64;
        // form.duration = duration;
        console.log("Start update storage data");
        // form.storageData = presign.storageData;
        form.attachments = [presign.storageData];
        form.message = {
            content: form.messageInput,
        };
        if (props.routeArgs) {
            await form.post(route(...props.routeArgs), {
                onSuccess: onFormSubmit,
            });
        } else {
            onFormSubmitEmpty();
        }
        console.log("End update storage data");
    } catch (error) {
        isLoading.value = false;
        console.log("submitForm error", error);
        toast.error("Помилка, спробуйте ще раз! " + error.message, {
            autoClose: 3000,
            position: toast.POSITION.BOTTOM_CENTER,
            multiple: false
        });
    }
};

const   uploadToS3 = async (file, presign) => {
    const formData = new FormData();
    formData.append("file", file.value);
    console.log(file.value.type);
    try {
        const response = await axios.put(presign, file.value, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": file.value.type,
                "Content-Disposition": "inline",
            },
            cancelToken: cancelTokenSource.token,
            // onUploadProgress: (progressEvent) => {
            //     fileUploadStore.setUploadProgress((progressEvent.loaded * 100) / progressEvent.total)
            // },
            withCredentials: false,
            mode: "no-cors",
        });

        if (response.status === 200) {
            console.log("File successfully uploaded to S3");
            return response.data;
        } else {
            console.error("Error uploading file to S3:", response.statusText);
            // fileUploadStore.setIsUpload(false);
            // isVisible.value = !isVisible.value;
            toast.error("Помилка, спробуйте ще раз!", {
                autoClose: 3000,
                position: toast.POSITION.BOTTOM_CENTER,
                multiple: false
            });
            return null;
        }
    } catch (error) {
        console.error("Error uploading file to S3:", error.message);
        return null;
    }
};

const getPresign = async (route) => {
    let response = await fetch(route);
    if (response.ok) {
        let json = await response.json();
        return json;
    } else {
        if (response.status === 403) {
            toast.error("Ліміт диска вичерпано", {
                autoClose: 3000,
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
        console.log("Ошибка HTTP: " + response.status, response);
        throw new Error(`Помилка HTTP: ${response.status}`);
    }
};

const onFormSubmit = (response) => {
    form.reset();
    file.value = null;
    fileInput.value = null;
    emit("upload-complete", response);
    isLoading.value = false;
    isVisible.value = !isVisible.value;
};

const onFormSubmitEmpty = () => {
    form.reset();
    file.value = null;
    fileInput.value = null;
    emit("upload-complete", tmpPresign.value);
    isLoading.value = false;
    isVisible.value = !isVisible.value;
};

//const allowedExtensions = ['.doc', '.docx', '.xml', '.pdf', '.png', '.jpg'];
const allowedExtensions = {
    file: [
        ".doc",
        ".docx",
        ".xml",
        ".pdf",
        ".txt",
        ".ppt",
        ".pptx",
        ".png",
        ".jpg",
        ".jpeg",
    ],
    image: [".png", ".jpg"],
};

const isLoading = ref(false);
const file = ref(null);
const fileInput = ref(null);

const isFile = computed(() => !!file.value);

const openFilePicker = () => {
    if (fileInput) {
        fileInput.value.click();
    }
};

const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
};

const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile) {
        if (props.allExceptVideo) {
            if (droppedFile.type.startsWith("video/")) {
                console.log("handleFileChange");
                file.value = null;
                return;
            } else {
                file.value = droppedFile;
            }
        } else if (isAllowedType(droppedFile)) file.value = droppedFile;
    }
};

const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("handleFileChange", event, selectedFile);
    if (selectedFile) {
        console.log("handleFileChange 2");
        if (props.allExceptVideo) {
            console.log("handleFileChange props.allExceptVideo");
            if (selectedFile.type.startsWith("video/")) {
                console.log("handleFileChange");
                file.value = null;
                return;
            } else {
                file.value = selectedFile;
            }
        } else if (isAllowedType(selectedFile)) {
            console.log("handleFileChange isAllowedType(selectedFile)");
            file.value = selectedFile;
        }
    }
};

const isAllowedType = (file) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    console.log(
        "fileExtension",
        fileExtension,
        allowedExtensions[props.fileType].includes(`.${fileExtension}`)
    );
    return allowedExtensions[props.fileType].includes(`.${fileExtension}`);
};

const formatFileType = (file) => {
    return file.name.slice(file.name.lastIndexOf(".")).toLowerCase();
};
</script>

<template>
    <b-modal
        v-model="isVisible"
        hide-footer
        hide-header
        centered
        :no-close-on-backdrop="isLoading"
    >
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>Додати файл</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <b-spinner
                v-if="isLoading"
                variant="primary"
                label="Spinning"
                class="wait-spinner"
            ></b-spinner>
            <div
                class="file-uploader"
                @dragover.prevent="handleDragOver"
                @drop.prevent="handleDrop"
                @click="openFilePicker"
            >
                <i class="p ph-paperclip-bold fs-1"></i>
                <div class="file-uploader__title">
                    {{
                        !isFile ? "Завантажте або перетягніть файл" : file.name
                    }}
                </div>
                <div v-if="allExceptVideo" class="file-uploader__subtitle">
                    {{ !isFile ? "" : `Тип файлу: ${formatFileType(file)}` }}
                </div>
                <div v-else class="file-uploader__subtitle">
                    {{
                        !isFile
                            ? allowedExtensions[fileType].join(", ")
                            : `Тип файлу: ${formatFileType(file)}`
                    }}
                </div>
                <input
                    ref="fileInput"
                    type="file"
                    style="display: none"
                    @change="handleFileChange"
                />
            </div>
            <InputGroup
                placeholder="Введіть повідомлення..."
                v-model="form.messageInput"
                size="lg"
                class=""
            />
            <div class="d-flex justify-content-end">
                <b-button
                    variant="primary"
                    size="md"
                    :disabled="!isFile && !isLoading"
                    pill
                    class="mt-3"
                    @click="handleSend"
                >
                    Відправити
                </b-button>
            </div>
        </div>
        <div v-if="isLoading" class="loading-backdrop"></div>
    </b-modal>
</template>
<style lang="scss" scoped>
.wait-spinner {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: calc(50% - 1.5rem);
    left: calc(50% - 1.5rem);
    z-index: 1000;
}

.file-uploader {
    max-width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    padding: 20px 50px;
    margin: 0 auto;

    .file-uploader__title,
    .file-uploader__subtitle,
    i {
        color: var(--dark, #1e1e1e);
        text-align: center;
        opacity: 0.4;
        font-style: normal;
    }

    .file-uploader__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 100%;
    }

    .file-uploader__subtitle {
        font-size: 12px;
        font-weight: 300;
        line-height: 140%;
    }
}

.loading-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.25;
    top: 0;
    left: 0;
    z-index: 999;
}
</style>
