<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, onUpdated, ref, watch} from "vue";
import InputGroup from "@/Components/InputGroup.vue";
import { helpers, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import noAvatar from "../../../images/tmp/ProductOwnerProfile/avatar.png";
import {useStudentsGroupStore} from "@/stores/studentsGroupStore.js";
import {usePage} from "@inertiajs/vue3";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import useRequestProgress from "@/composables/useRequestProgress.js";

const props = defineProps({
    modelValue: Boolean,
    students: Object,
    group: Object
});

const studentsGroupStore = useStudentsGroupStore();
const pageData = usePage();

const { isLoading } = useRequestProgress()

const tenanId = pageData?.props?.auth?.tenant?.id || false

const emit = defineEmits(["update:modelValue"]);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const form = ref({
    name: props.group.name
});

const groupStudentsSearchQuery = ref("")
const availableStudentsSearchQuery = ref("")

const isButtonVisible = ref(false);

watch(() => form.value.name, (newValue) => {
    isButtonVisible.value = newValue !== props.group.name;
});

const rules = {
    name: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
};

const v$ = useVuelidate(rules, form.value, { $lazy: true });

onMounted(() => {
    nextTick(() => {
        studentsGroupStore.getStudentsFromGroup({
            params:{
                group_id: props.group.id,
                tenant_id: tenanId,
            }
        });
        studentsGroupStore.getStudentsWithoutGroup({
            params:{
                group_id: props.group.id,
                tenant_id: tenanId,
            }
        });
    });
});

onBeforeUnmount(() => {
    studentsGroupStore.resetStudentsWithoutGroup()
    studentsGroupStore.resetStudentsFromGroup()
});

const handlePageChange = () => {
    nextTick(() => {
        studentsGroupStore.getStudentsFromGroup({
            params:{
                group_id: props.group.id,
                tenant_id: tenanId,
                page: studentsGroupStore.groupStudentsPaginationData.currentPage,
                perPage: studentsGroupStore.groupStudentsPaginationData.perPage
            }
        })
    });
}

const handleAvailableStudentsPageChange = () => {
    nextTick(() => {
        studentsGroupStore.getStudentsWithoutGroup({
            params:{
                group_id: props.group.id,
                tenant_id: tenanId,
                page: studentsGroupStore.availableStudentsPaginationData.currentPage,
                perPage: studentsGroupStore.availableStudentsPaginationData.perPage
            }
        })
    });
}

const currentPage = computed({
    get: () => studentsGroupStore.group.pagination.currentPage,
    set: (value) => studentsGroupStore.group.pagination.currentPage = value,
});

const currentAvailableStudentsPage = computed({
    get: () => studentsGroupStore.availableStudents.pagination.currentPage,
    set: (value) => studentsGroupStore.availableStudents.pagination.currentPage = value,
});

const handleSaveGroupName= () => {
    v$.value.$touch();
    if (!v$.value.$invalid) {
        studentsGroupStore.updateGroupName({
            id: props.group.id,
            name: form.value.name,
            tenant_id: tenanId
        })
        isButtonVisible.value = false
    }
};

const handleAddStudent = (id) => {
    studentsGroupStore.addStudentToGroup({
        tenant_id: tenanId,
        student_id: id,
        group_id: props.group.id,
    })
}

const handleRemoveStudent = (id) => {
    studentsGroupStore.removeStudentFromGroup({
        tenant_id: tenanId,
        student_id: id,
        group_id: props.group.id,
    })
}

const deleteStudentModal = ref(false)

let debounceTimerGroupStudents;
let debounceTimerAvailableStudents;
const debounceDelay = 300;

const handleFilterGroupStudents = () => {
    clearTimeout(debounceTimerGroupStudents);
    debounceTimerGroupStudents = setTimeout(() => {
        if(groupStudentsSearchQuery.value.length > 2 || groupStudentsSearchQuery.value === "") {
            studentsGroupStore.getStudentsFromGroup({
                params:{
                    group_id: props.group.id,
                    tenant_id: tenanId,
                    searchQuery: groupStudentsSearchQuery.value
                }
            })
        }
    }, debounceDelay);
}

const handleFilterAvailableStudents = () => {
    clearTimeout(debounceTimerAvailableStudents);
    debounceTimerAvailableStudents = setTimeout(() => {
        if(availableStudentsSearchQuery.value.length > 2 || availableStudentsSearchQuery.value === "") {
            studentsGroupStore.getStudentsWithoutGroup({
                params:{
                    tenant_id: tenanId,
                    searchQuery: availableStudentsSearchQuery.value
                }
            })
        }
    }, debounceDelay);
}

const studetnsCount = computed(() => studentsGroupStore.groupStudentsList?.length || 0)

</script>

<template>
    <b-modal
        v-model="isVisible"
        v-if="isVisible"
        hide-footer
        hide-header
        centered
        no-close-on-esc
        no-close-on-backdrop
        class="add-student-group__modal"
    >
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>{{ $t('t-group-edit') }}</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <InputGroup
                :label="$t('t-group-name')"
                v-model="form.name"
                size="lg"
                class="modal__input-group"
                :save-btn="isButtonVisible"
                :is-error="v$.name.$invalid"
                :error-message="
                    v$.name?.$errors.find(
                        (error) => error['$validator'] === 'required'
                    )?.$message
                "
                @blur="v$.name.$touch()"
                @save-btn-click="handleSaveGroupName"
            />
            <b-tabs class="modal-tabs-component">
                <b-tab :title="$t('t-group-student-count', { count: studetnsCount })" active>
                    <InputGroup
                        :label="$t('t-search')"
                        v-model="groupStudentsSearchQuery"
                        size="lg"
                        class="modal__student-filter-input"
                        @input="handleFilterGroupStudents"
                    />
                    <perfect-scrollbar :style="{ minHeight: '300px', maxHeight: '300px' }" class="mb-3">
                        <div v-if="studentsGroupStore.groupStudentsList.length === 0" class="modal__empty-list">
                            {{ isLoading ? "" : 'В цій групі ще немає студентів, будь ласка додайте студентів з вкладки "Інші студенти"'}}
                        </div>
                        <div
                            v-else
                            v-for="student of studentsGroupStore.groupStudentsList"
                            :key="student.id"
                            class="student-item"
                        >
                            <b-avatar
                                variant="light"
                                :src="student.imageUrl ? student.imageUrl : noAvatar"
                                class="student-item__avatar"
                            />
                            <div class="student-item__info">
                                <div class="student-item__info--name">{{student.name}}</div>
                                <div class="student-item__info--email">{{student.email}}</div>
                            </div>
                            <div class="student-item__action">
                                <b-button
                                    variant="link"
                                    size="md"
                                    class="in-group"
                                    @click="deleteStudentModal = true"
                                >
                                    {{ $t('t-remove') }}
                                </b-button>
                                <ConfirmModal
                                    v-if="deleteStudentModal"
                                    v-model="deleteStudentModal"
                                    title="Видалити студента з групи"
                                    decline-label="Не видаляти"
                                    accept-label="Видалити"
                                    @accept-btn="() => handleRemoveStudent(student.id)"
                                    @decline-btn="() => console.log('decline')"
                                >
                                    <template v-slot:description>
                                        Після видалення студента з групи, він може втратити свій прогрес. Бажаєте видалити студента з групи?
                                    </template>
                                </ConfirmModal>
                            </div>
                        </div>
                    </perfect-scrollbar>
                    <b-pagination
                        v-if="studentsGroupStore.isGroupStudentsPagination"
                        class="modal__pagination"
                        v-model="currentPage"
                        :total-rows="studentsGroupStore.groupStudentsPaginationData.totalItems"
                        :per-page="studentsGroupStore.groupStudentsPaginationData.perPage"
                        aria-controls="my-table"
                        @page-click="handlePageChange"
                    ></b-pagination>
                </b-tab>
                <b-tab :title="$t('t-other-students')">
                    <InputGroup
                        :label="$t('t-search')"
                        v-model="availableStudentsSearchQuery"
                        size="lg"
                        class="modal__student-filter-input"
                        @input="handleFilterAvailableStudents"
                    />
                    <perfect-scrollbar :style="{ minHeight: '300px', maxHeight: '300px' }" class="mb-3">
                        <div
                            v-for="student of studentsGroupStore.availableStudentsList"
                            :key="student.id"
                            class="student-item"
                        >
                            <b-avatar
                                variant="light"
                                :src="student.imageUrl ? student.imageUrl : noAvatar"
                                class="student-item__avatar"
                            />
                            <div class="student-item__info">
                                <div class="student-item__info--name">{{student.name}}</div>
                                <div class="student-item__info--email">{{student.email}}</div>
                            </div>
                            <div class="student-item__action">
                                <b-button
                                    pill
                                    size="md"
                                    class="outline-dark"
                                    @click="handleAddStudent(student.id)"
                                >
                                    {{ $t('t-add') }} <i class="ph-plus"></i>
                                </b-button>
                            </div>
                        </div>
                    </perfect-scrollbar>
                    <b-pagination
                        v-if="studentsGroupStore.isAvailableStudentsPagination"
                        class="modal__pagination"
                        v-model="currentAvailableStudentsPage"
                        :total-rows="studentsGroupStore.availableStudentsPaginationData.totalItems"
                        :per-page="studentsGroupStore.availableStudentsPaginationData.perPage"
                        aria-controls="my-table"
                        @page-click="handleAvailableStudentsPageChange"
                    ></b-pagination>
                </b-tab>
            </b-tabs>
        </div>
    </b-modal>
</template>
<style lang="scss">
.add-student-group__modal {
    .modal-dialog {
        max-width: 640px;
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .group-counter {
            font-family: e-Ukraine;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            color: #1E1E1E;
            padding: 16px 0;
        }
        .student-item {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            width: 100%;
            padding: 12px 12px 12px 0;
            .student-item__info {
                flex: 1;
                display: grid;
                .student-item__info--name {
                    font-family: e-Ukraine;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 16px;
                    color: #1E1E1E;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .student-item__info--email {
                    font-size: 10px;
                    font-weight: 300;
                    line-height: 12px;
                    color: #C2C2C2;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .modal__empty-list {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: e-Ukraine;
            font-size: 13px;
            color: #C2C2C2;
            text-align: center;
            padding: 20px;
        }
    }
    .ps__thumb-y {
        background-color: #1db954!important;
    }
}
</style>
