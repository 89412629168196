<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import { Link, router, usePage } from "@inertiajs/vue3";
import { useI18n } from "vue-i18n";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import CourseModuleSelector from "@/Components/form/CourseModuleSelector.vue";
import { useRoleChecker } from "@/composables/useRoleChecker.js";
import { useCourseGroupStore } from "@/stores/courseGroupStore.js";
import useRequestProgress from "@/composables/useRequestProgress.js";
import noAvatar from "../../../../images/tmp/ProductOwnerProfile/avatar.png";
import PerPageSelector from "@/Components/form/PerPageSelector.vue";
import FilterSelector from "@/Components/form/FilterSelector.vue";
import CourseGroupDropdown from "@/Components/buttons/CourseGroupDropdown.vue";
import EditCourseGroupModal from "@/Pages/Courses/Partials/group/EditCourseGroupModal.vue";

const props = defineProps({
  statuses: Object,
  team: Object,
  course: Object,
  form: Object,
  certificates_type: Object,
  users: Object,
  students: Object,
});

const { t } = useI18n();

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const workshopRoles = props.form?.course?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles, workshopRoles, "CURATOR");

const checkAccess = (userId, moduleId) => {
  console.log(userId, moduleId);
  let rez = false;
  for (const access in props.users.access) {
    if (userId === access.user_id && moduleId === access.module_id) {
      rez = true;
      console.log(rez);
      return rez;
    }
  }
  return rez;
};

function handleCheckboxChange(data) {
  const userId = data.user_id;
  const moduleId = data.module_id;
  router.visit(route("courses.grant-module-access"), {
    method: "post",
    data: {
      userId: userId,
      moduleId: moduleId,
      status: event.target.checked,
    },
    preserveState: true,
    preserveScroll: true,
    forceFormData: true,
    onSuccess: (response) => {
      // props.form.course.contentItems = response.props.course.contentItems;
      // router.visit(router.page.url);
      courseGroupStore.getStudentsFromCourse({
        course_id: props.form.course.id,
      });
    },
  });
}

const grantAccess = (courseUserId) => {
  console.log("courseUserId:", courseUserId);
  router.visit(route("courses.grant-access"), {
    method: "post",
    data: { courseUserId: courseUserId },
    preserveState: true,
    preserveScroll: true,
    forceFormData: true,
    onSuccess: (response) => {
      courseGroupStore.getStudentsFromCourse({
        course_id: props.form.course.id,
      });
    },
  });
};

const addUserToCourse = (courseUserId) => {
  console.log("courseUserId:", courseUserId);
  router.visit(route("courses.add-user"), {
    method: "post",
    data: { courseUserId: courseUserId, courseId: props.form.course.id },
    preserveState: true,
    preserveScroll: true,
    forceFormData: true,
    onSuccess: (response) => {
      // props.form.course.contentItems = response.props.course.contentItems;
      // router.visit(router.page.url);
      courseGroupStore.getStudentsFromCourse({
        course_id: props.form.course.id,
      });
    },
  });
};

const addStudent = ref({});

function removeUser(userId) {
  router.visit(route("courses.remove-user"), {
    method: "post",
    data: { id: userId },
    preserveState: true,
    preserveScroll: true,
    forceFormData: true,
    onSuccess: (response) => {
      // props.form.course.contentItems = response.props.course.contentItems;
      // router.visit(router.page.url);
      courseGroupStore.getStudentsFromCourse({
        course_id: props.form.course.id,
      });
    },
  });
}

const groupStudents = ref(false);
const deleteCourseUser = ref(false);

const courseGroupStore = useCourseGroupStore();

onMounted(() => {
  nextTick(() => {
    // courseGroupStore.getGroupsList({
    //     params:{
    //         course_id: props.form.course.id
    //     }
    // })
    courseGroupStore.getStudentsFromCourse({
      course_id: props.form.course.id,
    });
  });
});

const handlePerPageChange = (amount) => {
  courseGroupStore.resetStudentsPagination();
  courseGroupStore.setStudentsPerPage(amount);
  courseGroupStore.getStudentsFromCourse({
    course_id: props.form.course.id,
  });
};

const handleGroupFilterChange = (value) => {
  courseGroupStore.setStudentsFilterByGroup(value);
  courseGroupStore.resetStudentsPagination();
  courseGroupStore.getStudentsFromCourse({
    course_id: props.form.course.id,
  });
};

const handleSortingChange = (value) => {
  courseGroupStore.toggleSort(value);
  courseGroupStore.getStudentsFromCourse({
    course_id: props.form.course.id,
  });
};

const currentPage = computed({
  get: () => courseGroupStore.studentsPagination.currentPage,
  set: (value) => (courseGroupStore.studentsPagination.currentPage = value),
});

const handlePageChange = () => {
  nextTick(() => {
    courseGroupStore.getStudentsFromCourse({
      course_id: props.form.course.id,
    });
  });
};

const tmpGroup = ref(null);
const editCourseGroupModal = ref(false);

const handleEditGroup = (group) => {
  tmpGroup.value = group;
  editCourseGroupModal.value = true;
};
</script>

<template>
  <b-container fluid class="tab-form">
    <b-form>
      <div class="module-table course-users-list">
        <div class="list-filters__wrapper">
          <div class="list-filters__container">
            <div class="list-filters__item">
              <div class="text-uppercase opacity-50">Показувати</div>
              <PerPageSelector
                :current-value="courseGroupStore.studentsPerPage"
                @choose-amount="handlePerPageChange"
              />
            </div>
            <!--                        <div class="list-filters__item">-->
            <!--                            <div class="text-uppercase opacity-50">-->
            <!--                                показувати групу-->
            <!--                            </div>-->
            <!--                            <FilterSelector :current-value="courseGroupStore.studentsFilterByGroupValue" :filter-list="courseGroupStore.courseGroupList" @choose-filter="handleGroupFilterChange" />-->
            <!--                        </div>-->
          </div>
          <div class="list-filters__actions">
            <!--                        <CourseGroupDropdown :course-id="form.course.id" @edit-group="handleEditGroup" />-->
            <b-button
              v-if="students"
              variant="primary"
              pill
              v-b-modal.grantAccessModal1
              :disabled="isReadOnly"
            >
              <!-- && Object.keys(students).length > 0 -->
              Додати студента
            </b-button>
            <b-modal
              id="grantAccessModal1"
              hide-footer
              hide-header
              centered
              v-if="students"
            >
              <!-- && Object.keys(students).length > 0 -->
              <div class="modal-body">
                <div class="grand-modal-title">
                  <i class="ph-warning"></i>
                  <h4>Підтвердіть надання доступу</h4>
                </div>
                <p class="grand-modal-desc">
                  Якщо ви надасте студенту доступ до курсу, його вже не можна
                  буде скасувати.
                </p>
                <b-form-select
                  v-if="students && Object.keys(students).length > 0"
                  v-model="addStudent"
                  :placeholder="'Обрати студента'"
                  :options="students"
                  size="xl"
                  ref="input"
                  v-uid
                />
                <p v-else class="empty-student-list">
                  Список студентів поки що порожній.
                </p>
                <div class="grand-modal-actions">
                  <b-button
                    class="outline-dark"
                    pill
                    v-b-modal.grantAccessModal1
                  >
                    Скасувати
                  </b-button>
                  <b-button
                    @click="addUserToCourse(addStudent)"
                    :disabled="!(students && Object.keys(students).length > 0)"
                    class="grand-modal-accept m-2"
                    pill
                    v-b-modal.grantAccessModal1
                  >
                    Надати доступ
                  </b-button>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
        <div
          v-if="!courseGroupStore.isStudentsListEmpty"
          class="module-table__header border-bottom pb-2"
        >
          <div class="module-table__col module-table__col--user text-uppercase">
            <div
              class="table-column__sorting"
              :class="courseGroupStore.getStudentsSortingByName('user')"
              @click="handleSortingChange('user')"
            >
              користувач
              <div class="table-column__sorting--icon">
                <i class="ph-caret-up-fill"></i>
                <i class="ph-caret-down-fill"></i>
              </div>
            </div>
          </div>
          <div
            class="module-table__col module-table__col--phone text-uppercase opacity-50"
          >
            телефон
          </div>
          <!--          <div class="module-table__col module-table__col&#45;&#45;payment text-uppercase opacity-50">-->
          <!--            оплата-->
          <!--          </div>-->
          <div
            class="module-table__col module-table__col--modules text-uppercase opacity-50"
          >
            Доступ до модулів
            <div class="d-flex mt-1">
              <!--              <template v-for="module of form.course.contentItems">-->
              <!--                <span class="me-1" v-if="module.module_type === 'MULTI_LESSON'">{{ module.title }}</span>-->
              <!--                <span class="me-1" v-if="module.module_type === 'SINGLE_LESSON'">{{ module.lessons[0].title }}</span>-->
              <!--                <b-form-checkbox v-model="moduleChecked1" value="true" unchecked-value="false"></b-form-checkbox>-->
              <!--              </template>-->
            </div>
          </div>
          <!--                    <div-->
          <!--                        class="module-table__col module-table__col&#45;&#45;group text-uppercase opacity-50"-->
          <!--                    >-->
          <!--                        Група-->
          <!--                    </div>-->
          <div
            class="module-table__col module-table__col--actions text-uppercase opacity-50"
          >
            Дії
          </div>
        </div>
        <div
          v-for="user of courseGroupStore.courseStudentsList"
          :key="user.id"
          class="module-table__row border-bottom"
        >
          <div class="module-table__col module-table__col--user">
            <b-avatar
              variant="light"
              :src="user.imageUrl ? user.imageUrl : noAvatar"
              class="user-logo"
            ></b-avatar>
            <div class="d-flex flex-column justify-content-center">
              <span class="user-name">{{ user.name }}</span>
              <span class="user-email">{{ user.email }}</span>
            </div>
          </div>
          <div class="module-table__col module-table__col--phone">
            {{ user.phone }}
          </div>
          <!--          <div class="module-table__col module-table__col&#45;&#45;payment">-->
          <!--            <a v-if="user.payment_check_name" :href="user.payment_check_download">{{ user.payment_check_name }}<i class="ph-arrow-down-bold"></i></a>-->
          <!--          </div>-->
          <div class="module-table__col module-table__col--modules d-flex">
            <CourseModuleSelector
              :modules="form.course.contentItems"
              :user="user"
              :disabled="isReadOnly"
              @save-modules="handleCheckboxChange"
            />
          </div>
          <!--                    <div-->
          <!--                        class="module-table__col module-table__col&#45;&#45;group"-->
          <!--                    >-->
          <!--                        <div v-if="user.group">-->
          <!--                            {{user.group.name}}-->
          <!--                        </div>-->
          <!--                        <div v-else class="not-assigned">-->
          <!--                            {{$t('t-not-assigned')}}-->
          <!--                        </div>-->
          <!--                    </div>-->
          <div class="module-table__col module-table__col--actions">
            <div class="dropdown flex-shrink-0">
              <b-button
                :disabled="isReadOnly"
                variant="link"
                size="sm"
                class="btn-icon dropdown-menu__button"
                role="button"
                data-bs-toggle="dropdown"
                pill
                aria-expanded="false"
              >
                <i class="bi bi-three-dots-vertical"></i>
              </b-button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li v-if="user.status === 'USER_NEW'">
                  <span class="dropdown-item" v-b-modal.grantAccessModal>
                    Надати доступ
                  </span>
                </li>
                <li>
                  <!-- TODO: додати перевірку на те що юзер в групі і не давати видаляти  -->
                  <button
                    class="dropdown-item"
                    :disabled="user.group"
                    @click="deleteCourseUser = true"
                  >
                    <i class="bi bi-trash3 align-baseline me-1"></i>
                    Видалити
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <ConfirmModal
            v-model="deleteCourseUser"
            title="Видалення студента"
            decline-label="Скасувати"
            accept-label="Так, видалити"
            @accept-btn="() => removeUser(user.id)"
            @decline-btn="() => console.log('decline')"
          >
            <template v-slot:description>
              Ви впевнені, що хочете видалити цього студента?
            </template>
          </ConfirmModal>
          <b-modal id="grantAccessModal" hide-footer hide-header centered>
            <div class="modal-body">
              <div class="grand-modal-title">
                <i class="ph-warning"></i>
                <h4>Підтвердіть надання доступу</h4>
              </div>
              <p class="grand-modal-desc">
                Якщо ви надасте студенту доступ до курсу, його вже не можна буде
                скасувати.
              </p>
              <div class="grand-modal-actions">
                <b-button class="outline-dark" pill v-b-modal.grantAccessModal>
                  Скасувати
                </b-button>
                <b-button
                  @click="grantAccess(user.user_id)"
                  class="grand-modal-accept m-2"
                  pill
                  v-b-modal.grantAccessModal
                >
                  Надати доступ
                </b-button>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
      <b-pagination
        v-if="courseGroupStore.isStudentsPagination"
        class="mt-4"
        v-model="currentPage"
        :total-rows="courseGroupStore.studentsPaginationData.totalItems"
        :per-page="courseGroupStore.studentsPaginationData.perPage"
        aria-controls="my-table"
        first-number
        last-number
        @page-click="handlePageChange"
      >
        <template #prev-text><i class="ph-caret-left-bold"></i></template>
        <template #next-text><i class="ph-caret-right-bold"></i></template>
      </b-pagination>
    </b-form>
    <EditCourseGroupModal
      v-if="editCourseGroupModal && tmpGroup"
      v-model="editCourseGroupModal"
      :group="tmpGroup"
      :course="form.course"
      :team="team"
      @close-modal="tmpGroup = null"
    />
  </b-container>
</template>

<style lang="scss">
.grand-modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.grand-modal-title {
  color: #da7f14;
  display: flex;
  flex-direction: row;

  i {
    font-size: xxx-large;
  }

  h4 {
    color: #da7f14;
  }
}

.grand-modal-desc {
  margin-left: 50px;
  color: dimgray;
}

.grand-modal-accept {
  background-color: var(--green, #1db954);
  border: none;
}

.grand-modal-accept:hover {
  background-color: var(--green, #1db954);
  opacity: 0.8;
}

.grand-modal-accept:hover {
  background-color: var(--green, #1db954);
  opacity: 0.8;
}

.course-users-list {
  .module-table__row {
    padding: 12px 0;
  }
  .list-filters__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 12px 8px 12px 8px;
    border-radius: 8px;
    border: 1px solid var(--stroke-default-100, #e7e7e7);
    background: var(--background-default-50, #f6f6f6);
    margin-bottom: var(--tb-card-spacer-x);
    .list-filters__container,
    .list-filters__actions {
      display: flex;
      gap: 12px;
    }
  }
  .module-table__col--user {
    display: flex;
    width: 30%;
    gap: 8px;
    .user-name {
      font-size: 13px;
      font-weight: 400;
      line-height: 18.2px;
      color: #1e1e1e;
    }
    .user-email {
      font-size: 12px;
      font-weight: 300;
      line-height: 12px;
      color: var(--text-default-400, #888888);
    }
  }

  .module-table__col--phone {
    width: 10%;
  }
  .module-table__col--payment {
    width: 10%;

    i {
      color: green;
    }
  }

  .module-table__col--modules {
    flex: 1;
  }
  .module-table__col {
    display: flex;
  }
  .module-table__col--group {
    width: 10%;
    .not-assigned {
      color: #c2c2c2;
    }
    .form-check {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 0;
      .form-check-input {
        margin: 0;
      }
    }
    .group-grouping__dropdown {
      border: 1px solid #c2c2c2;
      border-radius: 4px !important;
      padding: 4px 12px;
      text-transform: uppercase;
      font-family: e-Ukraine;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: color-mix(in srgb, var(--dark, #1e1e1e), transparent 50%);
      &:hover {
        border-color: #1db954;
      }
      &.show {
        border-color: #1db954;
        .ph-caret-down-fill {
          transform: rotate(180deg);
        }
      }
    }
  }

  .module-table__col--actions {
    width: 50px !important;
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    .btn {
      margin: 0;
      padding: 0;
    }

    //i {
    //    margin: 0 10px;
    //    font-size: large;
    //    color: #C2C2C2;
    //}

    //button {
    //    background-color: var(--green, #1DB954);
    //}
  }
  @media only screen and (max-width: 1368px) {
    .module-table__col--user {
      width: 30%;
    }
    .module-table__col--phone {
      width: 15%;
    }
    .module-table__col--modules {
      width: 20% !important;
      flex-wrap: wrap;
    }
  }
}

.webinar-input-group {
  border-radius: 4px;
  border: 1px solid #1db954;
  background-color: #1db9540a;
  overflow: hidden;
  i {
    font-size: medium;
  }

  .webinar-form-input {
    background: transparent;
    border: none;
  }
}

.tab-form {
}

.image-uploader {
  max-width: 420px;
  height: 240px;
  border-radius: 20px;
  border: 2px dashed var(--stroke, #ebebeb);
  background: var(--white, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.image-uploader__description {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.image-uploader__title {
  color: var(--dark, #1e1e1e);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
  color: var(--dark, #1e1e1e);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 16.8px */
  opacity: 0.4;
}

#input-1 {
  background-color: #1db9540a;
  border: 1px solid #1db954;
  border-radius: 4px;
}
.empty-student-list {
  font-family: e-Ukraine;
  color: var(--dark, #1e1e1e);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin: 20px 0;
}
</style>
