<script setup>
import { usePage } from "@inertiajs/vue3";
import { ref, onMounted, computed } from "vue";
import useRequestProgress from "@/composables/useRequestProgress.js";
import axios from "axios";
import { toast } from "vue3-toastify";
import { useI18n } from "vue-i18n";

const isVisible = ref(true);
const questionIndex = ref(0);
const page = usePage();
const { t } = useI18n();

const { isLoading: isRequestProgressLoading, setIsLoading } =
  useRequestProgress();

const questions = page.props.auth.questions;
const answers = ref({});

const customAnswers = ref({});

onMounted(() => {
  questions.forEach((item) => {
    if (Object.values(item.answers).includes("CUSTOM_USER_ANSWER")) {
      customAnswers.value[item.id] = "";
    }
  });
});

const nextQuestion = () => {
  if (questionIndex.value < questions.length - 1) {
    questionIndex.value++;
  }
};

const prevQuestion = () => {
  if (questionIndex.value > 0) {
    questionIndex.value--;
  }
};

const handleSendTenantAnswer = async () => {
  try {
    setIsLoading(true);

    const check = Object.values(answers.value).length === questions.length;

    if (!check) {
      setIsLoading(false);

      toast.error("Дайте відповідь на усі питання!", {
        autoClose: 2000,
        position: toast.POSITION.BOTTOM_CENTER,
        multiple: false,
      });

      return;
    }

    const res = await axios.post(
      "/api/module/tenantSurvey/sendTenantSurveyAnswers",
      {
        user_id: page.props.auth?.tenant?.id,
        answers: answers.value,
      }
    );

    isVisible.value = false;

    setIsLoading(false);

    toast.success(t("request-success"), {
      autoClose: 2000,
      position: toast.POSITION.BOTTOM_CENTER,
      multiple: false,
    });
  } catch (error) {
    setIsLoading(false);

    toast.error("Сталася помилка!", {
      autoClose: 2000,
      position: toast.POSITION.BOTTOM_CENTER,
      multiple: false,
    });
  }
};
</script>

<template>
  <b-modal
    v-model="isVisible"
    hide-footer
    hide-header
    centered
    :no-close-on-backdrop="true"
  >
    <div class="modal-body">
      <h4 class="pb-2" v-if="questions[questionIndex]">
        {{ questionIndex + 1 }}/{{ questions.length }}
        {{ questions[questionIndex].question }}
      </h4>
      <b-form-group v-if="questions[questionIndex]">
        <template
          v-for="(option, index) in questions[questionIndex].answers"
          :key="index"
        >
          <div class="custom-answer" v-if="option === 'CUSTOM_USER_ANSWER'">
            <b-form-radio
              v-model="answers[questionIndex]"
              name="some-radios"
              :value="{
                id: questions[questionIndex].id,
                answer: customAnswers[questions[questionIndex].id],
              }"
              :disabled="!customAnswers[questions[questionIndex].id]?.trim()"
            >
              <input
                v-model="customAnswers[questions[questionIndex].id]"
                @input="
                  () => {
                    if (!customAnswers[questions[questionIndex].id]?.trim()) {
                      delete answers[questionIndex];
                    } else {
                      answers[questionIndex] = {
                        id: questions[questionIndex].id,
                        answer: customAnswers[questions[questionIndex].id],
                      };
                    }
                  }
                "
                class="custom-answer__input"
                placeholder="Інший варіант (вказати)"
                type="text"
              />
            </b-form-radio>
          </div>
          <div v-else>
            <b-form-radio
              v-model="answers[questionIndex]"
              name="some-radios"
              :value="{ id: questions[questionIndex].id, answer: option }"
            >
              <p>
                {{ option }}
              </p>
            </b-form-radio>
          </div>
        </template>
      </b-form-group>

      <div class="d-flex justify-content-between mt-3">
        <button
          v-if="questionIndex > 0"
          @click="prevQuestion"
          class="btn btn-secondary"
        >
          Назад
        </button>

        <button
          @click="
            questionIndex < questions.length - 1
              ? nextQuestion()
              : handleSendTenantAnswer()
          "
          class="btn btn-primary ms-auto"
        >
          {{ questionIndex < questions.length - 1 ? "Далі" : "Відправити" }}
        </button>
      </div>
      <div>
        <ul class="steps d-flex justify-content-center gap-1">
          <li
            v-for="(question, index) in questions"
            :key="index"
            :class="{
              'active-step': index === questionIndex,
            }"
          ></li>
        </ul>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
.modal-body {
  text-align: center;
}
ul {
  list-style: none;
  padding: 0;
}

.steps {
  li {
    border-radius: 100%;
    width: 10px;
    height: 10px;
    background-color: #f24822;
  }
  .active-step {
    background: #1db954;
  }
}

.custom-answer__input {
  font-family: e-Ukraine;
  border-bottom: 1px solid #ccc;
  width: 100%;
  width: 100%;
  box-sizing: border-box;
  font-size: 15px;
  padding-bottom: 6px;
  outline: none;
  margin-bottom: 16px;
  color: #1e1e1e;
}
</style>

<style lang="scss">
.custom-answer {
  .form-check-label {
    width: 100%;
  }
}
</style>
