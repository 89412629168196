<script setup>
import {Head, router, useForm, usePage} from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";

import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import {computed, nextTick, onMounted, ref, triggerRef, watch} from "vue";
import DownloadXlsxButton from "@/Components/buttons/DownloadXlsxButton.vue";
import AddStudentInvaiteModal from "@/Components/modals/AddStudentInvaiteModal.vue";
import DropdownButtons from "@/Components/buttons/DropdownButtons.vue";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import InputModal from "@/Components/modals/InputModal.vue";
import InputGroup from "@/Components/InputGroup.vue";
import noAvatar from "../../../images/tmp/ProductOwnerProfile/avatar.png";
import AddStudentGroupModal from "@/Components/modals/AddStudentGroupModal.vue";
import GroupItem from "@/Pages/Students/Partials/GroupItem.vue";
import EditStudentGroupModal from "@/Components/modals/EditStudentGroupModal.vue";
import {useStudentsGroupStore} from "@/stores/studentsGroupStore.js";
import GroupsList from "@/Pages/Students/Partials/GroupsList.vue";

const props = defineProps({
    students: Object,
    isLimit: false
});

const { t } = useI18n();

const studentsGroupStore = useStudentsGroupStore();
const pageData = usePage();

const tenanId = pageData?.props?.auth?.tenant?.id || false

const page = {
    title: t("students"),
};

const form = useForm({
    students: props.students,
});

watch(
    () => form.students,
    (value) => {
        console.log("change edit");
    },
    { deep: true }
);

const selectedUsers = ref([]);

const handleUserSelect = (id) => {
    const index = selectedUsers.value.findIndex((el) => el === id);
    if (index >= 0) selectedUsers.value.splice(index, 1);
    else selectedUsers.value.push(id);
    triggerRef(selectedUsers);
};

const downloadUsersXlsx = () => {
    console.log("handle download users: ", selectedUsers.value);
};

const addUserModal = ref(false);
const invaiteStudent = () => {
    addUserModal.value = !addUserModal.value;
    form.reset();
};

const handleAddUser = (user) => {
    console.log("Add student: ");

    router.visit(route("student.studentInvite"), {
        method: "post",
        data: { data: user },
        preserveState: false,
        preserveScroll: false,
        forceFormData: true,
    });
};

function removeUser(user) {
    router.visit(route("student.removeStudentFromTenant"), {
        method: "post",
        data: { id: user.pivot_id, email: user.email },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {},
        onFinish: () => (tempStudent.value = null),
    });
}

function editStudent() {
    router.visit(
        route("student.studentComment", { id: tempStudent.value.pivot_id }),
        {
            method: "post",
            data: { comment: tempStudent.value.comment },
            preserveState: true,
            preserveScroll: true,
            forceFormData: true,
            onSuccess: (response) => {
                // props.form.course.contentItems = response.props.course.contentItems;
                // router.visit(router.page.url);
            },
            onFinish: () => {
                tempStudent.value = null;
                editStudentModal.value = false;
            },
        }
    );
}

const removeGroup = () => {
    studentsGroupStore.deleteGroup({
        tenant_id: tenanId,
        group_id: tmpGroup.value.id,
    })
    tmpGroup.value = null;
}
const editGroup = () => {
    console.log('Викликати апі на збереження групи');
    tmpGroup.value = null;
}

const statusStyleIcon = {
    INVITE: "bi bi-three-dots student-status__icon student-status__icon--yellow",
    REGISTERED:
        "bi bi-r-circle-fill student-status__icon student-status__icon--green",
    ACCEPTED:
        "bi bi-check-circle-fill student-status__icon student-status__icon--green",
    UNKNOWN:
        "bi bi-question-circle-fill student-status__icon student-status__icon--red",
};

const actions = [
    {
        id: 1,
        label: "Редагувати",
        link: "",
        icon: "bi bi-pencil-square align-baseline me-1",
    },
    {
        id: 2,
        label: "Видалити",
        link: "",
        icon: "bi bi-trash3 align-baseline me-1",
    },
];

function handleActionClick(user, id) {
    tempStudent.value = user;
    if (id === 2) {
        removeStudentModal.value = true;
    }
    if (id === 1) {
        editStudentModal.value = true;
        //editStudent(user.pivot_id)
    }
}

const editStudentModal = ref(false);
const removeStudentModal = ref(false);
const tempStudent = ref(null);

const addStudentGroupModal = ref(false);

const deleteGroupModal = ref(false)
const editGroupModal = ref(false)

const tmpGroup = ref(null)

onMounted(() => {
    nextTick(() => {
        studentsGroupStore.getGroupsList({
            params:{
                tenant_id: tenanId,
            }
        })
    });
})

const currentPage = computed({
    get: () => studentsGroupStore.groupPagination.currentPage,
    set: (value) => studentsGroupStore.groupPagination.currentPage = value,
});

const handlePageChange = () => {
    nextTick(() => {
        studentsGroupStore.getGroupsList({
            params:{
                tenant_id: tenanId,
                page: studentsGroupStore.groupPaginationData.currentPage,
                perPage: studentsGroupStore.groupPaginationData.perPage
            }
        })
    });
}

</script>

<template>
    <div>
        <Head :title="page.title" />

        <PageHeader :title="page.title" />
        <PageContentCard :title="page.title">
            <template #header-end>
                <div class="header-end">

                    <!--          <DownloadXlsxButton @click="downloadUsersXlsx"/>-->
                </div>
            </template>

            <template #default>
                <b-tabs nav-class="page-tabs">
                    <b-tab :title="$t('t-students')" active>
                        <b-container fluid class="tab-form">
                            <b-form>
                                <div
                                    v-if="!form.students || form.students.length > 0"
                                    class="module-table team__container"
                                >
                                    <div
                                        class="module-table__header border-bottom pb-2"
                                    >
                                        <div
                                            class="module-table__col module-table__col--user text-uppercase opacity-50"
                                        >
                                            Студент
                                        </div>
                                        <div
                                            class="module-table__col module-table__col--phone text-uppercase opacity-50"
                                        >
                                            телефон
                                        </div>
                                        <!--                                <div class="module-table__col module-table__col&#45;&#45;other text-uppercase opacity-50">-->
                                        <!--                                    Навики-->
                                        <!--                                </div>-->
                                        <div
                                            class="module-table__col module-table__col--other text-uppercase opacity-50"
                                        >
                                            Додаткова інформація
                                        </div>
                                        <div
                                            class="module-table__col module-table__col--status text-uppercase opacity-50"
                                        >
                                            Статус
                                        </div>
                                        <div
                                            class="module-table__col module-table__col--group text-uppercase opacity-50"
                                        >
                                            {{$t('t-group')}}
                                        </div>
                                        <div
                                            class="module-table__col module-table__col--actions text-uppercase opacity-50"
                                        >
                                            Дії
                                        </div>
                                    </div>
                                    <!--              <b-form-checkbox-group-->
                                    <!--                  v-model="selectedUsers"-->
                                    <!--              >-->
                                    <div
                                        v-for="user of form.students"
                                        :key="user.id"
                                        class="module-table__row border-bottom py-3"
                                    >
                                        <div
                                            class="module-table__col module-table__col--user team__user"
                                            @click.prevent="handleActionClick(user, 1)"
                                        >
                                            <!--                    <b-form-checkbox :value="user.id" class="m-1"></b-form-checkbox>-->
                                            <b-avatar
                                                variant="light"
                                                :src="user.imageUrl ? user.imageUrl : noAvatar"
                                                class="team__user--logo"
                                            ></b-avatar>
                                            <div
                                                class="d-flex flex-column justify-content-center"
                                            >
                                                <span class="team__user--name">{{ user.name }}</span>
                                                <span class="team__user--email">{{ user.email }}</span>
                                            </div>
                                        </div>
                                        <div
                                            class="module-table__col module-table__col--phone team__user--phone"
                                            @click.prevent="handleActionClick(user, 1)"
                                        >
                                            {{ user.phone }}
                                        </div>
                                        <!--                                <div class="module-table__col module-table__col&#45;&#45;other d-flex">-->
                                        <!--                                    {{ user.skill }}-->
                                        <!--                                </div>-->
                                        <div
                                            class="module-table__col module-table__col--other d-flex"
                                            @click.prevent="handleActionClick(user, 1)"
                                        >
                                            <div class="other-text__container">
                                                {{ user.comment }}
                                            </div>
                                        </div>
                                        <div
                                            class="module-table__col module-table__col--status d-flex"
                                            @click.prevent="handleActionClick(user, 1)"
                                        >
                                            <i
                                                :class="statusStyleIcon[user.status]"
                                            ></i>
                                            {{ t("STUDENT_STATUS_" + user.status) }}
                                        </div>
                                        <div
                                            class="module-table__col module-table__col--group"
                                        >
                                            <GroupsList :groups="user.groups" />
                                        </div>
                                        <div
                                            class="module-table__col module-table__col--actions"
                                        >
                                            <!--                                    <i class="bi bi-chat-text-fill"></i>-->
                                            <DropdownButtons
                                                :buttons="actions"
                                                @on-click="
                                            (id) => handleActionClick(user, id)
                                        "
                                            >
                                                <template v-slot:toggle-button>
                                                    <i
                                                        class="bi bi-three-dots-vertical dropdown-menu__button"
                                                    ></i>
                                                </template>
                                            </DropdownButtons>
                                        </div>
                                        <!--                                <i class="bi bi-x-lg" @click="removeUser(user.pivot_id)"></i>-->
                                    </div>
                                    <!--              </b-form-checkbox-group>-->
                                </div>
                                <div v-else>
                                    <h3 class="text-center m-5 text-black-50">
                                        Ще нікого нема
                                    </h3>
                                </div>
                            </b-form>
                        </b-container>
                    </b-tab>
                    <b-tab :title="$t('t-groups')">
                        <GroupItem
                            v-for="group of studentsGroupStore.studentsGroupList"
                            :key="group.id"
                            :group="group"
                            @handle-edit="tmpGroup = group; editGroupModal = true;"
                            @handle-remove="tmpGroup = group; deleteGroupModal = true;"
                        />
                        <b-pagination
                            v-if="studentsGroupStore.isGroupPagination"
                            class="mt-4"
                            v-model="currentPage"
                            :total-rows="studentsGroupStore.groupPaginationData.totalItems"
                            :per-page="studentsGroupStore.groupPaginationData.perPage"
                            aria-controls="my-table"
                            @page-click="handlePageChange"
                        ></b-pagination>
                        <EditStudentGroupModal v-if="editGroupModal" v-model="editGroupModal" :students="form.students" :group="tmpGroup" />
                        <ConfirmModal
                            v-if="deleteGroupModal"
                            v-model="deleteGroupModal"
                            title="Видалити групу"
                            decline-label="Не видаляти"
                            accept-label="Видалити"
                            @accept-btn="() => removeGroup()"
                            @decline-btn="() => console.log('decline')"
                        >
                            <template v-slot:description>
                                Після розформування групи, студенти залишаться у списку учасників на платформі. Бажаєте видалити групу?
                            </template>
                        </ConfirmModal>
                    </b-tab>
                    <template #tabs-end>
                        <div class="tabs-end">
                            <b-button
                                pill
                                size="md"
                                class="outline-dark"
                                @click="addStudentGroupModal = true"
                            >
                                {{ $t("t-new-group") }} <i class="ph-plus"></i>
                            </b-button>
                            <AddStudentGroupModal v-if="addStudentGroupModal" v-model="addStudentGroupModal" :students="form.students" />
                            <b-button
                                variant="primary"
                                pill
                                size="md"
                                @click="invaiteStudent"
                            >
                                {{$t('t-student-invite')}}
                            </b-button>
                        </div>
                    </template>
                </b-tabs>
            </template>
        </PageContentCard>
        <AddStudentInvaiteModal
            v-if="addUserModal"
            :emailWithTenant="true"
            v-model="addUserModal"
            @add-user="handleAddUser"
        />
        <ConfirmModal
            v-model="removeStudentModal"
            title="Видалення студента"
            decline-label="Скасувати"
            accept-label="Так, видалити"
            @accept-btn="() => removeUser(tempStudent)"
            @decline-btn="() => console.log('decline')"
        >
            <template v-slot:description>Ви впевнені, що хочете видалити цього студента?</template>
        </ConfirmModal>
        <b-modal
            v-model="editStudentModal"
            v-if="editStudentModal"
            hide-footer
            hide-header
            centered
        >
            <div class="modal-body">
                <div
                    class="d-flex align-items-center justify-content-between mb-2"
                >
                    <h3>Додати коментар</h3>
                    <h3>
                        <i
                            class="bi bi-x-lg cursor-pointer"
                            @click="editStudentModal = !editStudentModal"
                        ></i>
                    </h3>
                </div>
                <div>
                    <InputGroup
                        element="textarea"
                        label="Коментар"
                        v-model="tempStudent.comment"
                        :rows="6"
                        :max-rows="10"
                        :max-length="300"
                    />
                </div>
                <div class="d-flex justify-content-center">
                    <b-button
                        variant="primary"
                        size="md"
                        pill
                        class="mt-3"
                        @click="editStudent"
                    >
                        Зберегти
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<style lang="scss" scoped>
.team__container {
    min-height: calc(100vh - 270px);

    .team__user {
        display: flex;
        align-items: center;
    }

    .team__user--logo {
        width: 38px;
        height: 38px;
        border-radius: 38px;
        margin: 0 8px;
    }

    .team__user--name {
        color: var(--dark, #1e1e1e);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        margin-bottom: 5px;
    }

    .team__user--email {
        color: var(--Grey, #c2c2c2);
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
    }

    .team__user--phone {
        color: var(--dark, #1e1e1e);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }

    .module-table__row {
        cursor: default;
    }

    .module-table__col {
        min-height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .module-table__col--user {
        display: flex;
        width: 25%!important;
        flex: none;
    }

    .module-table__col--phone {
        width: 15%;
    }

    .module-table__col--other {
        flex: 1;
        .other-text__container {
            line-height: 1.5;
            display: -webkit-box ;
            display: -moz-box;
            -webkit-line-clamp: 2;
            -moz-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            -moz-box-orient: vertical;
            box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .module-table__col--status {
        width: 15%;
    }

    .module-table__col--group {
        width: 15%;
        .not-assigned {
            color: #C2C2C2;
        }
    }

    .module-table__col--actions {
        width: 50px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        //i {
        //    margin: 0 10px;
        //    font-size: x-large;
        //    color: #000;
        //}
    }

    .module-content {
        min-height: calc(100vh - 120px);
    }

    .header-end {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
    }

    .student-status__icon {
        font-size: 20px;
        margin-right: 8px;
    }
    .student-status__icon--red {
        color: var(--purple, #f24822);
    }
    .student-status__icon--yellow {
        color: #fff;
        background-color: #f1c963;
        border-radius: 20px;
    }
    .student-status__icon--green {
        color: var(--green, #1db954);
    }
}


</style>
