import { defineStore } from "pinia";
import axios from "axios";
import useRequestProgress from "@/composables/useRequestProgress.js";
import { toast } from "vue3-toastify";

const { setIsLoading } = useRequestProgress();

const webinarReportApiUrl = import.meta.env.VITE_WEBINAR_REPORT_API_URL;

const initialFilterPagination = {
    currentPage: 1,
    perPage: 10,
};

const webinarStatisticsFilterType = {
    button_name: "multiple",
    button_saw: "single",
    button_click: "single",
    country: "multiple",
    city: "multiple",
    os: "multiple",
    device: "multiple",
    browser: "multiple",
    messages: "single",
};

const webinarStatisticsListFilterType = {
    status: "single",
    type: "single",
    period: "period",
};

const axiosInstance = axios.create({
    headers: {
        "Access-Control-Allow-Origin": "*",
        "X-AUTH-TOKEN": "123e4567-e89b-12d3-a456-426614174000",
    },
});

export const useWebinarStatisticsStore = defineStore("webinarStatisticsStore", {
    state: () => {
        return {
            userId: false,
            webinarStatisticsList: {
                list: [],
                listWebinarsLinksCabinet: [],
                filters: [],
                // activeFilters: [],
                installedFilters: {},
                orderBy: null,
                order: null,
                tenantId: null,
                pagination: {
                    currentPage: 1,
                    total: null,
                    perPage: 10,
                },
            },
            webinarStatistics: {
                users: [],
                info: null,
                filters: [],
                activeFilters: [],
                installedFilters: {},
                orderBy: null,
                order: null,
                webinarId: null,
                pagination: {
                    currentPage: 1,
                    total: null,
                    perPage: 10,
                },
                filterPagination: {
                    currentPage: 1,
                    perPage: 10,
                },
            },
        };
    },
    getters: {},
    actions: {
        setTenantId(id) {
            this.webinarStatisticsList.tenantId = id;
        },

        setUserId(id) {
            this.userId = id;
        },

        createWebinarStatisticsListFilterObj() {
            const parsedObject = {};

            for (const [key, value] of Object.entries(
                this.webinarStatisticsList.installedFilters
            )) {
                const selectedFilters = Object.entries(value.filters)
                    .filter(([, isSelected]) => isSelected)
                    .map(([filterName]) => filterName);

                if (selectedFilters.length > 0) {
                    parsedObject[key] = selectedFilters.join(",");

                    if (key === "period") {
                        parsedObject["period_from"] = value.filters.period_from;
                        parsedObject["period_to"] = value.filters.period_to;
                        delete parsedObject["period"];
                    }
                }
            }

            return parsedObject;
        },

        async getWebinarStatisticsList() {
            setIsLoading(true);
            const filtersObj = this.createWebinarStatisticsListFilterObj();
            await axiosInstance
                .get(`${webinarReportApiUrl}/webinar/list`, {
                    params: {
                        tenant_id: this.webinarStatisticsList.tenantId,
                        page: this.webinarStatisticsList.pagination.currentPage,
                        page_size:
                            this.webinarStatisticsList.pagination.perPage,
                        order_by: this.webinarStatisticsList.order
                            ? this.webinarStatisticsList.orderBy
                            : null,
                        order: this.webinarStatisticsList.order,
                        ...filtersObj,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        if (response.data.data) {
                            this.webinarStatisticsList.list =
                                response.data.data;
                        }
                        this.webinarStatisticsList.pagination.currentPage =
                            response.data.page;
                        this.webinarStatisticsList.pagination.total =
                            response.data.total_count;
                        this.webinarStatisticsList.pagination.perPage =
                            response.data.page_size;
                    }
                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                    this.errorToast();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },

        getComingWebinars() {
            return this.webinarStatisticsList.list
                .filter((webinar) => {
                    return webinar.status === "PENDING";
                })
                .map((webinar) => webinar.webinar_id);
        },

        async getWebinarsLinksCabinet() {
            const comingWebinarsIds = this.getComingWebinars();

            if (!comingWebinarsIds.length) return;

            setIsLoading(true);

            try {
                const response = await axios.post(
                    "/api/module/workShop/getWebinarsLinksCabinet",
                    {
                        user_id: this.userId,
                        webinars: comingWebinarsIds,
                    }
                );

                this.webinarStatisticsList.listWebinarsLinksCabinet =
                    response.data;

                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
                this.errorToast();
            }
        },

        findWebinarLinkCabinet(id) {
            return this.webinarStatisticsList.listWebinarsLinksCabinet.find(
                (webinar) => webinar.id === id
            )?.linkCabinet;
        },

        setWebinarStatisticsListCurrentPage(page) {
            this.webinarStatisticsList.pagination.currentPage = page;
        },
        setWebinarStatisticsListPerPage(amount, withDropPage = false) {
            this.webinarStatisticsList.pagination.perPage = amount;
            if (withDropPage) {
                this.webinarStatisticsList.pagination.currentPage = 1;
            }
        },

        cancelSortStatisticsList() {
            this.webinarStatisticsList.orderBy = null;
        },
        toggleSortWebinarStatisticsList(fieldName) {
            this.webinarStatisticsList.orderBy = fieldName;
        },

        setOrderWebinarStatisticsList(value = null) {
            this.webinarStatisticsList.order = value;
        },

        setWebinarStatisticsListInitialQueryParams(data) {
            if (data?.page) {
                this.setWebinarStatisticsListCurrentPage(data.page);
            }
            if (data?.perPage) {
                this.setWebinarStatisticsListPerPage(data.perPage);
            }
        },

        changeSortingWebinarStatisticsList(value) {
            const checkOrderBy = this.webinarStatisticsList.orderBy === value;

            if (checkOrderBy) {
                const order = this.webinarStatisticsList.order;
                switch (order) {
                    case "asc":
                        this.setOrderWebinarStatisticsList("desc");
                        break;
                    case "desc":
                        this.setOrderWebinarStatisticsList(null);
                        break;
                    case null:
                        this.setOrderWebinarStatisticsList("asc");
                        break;
                    default:
                        this.setOrderWebinarStatisticsList(null);
                        break;
                }
            } else {
                this.setOrderWebinarStatisticsList("asc");
                this.toggleSortWebinarStatisticsList(value);
            }
        },

        async getWebinarStatisticsListFilters(props) {
            setIsLoading(true);
            await axiosInstance
                .get(`${webinarReportApiUrl}/webinar/filters`, {
                    params: {
                        webinar_id: props.tenantId,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        delete response.data.period_to;
                        delete response.data.period_from;
                        response.data.period = true;
                        this.webinarStatisticsList.filters = Object.entries(
                            response.data
                        ).map(([key, value]) => {
                            const valueData = Array.isArray(value)
                                ? value.map((item, index) => ({
                                      id: index + 1,
                                      name: item,
                                  }))
                                : value;
                            return {
                                key,
                                value: valueData,
                                type: webinarStatisticsListFilterType[key]
                                    ? webinarStatisticsListFilterType[key]
                                    : null,
                            };
                        });
                    }

                    this.setWebinarStatisticsListInstalledFiltersFromData(
                        this.webinarStatisticsList.filters
                    );
                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                    this.errorToast();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },

        getWebinarStatisticsListCurrentSortingByName(name) {
            if (name !== this.webinarStatisticsList.orderBy) return;
            return this.webinarStatisticsList.order;
        },

        getWebinarStatisticsListActiveFilter(filter) {
            return this.webinarStatisticsList.activeFilters.find(
                (item) => item.name === filter
            );
        },

        setWebinarStatisticsListInstalledFiltersFromData(data) {
            data.forEach((item) => {
                const filters = {};

                if (Array.isArray(item.value)) {
                    item.value.forEach((i) => (filters[i.name] = false));
                }

                if (item.type === "period") {
                    filters.period_from = null;
                    filters.period_to = null;
                }

                this.webinarStatisticsList.installedFilters[item.key] = {
                    name: item.key,
                    filters,
                    type: item?.type,
                };
            });
        },

        dropWebinarStatisticsListStore() {
            this.webinarStatisticsList.list = [];
            this.webinarStatisticsList.listWebinarsLinksCabinet = [];
            this.webinarStatisticsList.filters = [];
            this.webinarStatisticsList.installedFilters = {};
            this.webinarStatisticsList.orderBy = null;
            this.webinarStatisticsList.order = null;
            this.webinarStatisticsList.tenantId = null;
            this.webinarStatisticsList.pagination.currentPage = 1;
            this.webinarStatisticsList.pagination.total = null;
            this.webinarStatisticsList.pagination.perPage = 10;
        },

        //----------------------------------------------------------------
        //----------------------------------------------------------------
        //----------------------------------------------------------------
        //----------------------------------------------------------------
        //----------------------------------------------------------------

        sortingButtonFromBack(data) {
            return data.map((user) => {
                const sortedButtons = user.buttons.sort(
                    (a, b) => a.button_order - b.button_order
                );
                return { ...user, buttons: sortedButtons };
            });
        },

        setWebinarId(id) {
            this.webinarStatistics.webinarId = id;
        },

        createWebinarStatisticsFilterObj() {
            const parsedObject = {};

            for (const [key, value] of Object.entries(
                this.webinarStatistics.installedFilters
            )) {
                const selectedFilters = Object.entries(value.filters)
                    .filter(([, isSelected]) => isSelected)
                    .map(([filterName]) => filterName);

                if (selectedFilters.length > 0) {
                    parsedObject[key] = selectedFilters.join(",");
                }
            }

            return parsedObject;
        },

        async getWebinarStatistics() {
            setIsLoading(true);
            const filtersObj = this.createWebinarStatisticsFilterObj();
            await axiosInstance
                .get(`${webinarReportApiUrl}/webinar/activity`, {
                    params: {
                        webinar_id: this.webinarStatistics.webinarId,
                        page: this.webinarStatistics.filterPagination
                            .currentPage,
                        page_size:
                            this.webinarStatistics.filterPagination.perPage,
                        order_by: this.webinarStatistics.order
                            ? this.webinarStatistics.orderBy
                            : null,
                        order: this.webinarStatistics.order,
                        ...filtersObj,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        if (response.data.data) {
                            this.webinarStatistics.users =
                                this.sortingButtonFromBack(response.data.data);
                        }

                        this.webinarStatistics.pagination.currentPage =
                            response.data.page;
                        this.webinarStatistics.pagination.total =
                            response.data.total_count;
                        this.webinarStatistics.pagination.perPage =
                            response.data.page_size;
                    }

                    this.setWebinarStatisticsActiveFilters(filtersObj);
                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                    this.errorToast();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },

        setWebinarStatisticsFilterCurrentPage(page) {
            this.webinarStatistics.filterPagination.currentPage = page;
        },
        setWebinarStatisticsFilterPerPage(amount, withDropPage = false) {
            this.webinarStatistics.filterPagination.perPage = amount;
            if (withDropPage) {
                this.webinarStatistics.filterPagination.currentPage = 1;
            }
        },
        cancelSortWebinarStatistics() {
            this.webinarStatistics.orderBy = null;
        },
        toggleSortWebinarStatistics(fieldName) {
            this.webinarStatistics.orderBy = fieldName;
        },

        setOrderWebinarStatistics(value = null) {
            this.webinarStatistics.order = value;
        },

        setPageWebinarStatistics(value) {
            this.webinarStatistics.page = value;
        },

        setPerPageWebinarStatistics(value) {
            this.webinarStatistics.perPage = value;
        },

        changeSortingWebinarStatistics(value) {
            const checkOrderBy = this.webinarStatistics.orderBy === value;

            if (checkOrderBy) {
                const order = this.webinarStatistics.order;
                switch (order) {
                    case "asc":
                        this.setOrderWebinarStatistics("desc");
                        break;
                    case "desc":
                        this.setOrderWebinarStatistics(null);
                        break;
                    case null:
                        this.setOrderWebinarStatistics("asc");
                        break;
                    default:
                        this.setOrderWebinarStatistics(null);
                        break;
                }
            } else {
                this.setOrderWebinarStatistics("asc");
                this.toggleSortWebinarStatistics(value);
            }
        },

        setWebinarStatisticsInitialQueryParams(data) {
            if (data?.page) {
                this.setWebinarStatisticsCurrentPage(data.page);
            }
            if (data?.perPage) {
                this.setWebinarStatisticsPerPage(data.perPage);
            }
            // if (data?.orderBy) {
            //     this.toggleSortWebinarStatistics(data.orderBy);
            // }
            // if (data?.order) {
            //     this.setOrderWebinarStatistics(data.order);
            // }
        },

        async getWebinarStatisticsItemInfo(props) {
            setIsLoading(true);
            await axiosInstance
                .get(`${webinarReportApiUrl}/webinar/info`, {
                    params: {
                        webinar_id: props.webinarId,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        this.webinarStatistics.info = response.data;
                    }
                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                    this.errorToast();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },

        async getWebinarStatisticsItemFilters(props) {
            setIsLoading(true);
            await axiosInstance
                .get(`${webinarReportApiUrl}/webinar/activity/filters`, {
                    params: {
                        webinar_id: props.webinarId,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        this.webinarStatistics.filters = Object.entries(
                            response.data
                        ).map(([key, value]) => {
                            let valueData = null;
                            if (key === "button_name") {
                                valueData = Array.isArray(value)
                                    ? value
                                          .map((item, index) => ({
                                              id: item.ButtonOrder + 1,
                                              name: item.ButtonName,
                                          }))
                                          .sort((a, b) => a.id - b.id)
                                    : value;
                            } else {
                                valueData = Array.isArray(value)
                                    ? value.map((item, index) => ({
                                          id: index + 1,
                                          name: item,
                                      }))
                                    : value;
                            }

                            return {
                                key,
                                value: valueData,
                                type: webinarStatisticsFilterType[key]
                                    ? webinarStatisticsFilterType[key]
                                    : null,
                            };
                        });
                    }
                    this.setWebinarStatisticsInstalledFiltersFromData(
                        this.webinarStatistics.filters
                    );

                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                    this.errorToast();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },

        setWebinarStatisticsInstalledFiltersFromData(data) {
            data.forEach((item) => {
                const filters = {};

                if (Array.isArray(item.value)) {
                    item.value.forEach((i) => (filters[i.name] = false));
                }

                this.webinarStatistics.installedFilters[item.key] = {
                    name: item.key,
                    filters,
                    type: item?.type,
                };
            });
        },

        getWebinarStatisticsCurrentSortingByName(name) {
            if (name !== this.webinarStatistics.orderBy) return;
            return this.webinarStatistics.order;
        },

        dropWebinarStatisticsStore() {
            this.webinarStatistics.users = [];
            this.webinarStatistics.info = null;

            this.webinarStatistics.filters = [];
            this.webinarStatistics.activeFilters = [];
            this.webinarStatistics.installedFilters = {};
            this.webinarStatistics.orderBy = null;
            this.webinarStatistics.order = null;
            this.webinarStatistics.webinarId = null;
            this.webinarStatistics.pagination.currentPage = 1;
            this.webinarStatistics.pagination.total = null;
            this.webinarStatistics.pagination.perPage = 10;
            this.webinarStatistics.filterPagination = {
                ...initialFilterPagination,
            };
        },

        dropWebinarStatisticsStoreFilters() {
            this.setWebinarStatisticsInstalledFiltersFromData(
                this.webinarStatistics.filters
            );
            this.webinarStatistics.filterPagination = {
                ...initialFilterPagination,
            };
        },

        dropOneFilterFromWebinarStatisticInstalledFilters(data) {
            this.webinarStatistics.installedFilters[data.firstKey].filters[
                data.secondKey
            ] = false;
        },

        setWebinarStatisticsActiveFilters(obj) {
            this.webinarStatistics.activeFilters = [];
            for (const [key, value] of Object.entries(obj)) {
                const values = value.split(",");

                values.forEach((val) => {
                    this.webinarStatistics.activeFilters.push({
                        firstKey: key.trim(),
                        secondKey: val.trim(),
                    });
                });
            }
        },

        //----------------------------------------------------------------

        errorToast() {
            toast.error("Сталася помилка, спробуйте ще раз!", {
                autoClose: 2000,
                position: toast.POSITION.BOTTOM_CENTER,
                multiple: false,
            });
        },
    },
});
