
<script setup>
import { computed } from "vue";
import { convertSecondToTime } from "@/Components/Statistic/helpers.js";

const props = defineProps({
  seconds: Number | String,
});

const time = computed(() => convertSecondToTime(props.seconds));
</script>
<template>
  <div class="duration-time">
    <div class="duration-time__unit">
      <p class="duration-time__value">{{ time.hours }}</p>
      <p class="duration-time__label">год</p>
    </div>
    <div class="duration-time__separator">:</div>
    <div class="duration-time__unit">
      <p class="duration-time__value">{{ time.minutes }}</p>
      <p class="duration-time__label">хв</p>
    </div>
    <div class="duration-time__separator">:</div>
    <div class="duration-time__unit">
      <p class="duration-time__value">{{ time.seconds }}</p>
      <p class="duration-time__label">сек</p>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.duration-time {
  display: flex;
  gap: 5px;
  &__unit {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__value {
    margin: 0;
  }
  &__label {
    font-size: 12px;
    color: #888888;
    margin: 0;
  }
}
</style>

