<script setup>

import InputGroup from "@/Components/InputGroup.vue";
import TeamMemberChooser from "@/Components/form/TeamMemberChooser.vue";
import {useForm} from "@inertiajs/vue3";
import {computed} from "vue";
import axios from "axios";
import {toast} from "vue3-toastify";
import useRequestProgress from "@/composables/useRequestProgress.js";

const props = defineProps({
    team: Object,
    form: Object
});

const courseAvailable = computed({
    get() {
        return props.form.available === 0 ||
        props.form.available === "0"
            ? ""
            : props.form.available;
    },
    set(value) {
        props.form.available = value ? parseFloat(value) : 0;
    },
});

const { setIsLoading, isLoading } = useRequestProgress()

const handleSaveConfig = () => {
    const payload = {
        group_id: props.form.group_id,
        course_id: props.form.course_id,
        is_manual_module_open: Number(props.form.is_manual_module_open),
        is_automatic_module_open: Number(props.form.is_automatic_module_open),
        is_all_lesson_open: Number(props.form.is_all_lesson_open),
        is_after_watch_lesson_open: Number(props.form.is_after_watch_lesson_open),
        available: props.form.available,
        max_user_in_course: props.form.max_user_in_course,
        speakers: props.form.speakers,
    }
    setIsLoading(true)
    axios.post (route('api.get_action', { 'moduleName': 'group','action': `courseGroupConfigUpdate` }), payload)
        .then(() => {

        })
        .catch(error => {
            console.error(error);
            toast.error("Сталася помилка, спробуйте ще раз!", {
                autoClose: 2000,
                position: toast.POSITION.BOTTOM_CENTER,
                multiple: false
            });
        })
        .finally(() => {
            setIsLoading(false)
        })
}

</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="12">
                <TeamMemberChooser
                    :speaker="form.speakers"
                    :team="team"
                    type="CURATOR"
                    class="w-100 p-0"
                >
                    <template #header>
                        Куратор курсу
                        <i
                            id="course-curator-role"
                            class="bi bi-info-circle">
                        </i>

                    </template>

                </TeamMemberChooser>
                <b-tooltip
                    class="text-left"
                    target="course-curator-role"
                    custom-class="custom-tooltip"
                    offset="5"
                    triggers="hover"
                    :no-fade="true"
                    :delay="{ show: 50, hide: 50 }"
                >
                    Куратор може додавати або видаляти студентів, відкривати доступ до модулів курсу та слідкувати за активністю в чаті.
                </b-tooltip>

                <b-card no-body class="shadow-none mt-4">
                    <b-card-body
                        class="d-flex flex-column justify-content-between align-items-start bg-light rounded-3 p-3 mt-3"
                    >
                        <b-row class="w-100">
                            <b-col
                                cols="4"
                                class="card__title mt-4 d-flex gap-2 align-items-center"
                            >
                                <i
                                    id="course-availability-tooltip"
                                    class="bi bi-info-circle fs-5"
                                ></i>
                                Доступ після завершення
                            </b-col>
                            <b-col cols="8">
                                <InputGroup
                                    placeholder="Кількість днів"
                                    v-model="courseAvailable"
                                    type="number"
                                    size="lg"
                                    class="w-100"
                                    :postfix="courseAvailable ? 'Днів' : ''"
                                />
                            </b-col>
                        </b-row>
                        <b-tooltip
                            class="text-left"
                            target="course-availability-tooltip"
                            custom-class="custom-tooltip"
                            offset="5"
                            triggers="hover"
                        >
                            <p>
                                Введіть цифрами кількість днів, протягом яких
                                курс буде доступний після завершення.
                            </p>
                            <p>
                                Якщо хочете залишити доступ відкритим назавжди,
                                залиште поле незаповненим.
                            </p>
                        </b-tooltip>
                    </b-card-body>
                </b-card>

                <b-button
                    variant="primary"
                    size="lg"
                    pill
                    class="mt-3 float-end"
                    :disabled="isLoading"
                    @click="handleSaveConfig()"
                >
                    Зберегти
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<style scoped lang="scss">

</style>
