<script setup>
import { useI18n } from "vue-i18n";
import { translateStatisticPrefix } from "@/Components/Statistic/helpers.js";

const props = defineProps({
  currentValue: {
    type: Array,
    default: null,
  },
  filterList: {
    type: Array,
    default: [],
  },
  filterType: {
    type: String,
  },
});
const emit = defineEmits(["choose-filter"]);

const { t } = useI18n();

const updateSingleFilter = (filters, name) => {
  for (const [key, value] of Object.entries(filters)) {
    if (key !== name) {
      filters[key] = false;
    }
  }
};

const translateStatistic = (key) =>
  t(translateStatisticPrefix + key) === translateStatisticPrefix + key
    ? key
    : t(translateStatisticPrefix + key);

const exceptionsTranslate = {
  Empty: translateStatistic("Empty"),
  NotEmpty: translateStatistic("NotEmpty"),
};
</script>

<template>
  <div class="dropdown-filter__wrapper">
    <ul>
      <li v-for="filter of filterList" :key="filter.id">
        <b-form-checkbox
          v-if="filterType === 'multiple'"
          v-model="currentValue[filter.name]"
          :unchecked-value="false"
        >
          {{
            exceptionsTranslate[filter.name]
              ? exceptionsTranslate[filter.name]
              : filter.name
          }}
        </b-form-checkbox>
        <b-form-checkbox
          v-else-if="filterType === 'single'"
          v-model="currentValue[filter.name]"
          :unchecked-value="false"
          @change="() => updateSingleFilter(currentValue, filter.name)"
        >
          {{
            exceptionsTranslate[filter.name]
              ? exceptionsTranslate[filter.name]
              : filter.name
          }}
        </b-form-checkbox>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>
