<script setup>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import {
  addMinutes,
  addMonths,
  addDays,
  addYears,
  format,
  parse,
} from "date-fns";
import {
  fromISOString,
  getPeriods,
  dateRegex,
} from "@/Components/Statistic/helpers.js";

import { ref, watch, computed, onMounted, onBeforeUnmount } from "vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const props = defineProps({
  currentValue: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(["choose-filter", "change-period"]);

const showCalendar = ref(false);
const calendarContainer = ref(null);

const handleClickOutside = (event) => {
  if (
    calendarContainer.value &&
    !calendarContainer.value.contains(event.target)
  ) {
    showCalendar.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});

const anotherPeriodInputs = ref(false);

const calendarButtonPeriod = ref(null);

const notValidDate = ref(false);

const notValidDateSetter = computed({
  set(newValue) {
    if (!newValue) return;
    toast.error("Дата має бути у коректному форматі", {
      autoClose: 2000,
      position: toast.POSITION.BOTTOM_CENTER,
      multiple: false,
    });
  },
});

// const selectedDate = ref(null);

// const localSelectedDate = ref(
//   !!props.currentValue?.period_from && !!props.currentValue?.period_to
//     ? `${props.currentValue.period_from} to ${props.currentValue.period_to}`
//     : ""
// );

// watch(showCalendar, (newValue) => {
//   if (newValue) {
//     localSelectedDate.value =
//       !!props.currentValue?.period_from && !!props.currentValue?.period_to
//         ? `${props.currentValue.period_from} to ${props.currentValue.period_to}`
//         : "";
//   }
// });

const localSelectedDate = ref(
  !!props.currentValue?.period_from && !!props.currentValue?.period_to
    ? `${fromISOString(props.currentValue.period_from)} to ${fromISOString(
        props.currentValue.period_to
      )}`
    : ""
);

const periods = getPeriods();

const periodsArray = computed(() => {
  return Object.entries(periods).map(([key, period]) => {
    // console.log(val);
    return {
      key,
      value: period.value(),
      name: period.name,
      additionalValue: !!period?.additionalValue
        ? period.additionalValue()
        : "none",
    };
  });
});

const isValidDate = (input) => {
  return dateRegex.test(input);
};

watch(showCalendar, (newValue) => {
  if (newValue) {
    localSelectedDate.value =
      !!props.currentValue?.period_from && !!props.currentValue?.period_to
        ? `${fromISOString(props.currentValue.period_from)} to ${fromISOString(
            props.currentValue.period_to
          )}`
        : "";
  }
});

watch(localSelectedDate, (newValue) => {
  //console.log("changeLocalSelectedDate", newValue);
  const existPeriodIndex = periodsArray.value.findIndex(
    (period) =>
      period.value === localSelectedDate.value ||
      period.additionalValue === localSelectedDate.value
  );

  const existedPeriod = periodsArray.value[existPeriodIndex];
  if (existedPeriod) {
    calendarButtonPeriod.value = existedPeriod.key;
  } else {
    calendarButtonPeriod.value = null;
  }
});

// console.log(localSelectedDate.value, "check");
</script>


<template>
  <div class="dropdown-filter__wrapper" ref="calendarContainer">
    <button
      size="md"
      class="dropdown-filter default__filter"
      :class="{
        show: showCalendar,
      }"
      role="button"
      aria-expanded="false"
      @click="showCalendar = !showCalendar"
    >
      <span>Період</span>
      <i class="ph-caret-down-fill"></i>
    </button>
    <template v-if="showCalendar">
      <div class="dropdown-filter__content-wrapper">
        <div class="dropdown-filter__content">
          <ul class="list-period">
            <li>
              <button
                @click="() => (anotherPeriodInputs = !anotherPeriodInputs)"
                class="list-period__period"
                :class="{
                  'list-period__period--active-period': anotherPeriodInputs,
                }"
              >
                Інший період
              </button>
            </li>
            <li v-for="period in periodsArray" :key="period.key">
              <button
                class="list-period__period"
                :class="{
                  'list-period__period--active-period':
                    calendarButtonPeriod === period.key,
                }"
                @click="
                  () => {
                    anotherPeriodInputs = false;
                    calendarButtonPeriod = period.key;
                    // console.log(period.value, 'period.value');
                    localSelectedDate = period.value;
                  }
                "
              >
                {{ period.name }}
              </button>
            </li>
          </ul>
          <div class="custom-calendar__wrapper">
            <div class="custom-calendar__inputs">
              <div class="custom-calendar__input-group">
                <label for="input-from" class="custom-calendar__label"
                  >Від</label
                >
                <input
                  id="input-from"
                  class="custom-calendar__input-from"
                  :disabled="!anotherPeriodInputs"
                  type="text"
                  :placeholder="localSelectedDate ? '' : '2024-10-25'"
                  :value="
                    localSelectedDate ? localSelectedDate.split(' to ')[0] : ''
                  "
                  @change="
                    (event) => {
                      const newValue = event.target.value;
                      if (!isValidDate(newValue)) {
                        event.target.value = '';
                        notValidDateSetter = true;
                      } else {
                        const [from, to] = localSelectedDate
                          ? localSelectedDate.split(' to ')
                          : ['', ''];
                        localSelectedDate = `${format(
                          newValue,
                          'yyyy-MM-dd'
                        )} to ${to}`;
                      }
                    }
                  "
                />
              </div>
              <div class="custom-calendar__input-group">
                <label for="input-to" class="custom-calendar__label">До</label>
                <input
                  id="input-to"
                  class="custom-calendar__input-to"
                  :disabled="!anotherPeriodInputs"
                  type="text"
                  :placeholder="localSelectedDate ? '' : '2024-11-25'"
                  :value="
                    localSelectedDate ? localSelectedDate.split(' to ')[1] : ''
                  "
                  @change="
                    (event) => {
                      const newValue = event.target.value;

                      if (!isValidDate(newValue)) {
                        event.target.value = '';
                        notValidDateSetter = true;
                      } else {
                        const [from, to] = localSelectedDate
                          ? localSelectedDate.split(' to ')
                          : ['', ''];
                        localSelectedDate = `${from} to ${format(
                          newValue,
                          'yyyy-MM-dd'
                        )}`;
                      }
                    }
                  "
                />
              </div>
            </div>
            <div class="custom-calendar">
              <!--                        <VCalendar locale="uk" borderless :attributes="attributes" @dayclick="onDayClick"/>-->
              <flat-pickr
                v-model="localSelectedDate"
                :config="{
                  locale: Ukrainian,
                  mode: 'range',
                  inline: true,
                  minDate: format(new Date(2020, 0, 1), 'yyyy-MM-dd'),
                  maxDate: format(new Date(), 'yyyy-MM-dd'),
                  defaultDate: props.currentValue
                    ? [
                        fromISOString(props.currentValue?.period_from),
                        fromISOString(props.currentValue?.period_to),
                      ]
                    : null,
                }"
                class="booking-calendar_picker"
                @on-change="
                  (selectedDates, dateStr, instance) => {
                    // console.log(
                    //  'localSelectedDate',
                    //  localSelectedDate,
                    // 'dateStr',
                    //  dateStr,
                    // 'result',
                    // dateStr === localSelectedDate
                    //);

                    if (
                      dateStr === localSelectedDate &&
                      !localSelectedDate.includes('to')
                    ) {
                      localSelectedDate = '';
                    }
                  }
                "
              />
            </div>
            <button
              class="custom-calendar__apply-button"
              @click="
                () => {
                  // console.log('set', localSelectedDate);
                  emit('change-period', localSelectedDate);
                  localSelectedDate = '';
                  showCalendar = false;
                }
              "
            >
              Застосувати
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.list-filters__item {
  min-width: 120px;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .btn:focus-visible,
  .btn:active {
    box-shadow: none;
    border: 1px solid #ebebeb;
    background-color: white !important;
    color: #1e1e1e;
  }

  .dropdown-filter {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border: 1px solid #ebebeb;
    background-color: white;
    border-radius: 8px;
    padding: 8px 16px;
    height: auto;
    font-family: e-Ukraine;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: #1e1e1e;
    .ph-caret-down-fill {
      transition: all 0.2s linear;
    }
    &.default__filter {
      min-width: 180px;
      max-width: 280px;
      span {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &.show {
      .ph-caret-down-fill {
        transform: rotate(180deg);
      }
    }
    .selected {
      background-color: red;
    }
  }
}

.dropdown-filter__content-wrapper {
  position: absolute;
  z-index: 12;
  padding-bottom: 40px;
}

.dropdown-filter__content {
  width: 100%;
  background-color: white;
  display: flex;
  border-radius: 12px;

  box-shadow: 0 0 40px 0px #8e8d8d42;
  -webkit-box-shadow: 0 0 40px 0px #8e8d8d42;
}
.list-period {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  margin: 0;
  border-right: 1px solid #ebebeb;
  font-family: "e-Ukraine";
  font-size: 14px;
  font-weight: 400;

  &__period {
    transition: 0.5s;
    padding: 12px;
    width: 100%;
    text-align: left;
    border-radius: 8px;
    &:hover {
      background-color: #1db954;
      color: white;
    }
  }

  &__period--active-period {
    background-color: #1db954;
    color: white;
  }
}

.custom-calendar__wrapper {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 470px;
}

.custom-calendar__inputs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding: 16px;
  background-color: #f6f6f6;
  border-radius: 8px;
}

.custom-calendar__input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "e-Ukraine";
}

.custom-calendar__label {
  position: absolute;
  top: 0;
  left: 12px;
  font-size: 10px;
  line-height: 10px;
  color: #c2c2c2;
  font-weight: 300;
  padding: 0 4px;
  pointer-events: none;
  z-index: 1;
}

.custom-calendar__input-from,
.custom-calendar__input-to {
  width: 100%;
  padding: 16px 12px;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #c2c2c2;
  outline: none;
  &::placeholder {
    color: #c2c2c2;
    font-size: 12px;
  }
}

.custom-calendar__apply-button {
  background-color: #1db954;
  color: white;
  border-radius: 50px;
  padding: 16px 24px;
  font-family: e-Ukraine;
  font-size: 14px;
  font-weight: 400;
}

.custom-calendar {
  display: flex;
  justify-content: center;
  width: 100%;
  .flatpickr-calendar {
    width: 100% !important;
    box-shadow: none !important;
    &::before,
    &::after {
      display: none !important;
    }
    .flatpickr-next-month,
    .flatpickr-prev-month {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
    .dayContainer {
      //padding: 2px;
    }
    .flatpickr-day {
      background-color: transparent;
      border: 2px solid white !important;
      position: relative !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      &::before {
        content: "";
        display: block !important;
        position: absolute !important;
        border-radius: 4px !important;
        border: 1px solid white;
        width: 100%;
        height: 100%;
      }
      &:hover {
        border: 2px solid white !important;
      }
      &:hover::before {
        border: 1px solid var(--tb-primary);
      }
      &.today {
        &::before {
          border: 1px solid var(--tb-primary);
        }
      }
      &.flatpickr-disabled {
        background: color-mix(in srgb, #1e1e1e, transparent 95%) !important;
        color: color-mix(in srgb, #1e1e1e, transparent 50%) !important;
        cursor: not-allowed !important;
        &:hover {
          background: color-mix(in srgb, #1e1e1e, transparent 95%) !important;
        }
        &:hover::before {
          border: 1px solid white;
        }
      }
      &.selected {
        &::before {
          border: none;
        }
      }
      &.inRange {
        // background-color: #1db954 !important;
        //color: white !important;
      }
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
}
</style>
