<script setup>
import { Head, Link, router } from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";
import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import { ref, onMounted, onUnmounted, computed } from "vue";

import PerPageSelectorStatistics from "@/Components/form/PerPageSelectorStatistics.vue";
import ItemStatisticsHeader from "@/Components/Statistic/ItemStatisticsHeader.vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import StatisticsMessageModal from "@/Components/modals/StatisticsMessageModal.vue";
import DownloadStatisticsReportModal from "@/Components/modals/DownloadStatisticsReportModal.vue";
import { useWebinarStatisticsStore } from "@/stores/webinarStatisticsStore.js";
import OptionsFilterSelectorStatistics from "@/Components/form/OptionsFilterSelectorStatistics.vue";
import {
  convertSecondToTime,
  getIsoTimeDifferenceFormatted,
  translateStatisticPrefix,
} from "@/Components/Statistic/helpers.js";
import axios from "axios";
import { toast } from "vue3-toastify";

const props = defineProps({
  user: Object,
  workshop: Object,
  workshopTypes: Object,
  workshopStatistic: Object,
});

const { t } = useI18n();

const webinarStatisticsStore = useWebinarStatisticsStore();

const hrefStatistics = route("workshops.workshops-statistics");

const webinarReportApiUrl = import.meta.env.VITE_WEBINAR_REPORT_API_URL;

const page = computed(() => ({
  title: t("t-workshops"),
  breadcrumbs: [
    {
      text: "Дашборд",
      href: route("dashboard"),
    },
    {
      text: "Воркшопи",
      href: route("workshops.workshops"),
    },
    {
      text: "Статистика",
      href: hrefStatistics,
    },
    {
      text:
        webinarStatisticsStore.webinarStatistics.info?.webinar_name || "Подія",
      active: true,
    },
  ],
}));

const setPage = async (e, page) => {
  await fetchAndSyncWebinarStatistics({ page });
};

const handlePerPageChange = async (amount) => {
  webinarStatisticsStore.setWebinarStatisticsFilterPerPage(amount, true);
};

const getInitials = (name) => {
  const words = name.trim().split(/\s+/);

  if (words.length >= 1) {
    const firstInitial = words[0][0].toUpperCase();
    const secondInitial =
      words.length > 1
        ? words[1][0].toUpperCase()
        : (words[0][1] || "").toUpperCase();
    return firstInitial + secondInitial;
  }

  return "";
};

const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
};

const modalMessages = ref([]);

const setModalMessage = (messages) => {
  modalMessages.value = messages;
};

const modalShow = ref(false);

const downloadModalShow = ref(false);

const downloadUrlValue = "";

const downloadWithFilters = ref(false);

const scheduleCanvas = ref(null);

const bOffCanvasShow = ref(false);

const fetchAndSyncWebinarStatistics = async (fetchData) => {
  webinarStatisticsStore.setWebinarId(props.workshop.id);

  if (fetchData?.page) {
    webinarStatisticsStore.setWebinarStatisticsFilterCurrentPage(
      fetchData.page
    );
  }
  if (fetchData?.perPage) {
    webinarStatisticsStore.setWebinarStatisticsFilterPerPage(fetchData.perPage);
  }

  try {
    await webinarStatisticsStore.getWebinarStatistics();
  } catch (error) {
    console.error("Failed to fetch webinar statistics:", error);
  }
};

const handleSortingChange = async (value) => {
  webinarStatisticsStore.changeSortingWebinarStatistics(value);
  await fetchAndSyncWebinarStatistics();
};
const handleGroupFilterChange = async () => {
  await fetchAndSyncWebinarStatistics({ page: 1 });
  bOffCanvasShow.value = false;
};

const handleDropFilters = async () => {
  webinarStatisticsStore.dropWebinarStatisticsStoreFilters();
  await fetchAndSyncWebinarStatistics();
};

const handleDropOneFilter = async (data) => {
  webinarStatisticsStore.dropOneFilterFromWebinarStatisticInstalledFilters(
    data
  );
  await fetchAndSyncWebinarStatistics();
};

const openDownloadStatisticsModal = (withFiltersValue = false) => {
  downloadModalShow.value = true;
  downloadWithFilters.value = withFiltersValue;
};

const handleDownloadStatistics = async (
  webinarId,
  spreadsheetUrl,
  withFilters = false
) => {
  try {
    let params = {
      webinar_id: webinarId,
      spreadsheet_url: spreadsheetUrl,
    };

    if (withFilters) {
      const filtersObj =
        webinarStatisticsStore.createWebinarStatisticsFilterObj();
      params = { ...params, ...filtersObj };
    }

    await axios.get(`${webinarReportApiUrl}/webinar/activity/report`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "X-AUTH-TOKEN": "123e4567-e89b-12d3-a456-426614174000",
      },
      params: params,
    });

    downloadModalShow.value = false;

    toast.success("Успішно", {
      autoClose: 3000,
      position: toast.POSITION.BOTTOM_CENTER,
    });
  } catch (e) {
    toast.error("Упс... щось пішло не так ", {
      autoClose: 3000,
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }
};

const translateStatistic = (key) =>
  t(translateStatisticPrefix + key) === translateStatisticPrefix + key
    ? key
    : t(translateStatisticPrefix + key);

const exceptionsTranslate = {
  Empty: translateStatistic("Empty"),
  NotEmpty: translateStatistic("NotEmpty"),
};

onMounted(async () => {
  //   const url = new URL(window.location.href);

  //   const page = Number(url.searchParams.get("page")) || 1;

  //   const perPage = Number(url.searchParams.get("per_page")) || 1;

  //   const orderBy = url.searchParams.get("order_by") || null;

  //   const order = url.searchParams.get("order") || null;

  //   webinarStatisticsStore.setWebinarStatisticsInitialQueryParams({
  //     page,
  //     perPage,
  //     // orderBy,
  //     // order,
  //   });

  await webinarStatisticsStore.getWebinarStatisticsItemInfo({
    webinarId: props.workshop.id,
  });
  await webinarStatisticsStore.getWebinarStatisticsItemFilters({
    webinarId: props.workshop.id,
  });

  await fetchAndSyncWebinarStatistics();
});

onUnmounted(() => {
  webinarStatisticsStore.dropWebinarStatisticsStore();
});
</script>

<template>
  <div class="item-statistic__page">
    <Head title="Статистика" />

    <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />

    <!-- <PageContentCard :title="$t('t-workshops')"> -->
    <PageContentCard title="Статистика">
      <template #before-title>
        <Link class="back-to-workshops" :href="hrefStatistics">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="24"
            viewBox="0 0 15 24"
            fill="none"
          >
            <path
              d="M10.8213 5.60467C11.0203 5.60066 11.2155 5.66397 11.3799 5.78584C11.5443 5.9077 11.6698 6.0821 11.739 6.2849C11.8082 6.4877 11.8178 6.70887 11.7663 6.9178C11.7148 7.12674 11.6048 7.31311 11.4516 7.45113L5.58747 12.9078L11.4552 18.3645C11.5624 18.4495 11.6522 18.5576 11.719 18.6822C11.7858 18.8068 11.8281 18.9451 11.8433 19.0883C11.8585 19.2315 11.8462 19.3767 11.8071 19.5145C11.7681 19.6523 11.7032 19.7798 11.6166 19.889C11.53 19.9982 11.4234 20.0867 11.3038 20.1489C11.1841 20.2111 11.0538 20.2456 10.9212 20.2503C10.7887 20.2551 10.6566 20.2299 10.5334 20.1763C10.4103 20.1228 10.2986 20.0421 10.2056 19.9393L3.49755 13.707C3.39205 13.6091 3.30735 13.4874 3.24927 13.3505C3.19118 13.2135 3.16111 13.0645 3.16111 12.9137C3.16111 12.7629 3.19118 12.6139 3.24927 12.477C3.30735 12.34 3.39205 12.2184 3.49755 12.1204L10.2056 5.86845C10.3756 5.70396 10.5938 5.61047 10.8213 5.60467Z"
              fill="white"
            />
          </svg> </Link
      ></template>
      <template #header-start> </template>
      <template #default>
        <ItemStatisticsHeader
          v-if="!!webinarStatisticsStore.webinarStatistics.info"
          :workshop="workshop"
          @handle-filter="() => (bOffCanvasShow = !bOffCanvasShow)"
          @download-all-statistics="() => openDownloadStatisticsModal()"
          @download-statistics-with-filter="
            () => openDownloadStatisticsModal(true)
          "
        />

        <div>
          <b-offcanvas
            v-model="bOffCanvasShow"
            :class="scheduleClass"
            ref="scheduleCanvas"
            headerClass="schedule-сanvas__title"
            headerCloseClass="schedule-сanvas__close-btn"
            placement="end"
          >
            <template #title> Фільтр </template>
            <div class="d-flex flex-column justify-content-between gap-4">
              <div class="list-filters__wrapper">
                <div class="list-filters__container d-flex flex-column">
                  <div class="list-filters__item">
                    <div class="text-uppercase opacity-50">Показувати</div>
                    <PerPageSelectorStatistics
                      :current-value="
                        webinarStatisticsStore.webinarStatistics
                          .filterPagination.perPage
                      "
                      @choose-amount="handlePerPageChange"
                    />
                  </div>
                </div>
              </div>
              <template
                v-if="webinarStatisticsStore.webinarStatistics.filters?.length"
              >
                <div
                  class="list-filters__wrapper"
                  v-for="filter in webinarStatisticsStore.webinarStatistics
                    .filters"
                  :key="filter.key"
                >
                  <div class="list-filters__container d-flex flex-column">
                    <div class="list-filters__item">
                      <div class="text-uppercase opacity-50">
                        {{ translateStatistic(filter.key) }}:
                      </div>
                      <OptionsFilterSelectorStatistics
                        :current-value="
                          webinarStatisticsStore.webinarStatistics
                            .installedFilters[filter.key].filters
                        "
                        :filter-list="filter.value"
                        :filter-type="filter.type"
                        @choose-filter="
                          (value) => handleGroupFilterChange(filter, value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </template>

              <div class="d-flex justify-content-between">
                <button
                  class="list-filters__drop-button"
                  @click="handleDropFilters"
                >
                  <span>Скинути</span><i class="bi bi-arrow-repeat"></i>
                </button>
                <button
                  class="list-filters__apply-button"
                  @click="handleGroupFilterChange"
                >
                  <span>Застосувати</span>
                </button>
              </div>
            </div>
          </b-offcanvas>
        </div>

        <PerfectScrollbar>
          <div class="module-table statistics-details-list">
            <div
              class="module-table__active-filters"
              v-if="
                !!webinarStatisticsStore.webinarStatistics.activeFilters.length
              "
            >
              <div class="module-table__active-filters-select">Ви обрали:</div>
              <div class="module-table__active-filters-buttons">
                <button
                  v-for="filter of webinarStatisticsStore.webinarStatistics
                    .activeFilters"
                  :key="filter.firstKey + filter.secondKey"
                  @click="
                    () =>
                      handleDropOneFilter({
                        firstKey: filter.firstKey,
                        secondKey: filter.secondKey,
                      })
                  "
                >
                  <span>
                    {{ translateStatistic(filter.firstKey) }}
                    {{
                      exceptionsTranslate[filter.secondKey]
                        ? exceptionsTranslate[filter.secondKey]
                        : filter.secondKey
                    }}
                  </span>
                  <i class="bi bi-x-lg"></i>
                </button>
              </div>
              <button
                @click="handleDropFilters"
                class="module-table__active-filters-drop-button"
              >
                Скинути
              </button>
            </div>
            <div class="module-table__header border-bottom pb-2">
              <div
                class="module-table__col module-table__col--name text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsCurrentSortingByName(
                      'name'
                    )
                  "
                  @click="handleSortingChange('name')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Ім'я
                </div>
              </div>
              <div
                class="module-table__col module-table__col--phone text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsCurrentSortingByName(
                      'phone'
                    )
                  "
                  @click="handleSortingChange('phone')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Телефон
                </div>
              </div>
              <div
                class="module-table__col module-table__col--device text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsCurrentSortingByName(
                      'device'
                    )
                  "
                  @click="handleSortingChange('device')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Пристрій
                </div>
              </div>
              <div
                class="module-table__col module-table__col--system text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsCurrentSortingByName(
                      'os'
                    )
                  "
                  @click="handleSortingChange('os')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  ОС
                </div>
              </div>
              <div
                class="module-table__col module-table__col--browser text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsCurrentSortingByName(
                      'browser'
                    )
                  "
                  @click="handleSortingChange('browser')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Браузер
                </div>
              </div>
              <div
                class="module-table__col module-table__col--country text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsCurrentSortingByName(
                      'country'
                    )
                  "
                  @click="handleSortingChange('country')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Країна
                </div>
              </div>
              <div
                class="module-table__col module-table__col--city text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsCurrentSortingByName(
                      'city'
                    )
                  "
                  @click="handleSortingChange('city')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Місто
                </div>
              </div>
              <div
                class="module-table__col module-table__col--connected-time text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsCurrentSortingByName(
                      'connection_time'
                    )
                  "
                >
                  Увійшов(ч)
                </div>
              </div>
              <div
                class="module-table__col module-table__col--disconnected-time text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsCurrentSortingByName(
                      'disconnection_time'
                    )
                  "
                >
                  Вийшов(ч)
                </div>
              </div>
              <div
                class="module-table__col module-table__col--attended-time text-uppercase"
              >
                <div
                  class="table-column__sorting"
                  :class="
                    webinarStatisticsStore.getWebinarStatisticsCurrentSortingByName(
                      'total_duration'
                    )
                  "
                  @click="handleSortingChange('total_duration')"
                >
                  <div class="table-column__sorting--icon">
                    <i class="ph-caret-up-fill"></i>
                    <i class="ph-caret-down-fill"></i>
                  </div>
                  Пробув
                </div>
              </div>
              <div
                class="module-table__col module-table__col--button-first text-uppercase"
              >
                <div class="table-column__sorting">Кнопка 1</div>
              </div>
              <div
                class="module-table__col module-table__col--button-second text-uppercase"
              >
                <div class="table-column__sorting">Кнопка 2</div>
              </div>
              <div
                class="module-table__col module-table__col--comments text-uppercase"
              >
                <div class="table-column__sorting">Коментарі</div>
              </div>
            </div>
            <template v-if="webinarStatisticsStore.webinarStatistics.users">
              <div
                v-for="user of webinarStatisticsStore.webinarStatistics.users"
                :key="user.user_id"
                class="module-table__row border-bottom"
              >
                <div
                  class="module-table__col module-table__col--name border-right"
                >
                  <div class="module-table__user-avatar">
                    <div>{{ getInitials(user.user_name) }}</div>
                  </div>
                  <div class="module-table__user-info">
                    <span class="table-col__title">{{ user.user_name }}</span>
                    <span
                      v-if="!user.user_email.includes('@webinar.test')"
                      class="table-col__subtitle"
                      :id="'popover_' + user.user_email"
                    >
                      {{ user.user_email }}
                      <b-popover
                        :target="'popover_' + user.user_email"
                        triggers="hover"
                        placement="topleft"
                        :no-fade="true"
                        :delay="{ show: 50, hide: 50 }"
                      >
                        {{ user.user_email }}
                      </b-popover>
                    </span>
                  </div>
                </div>
                <div class="module-table__col module-table__col--phone">
                  <span> {{ user.user_phone }} </span>
                </div>
                <div class="module-table__col module-table__col--device">
                  <span> {{ user.device }} </span>
                </div>
                <div class="module-table__col module-table__col--system">
                  <span>{{ user.os }}</span>
                </div>
                <div class="module-table__col module-table__col--browser">
                  <span>{{ user.browser }}</span>
                </div>
                <div class="module-table__col module-table__col--country">
                  <span>{{ user.country }}</span>
                </div>
                <div class="module-table__col module-table__col--city">
                  <span>{{ user.city }}</span>
                </div>
                <div
                  class="module-table__col module-table__col--connected-time"
                >
                  <span>
                    {{
                      getIsoTimeDifferenceFormatted(
                        webinarStatisticsStore.webinarStatistics.info
                          .start_webinar_time,
                        user.sessions[0]?.connection_time
                      )
                    }}</span
                  >
                </div>
                <div
                  class="module-table__col module-table__col--disconnected-time"
                >
                  <span>{{
                    getIsoTimeDifferenceFormatted(
                      webinarStatisticsStore.webinarStatistics.info
                        .start_webinar_time,
                      user.sessions[0]?.disconnection_time
                    )
                  }}</span>
                </div>
                <div class="module-table__col module-table__col--attended-time">
                  <span>{{
                    convertSecondToTime(user.total_duration).value
                  }}</span>
                </div>
                <div class="module-table__col module-table__col--button-first">
                  <p v-if="user.buttons[0]?.button_saw">
                    {{ user.buttons[0].button_saw }}
                  </p>
                  <p v-else class="module-table__col--button-item--empty">-</p>
                  <p v-if="user.buttons[0]?.button_click">
                    {{ user.buttons[0].button_click }}
                  </p>
                  <p v-else class="module-table__col--button-item--empty">-</p>
                </div>
                <div class="module-table__col module-table__col--button-second">
                  <p v-if="user.buttons[1]?.button_saw">
                    {{ user.buttons[1].button_saw }}
                  </p>
                  <p v-else class="module-table__col--button-item--empty">-</p>
                  <p v-if="user.buttons[1]?.button_click">
                    {{ user.buttons[1].button_click }}
                  </p>
                  <p v-else class="module-table__col--button-item--empty">-</p>
                </div>
                <div
                  class="module-table__col module-table__col--comments module-table__col--comments-item"
                >
                  <template v-if="user.messages.length">
                    <div class="module-table__col--comments-card">
                      <p>
                        {{ truncateString(`${user.messages[0].message}`, 90) }}
                      </p>
                      <p class="module-table__col--comments-time">
                        {{
                          convertSecondToTime(
                            user.messages[0].webinar_message_time
                          ).value
                        }}
                      </p>
                    </div>
                    <button
                      v-if="user.messages.length > 1"
                      @click="
                        () => {
                          modalShow = true;
                          setModalMessage(user.messages);
                        }
                      "
                    >
                      Показати +{{ user.messages.length - 1 }}
                    </button>
                  </template>
                </div>
              </div>
            </template>
          </div>
          <div class="statistics-details-list__footer">
            <div class="statistics-details-list__footer--info">
              Відображено:
              <span
                v-if="webinarStatisticsStore.webinarStatistics.users.length > 1"
              >
                {{
                  webinarStatisticsStore.webinarStatistics.pagination
                    .currentPage *
                    webinarStatisticsStore.webinarStatistics.pagination
                      .perPage -
                  webinarStatisticsStore.webinarStatistics.pagination.perPage +
                  1
                }}-{{
                  webinarStatisticsStore.webinarStatistics.pagination
                    .currentPage *
                    webinarStatisticsStore.webinarStatistics.pagination
                      .perPage -
                  webinarStatisticsStore.webinarStatistics.pagination.perPage +
                  webinarStatisticsStore.webinarStatistics.users.length
                }}
              </span>
              <span v-else>
                {{
                  webinarStatisticsStore.webinarStatistics.pagination
                    .currentPage *
                    webinarStatisticsStore.webinarStatistics.pagination
                      .perPage -
                  webinarStatisticsStore.webinarStatistics.pagination.perPage +
                  webinarStatisticsStore.webinarStatistics.users.length
                }}
              </span>
              <span> з </span>
              <span>
                {{ webinarStatisticsStore.webinarStatistics.pagination.total }}
              </span>
            </div>
            <b-pagination
              v-if="
                webinarStatisticsStore.webinarStatistics.pagination &&
                webinarStatisticsStore.webinarStatistics.pagination.total > 0
              "
              v-model="
                webinarStatisticsStore.webinarStatistics.pagination.currentPage
              "
              class="statistics-details-list__footer--pagination"
              :total-rows="
                webinarStatisticsStore.webinarStatistics.pagination.total
              "
              :per-page="
                webinarStatisticsStore.webinarStatistics.pagination.perPage
              "
              aria-controls="my-table"
              first-number
              last-number
              @page-click="setPage"
            >
              <template #prev-text><i class="ph-caret-left-bold"></i></template>
              <template #next-text
                ><i class="ph-caret-right-bold"></i
              ></template>
            </b-pagination>
          </div>
        </PerfectScrollbar>
      </template>
    </PageContentCard>
    <StatisticsMessageModal
      v-model="modalShow"
      :modalMessages="modalMessages"
    />
    <DownloadStatisticsReportModal
      v-model="downloadModalShow"
      :webinarId="props.workshop.id"
      :urlValue="downloadUrlValue"
      :withFilters="downloadWithFilters"
      @update:urlValue="(value) => (downloadUrlValue = value)"
      @update:modelValue="(value) => (downloadModalShow = value)"
      @handle-download-statistics="handleDownloadStatistics"
    />
  </div>
</template>

<style lang="scss" scoped >
.ps {
  max-height: 80vh;
  padding-bottom: 25px;
}
.statistics-details-list__footer {
  position: sticky;
  left: 0;
  padding: 24px 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: e-Ukraine;

  &--info {
    font-size: 12px;
    color: #888888;
    flex: 0 0 auto;
    span {
      font-weight: 700;
    }
  }

  &--pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.statistics-details-list {
  width: 150%;
  .module-table__header {
    position: sticky;
    top: 0;
    z-index: 10;
    background: white;
  }
  .module-table__row {
    position: relative;
    padding: 0;
    margin: 0;
    align-items: initial;
    span {
      font-weight: 300;
    }
    p {
      font-weight: 300;
    }

    &:hover {
      background-color: color-mix(
        in srgb,
        var(--tb-module-table-bage-bg),
        transparent 60%
      );
      border-color: #1db954 !important;

      .module-table__user-avatar {
        color: #1db954;
        border-color: #1db954 !important;
      }
    }
    .workshops-tags {
      position: absolute;
      top: 4px;
      left: 4px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .workshops-tag {
        font-family: e-Ukraine;
        background: var(--green, #1db954);
        padding: 2px 8px;
        border-radius: 4px;
        color: white;
        text-align: center;
      }
    }
  }

  .module-table__col--name {
    display: flex;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;

    width: 13%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }

  .module-table__user-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d1d1d1;
    color: #6d6d6d;
    border-radius: 100%;
    min-width: 38px;
    max-width: 38px;
    height: 38px;
  }

  .module-table__user-info {
    width: calc(100% - 38px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    flex-wrap: nowrap;
    padding: 10px 16px;
    //width: calc(100% - 125px);

    .table-col__title {
      font-family: e-Ukraine;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0;
      text-align: left;
      color: #1e1e1e;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .table-col__subtitle {
      font-family: e-Ukraine;
      font-size: 14px;
      font-weight: 200;
      line-height: 1.5;
      opacity: 1;
      letter-spacing: 0;
      text-align: left;
      color: #1e1e1e80;
      display: -webkit-box;
      display: -moz-box;
      -webkit-line-clamp: 1;
      -moz-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .module-table__col--phone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 6%;

    @media (max-width: 992px) {
      width: 50%;
    }
  }
  .module-table__col--device {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 4%;

    @media (max-width: 992px) {
      width: 50%;
    }
  }

  .module-table__col--system {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 4%;

    @media (max-width: 992px) {
      width: 50%;
    }
  }

  .module-table__col--browser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 6%;

    @media (max-width: 992px) {
      width: 50%;
    }
  }

  .module-table__col--country {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 6%;
    @media (max-width: 992px) {
      width: 50%;
    }
  }

  .module-table__col--city {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 6%;
    @media (max-width: 992px) {
      width: 50%;
    }
  }
  .module-table__col--connected-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 6%;
    @media (max-width: 992px) {
      width: 50%;
    }
  }
  .module-table__col--disconnected-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 6%;
    @media (max-width: 992px) {
      width: 50%;
    }
  }

  .module-table__col--attended-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 6%;
    @media (max-width: 992px) {
      width: 50%;
    }
  }

  .module-table__col--button-first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 4%;
    @media (max-width: 992px) {
      width: 50%;
    }
  }

  .module-table__col--button-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    width: 4%;
    @media (max-width: 992px) {
      width: 50%;
    }
  }
  .module-table__col--button-item--empty {
    color: #888888;
  }

  .module-table__col--comments {
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    flex-wrap: nowrap;

    width: 14%;
    @media (max-width: 992px) {
      width: 50%;
    }
    &-item {
      min-height: 135px;
      padding-right: 24px;
    }
    &-card {
      background: #f6f6f6;
      padding: 12px;
      margin: 6px 0;
      border-radius: 12px;
      width: 100%;
    }
    &-time {
      text-align: end;
      margin: 0;
      color: #888888;
    }
    button {
      color: var(--green, #1db954);
      margin-bottom: 6px;
    }
  }
  .workshop-statistics__details-button {
    padding: 12px 24px;
    border-radius: 45px;
    background: var(--green, #1db954);
    border: 1px solid var(--green, #1db954);
    font-family: e-Ukraine;
    font-size: 11px;
    font-weight: 300;
    line-height: 15.4px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .module-table__col--actions {
    width: 13%;
    justify-content: center !important;
    //i {
    //  font-size: large;
    //}
  }

  .module-table__title-image {
    width: 128px;
    height: 72px;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    background: #f7f7f8;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 128px;
      max-height: 72px;
    }
  }
}

.list-filters__wrapper {
  font-family: e-Ukraine;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 8px 12px 8px;
  border-radius: 8px;
  border: 1px solid var(--stroke-default-100, #e7e7e7);
  background: var(--background-default-50, #f6f6f6);
  margin-bottom: var(--tb-card-spacer-x);
  .list-filters__container,
  .list-filters__actions {
    width: 100%;
    display: flex;
    gap: 12px;
  }
  .list-filters__item {
    min-width: 100%;
    max-width: 100%;
  }
}

.list-filters__drop-button {
  font-family: e-Ukraine;
  color: #f24822;
  font-size: 14px;
  span {
    padding-right: 6px;
  }
}

.list-filters__apply-button {
  font-family: e-Ukraine;

  color: #ffffff;
  font-size: 14px;
  background: #1db954;
  padding: 16px 24px;
  border-radius: 50px;
}

.back-to-workshops {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
  border-radius: 4px;
  background: var(--purple, #f24822);
  cursor: pointer;
}
.border-right {
  border-right: var(--tb-border-width) var(--tb-border-style)
    var(--tb-border-color) !important;
}

.module-table__active-filters {
  display: flex;
  gap: 12px;
  align-items: center;
  padding-bottom: 12px;
  font-size: 12px;
  font-family: "e-Ukraine";
}

.module-table__active-filters-select {
  color: #1e1e1e;
  opacity: 0.5;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
}

.module-table__active-filters-buttons {
  display: flex;
  gap: 6px;
  button {
    border: 1px solid #1db954;
    background-color: #f0fdf4;
    padding: 10px 8px;
    border-radius: 8px;
    span {
      padding-right: 6px;
    }
    i {
      color: #1db954;
    }
  }
}

.module-table__active-filters-drop-button {
  color: #f24822;
  font-weight: 700;
}
</style>


<style lang="scss">
.item-statistic__page {
  .ps__thumb-y {
    background-image: -webkit-linear-gradient(
      90deg,
      #1db954 47.5%,
      white 47.5% calc(47.5% + 2px),
      #1db954 calc(47.5% + 2px) calc(47.5% + 4px),
      white calc(47.5% + 4px) calc(47.5% + 6px),
      #1db954 calc(47.5% + 6px) calc(47.5% + 8px),
      white calc(47.5% + 8px) calc(47.5% + 10px),
      #1db954 calc(47.5% + 10px)
    );
    border: 1px solid #1db954;
  }
  .ps__thumb-x {
    border: 1px solid #1db954;
    background-image: -webkit-linear-gradient(
      0deg,
      #1db954 47.5%,
      white 47.5% calc(47.5% + 2px),
      #1db954 calc(47.5% + 2px) calc(47.5% + 4px),
      white calc(47.5% + 4px) calc(47.5% + 6px),
      #1db954 calc(47.5% + 6px) calc(47.5% + 8px),
      white calc(47.5% + 8px) calc(47.5% + 10px),
      #1db954 calc(47.5% + 10px)
    );
  }

  .ps__rail {
    &-x {
      border-radius: 12px;
    }
    &-y {
      border-radius: 12px;
    }
  }
}
</style>
